module.exports = {
  messages: {
    ' COMMBOX_SCHEDULING_SUCCESSFULLY_SCHEDULED_TO':
      'Your message was scheduled for {time} on {date}.',
    A11Y_LOGO: 'Go to XING homepage',
    A11Y_SETTINGS_DROPDOWN_LABEL: 'Edit settings',
    ABOUT_ME: 'About me',
    ACCOUNT: 'Member account',
    ACTION_BLOCK_USER: 'Block this user',
    ACTION_BLOCK_USER_DIALOG_DESCRIPTION:
      "Blocking {displayName} means they can't view your profile or contact you via XING.",
    ACTION_BLOCK_USER_DIALOG_TITLE: 'Really block this user?',
    ACTION_BLOCK_USER_SUCCESS: 'Member blocked',
    ACTIVE_LANGUAGE: 'English',
    ADD_ADDRESS: 'add address',
    ADD_COMPANY_SIZE: 'Add company size',
    ADD_ENTRY: 'Add entry',
    ADD_IM_ID: 'Add instant messaging ID',
    ADD_PROFILE: 'add profile',
    ADD_SUBJECT: 'Add field of study',
    ADD_WEBSITE: 'Add website',
    ADDRESS: 'Address',
    AMBASSADOR_ICON_LINK:
      'http://official-events.xing.com/ambassador.php?dpt=prd&ttp=plat&plf=amb&pid=20070712&lng=en',
    ARMSTRONG_DISCO_NAVIGATION_ICON_TEXT: 'Exchange',
    ARTICLE_KIT_RECOMMEND_STREAM_PLACEHOLDER: 'Add a comment about this here.',
    ASK_XING_NEW: 'New',
    ASK_XING_TITLE: 'Ask XING',
    AWARD: 'Award',
    AWARDS: 'Awards',
    AWARDS_EMPTY:
      'Enter awards that you have received such as "Networker of the Year 2010".',
    BASE_PROFILE: 'Basic profile',
    BILLING_ADDRESS_CHANGE_HINT:
      'The billing address is not automatically updated. To edit your billing address please click here: "<a href="{!BILLING_ADDRESS_URL}">{\'NAVI_ACCOUNTS}</a>".',
    BILLING_ADDRESS_CHANGE_HINT_TITLE:
      'Is your billing information up to date?',
    BIRTHDATE_EMPTY: 'You have not yet entered your birthday.',
    BIRTHDATE_PROFILES_EMPTY: 'You have not yet entered your birthday.',
    BOTTOM_BAR_FIND_JOBS_LABEL: 'Find jobs',
    BOTTOM_BAR_INSIGHTS_LABEL: 'Insights',
    BOTTOM_BAR_NETWORK_LABEL: 'Network',
    BOTTOM_BAR_NOTIFICATIONS_LABEL: 'Notifications',
    BOTTOM_BAR_YOUR_JOBS_LABEL: 'Your jobs',
    BREWERY_NOCOOKIES_LINK_TARGET:
      'https://faq.xing.com/en/security/what-are-cookies-and-why-do-i-need-them-log',
    BREWERY_NOCOOKIES_LINK_TEXT: 'Activate cookies',
    BREWERY_NOCOOKIES_PARAGRAPH:
      "You need to activate cookies to log in. Here's how to do that:",
    BREWERY_NOCOOKIES_TITLE: 'Please activate cookies.',
    BREWERY_NOJAVASCRIPT_LINK_TARGET:
      'https://faq.xing.com/en/settings/enable-javascript-my-browser',
    BREWERY_NOJAVASCRIPT_LINK_TEXT: 'Activate JavaScript',
    BREWERY_NOJAVASCRIPT_PARAGRAPH:
      "XING only works properly with JavaScript. Here's how to activate it:",
    BREWERY_NOJAVASCRIPT_TITLE: 'Please activate JavaScript.',
    BUSINESS: 'Business',
    BUSINESS_ADDRESS_STUDENT_CHANGE_HINT:
      'Once you’ve completed your studies and entered the world of work, please don’t forget to change your status accordingly.',
    BUSINESS_UNIVERSITY: 'Business/University',
    BUTTON_ACCEPT: 'Accept',
    BUTTON_ADD: 'Add',
    BUTTON_ADDCONTACT: 'Add as contact',
    BUTTON_BACK: 'Back',
    BUTTON_BACKTOP: 'Back to overview',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_CHANGE: 'Change entries',
    BUTTON_CLEAR_FIELDS: 'Clear',
    BUTTON_CLOSE: 'Close',
    BUTTON_CLOSEWINDOW: 'Close window',
    BUTTON_COMMENT: 'Comment',
    BUTTON_CONFIRM: 'Confirm',
    BUTTON_CONTINUE: 'OK',
    BUTTON_CORRECTNEWS: 'Make corrections to news entry',
    BUTTON_CORRECTNEWSLETTER: 'Correct newsletter',
    BUTTON_CREATE_FILE: 'Create updated file',
    BUTTON_DELCONTACT: 'Delete contact',
    BUTTON_DELETE: 'Delete',
    BUTTON_DELETE_LANGUAGE: 'Delete language',
    BUTTON_EDIT: 'Edit',
    BUTTON_FILTER: 'Filter',
    BUTTON_FORWARD: 'Next',
    BUTTON_FURTHER_INVITATIONS: 'Invite more people',
    BUTTON_GRANT_PERMISSIONS: 'Grant access rights',
    BUTTON_JOIN: 'Join',
    BUTTON_JOIN_NOW: 'Join group now',
    BUTTON_LESS: 'Less',
    BUTTON_LOAD_MORE: 'More',
    BUTTON_MORE: 'More',
    BUTTON_NEW_LANGUAGE: 'New language',
    BUTTON_NEXT_STEP: 'Next step',
    BUTTON_OK: 'OK',
    BUTTON_POST: 'Post',
    BUTTON_POST_AND_TWEET: 'Post and tweet',
    BUTTON_POST_STATUS: 'Post',
    BUTTON_PREVIEW: 'Preview',
    BUTTON_PRINT: 'Print',
    BUTTON_PRINT_PROFILE: 'Print profile',
    BUTTON_PUBLISH: 'Post',
    BUTTON_RELOAD_PAGE: 'Refresh page',
    BUTTON_SAVE: 'Save',
    BUTTON_SAVE_AND_CONTINUE: 'Save and continue',
    BUTTON_SAVENEWS: 'Save news entry',
    BUTTON_SEARCH: 'Search',
    BUTTON_SEND: 'Send',
    BUTTON_SENDMESSAGE: 'Send message',
    BUTTON_SENDNEWSLETTER: 'Send newsletter',
    BUTTON_SHOW: 'Show',
    BUTTON_SHOW_ERRORS: 'Display error',
    BUTTON_SHOW_LESS: 'Less',
    BUTTON_SHOW_MORE: 'Show more',
    BUTTON_SKIP_STEP: 'Skip',
    BUTTON_SUBMIT: 'Submit',
    BUTTON_SUGGEST: 'Suggest contacts',
    BUTTON_TEXT_RECOVERY: 'Retrieve text',
    BUTTON_TRY_AGAIN: 'Try again',
    BUTTON_UPDATE: 'Save changes',
    CAL_DATE_FORMAT: 'DD/MM/YYYY',
    CAL_DATE_FORMAT_HINT: 'DD/MM/YYYY',
    CAREER_LEVEL_1: 'Student/Intern',
    CAREER_LEVEL_2: 'Entry Level',
    CAREER_LEVEL_3: 'Professional/Experienced',
    CAREER_LEVEL_4: 'Manager/Supervisor',
    CAREER_LEVEL_5: 'Executive (VP, SVP, etc.)',
    CAREER_LEVEL_6: 'Senior Executive (CEO, CFO, President)',
    CB_POSTING_ERROR: "Sorry, that didn't work. Please try again.",
    CELLPHONE: 'Mobile',
    CERTIFICATE_ERROR_DOWNLOAD_FAILED:
      'Unfortunately, this file could not be downloaded. Please try again.',
    CERTIFICATE_ERROR_EMPTY_FILE: 'Please select a file.',
    CERTIFICATE_ERROR_EMPTY_TITLE: 'Please enter a title for the file.',
    CERTIFICATE_ERROR_ERROR_FILE_TOO_BIG:
      'Please select a file with a maximum size of 2 MB.',
    CERTIFICATE_ERROR_FILE_NOT_SAVED:
      "We're sorry, the file wasn't uploaded. Please try again.",
    CERTIFICATE_ERROR_FILE_TYPE_NOT_ALLOWED:
      'Please select a file in one of the following formats: PDF, JPG or PNG.',
    CERTIFICATE_FILE: 'File',
    CERTIFICATE_MIMETYPE_APPLICATION_PDF: 'pdf',
    CERTIFICATE_MIMETYPE_IMAGE_JPEG: 'jpg',
    CERTIFICATE_MIMETYPE_IMAGE_PNG: 'png',
    CERTIFICATE_OPEN_OR_SAVE: 'Open or save',
    CERTIFICATE_PRIVACY: 'File visible to:',
    CERTIFICATE_PRIVACY_ALL: 'All XING members',
    CERTIFICATE_PRIVACY_CONTACTS_ONLY: 'Only my contacts',
    CERTIFICATE_TITLE: 'File description',
    CERTIFICATE_UPLOAD_NEW_FILE: 'change file',
    CERTIFICATE_VISIBILITY_CONTACTS_ONLY: 'Only visible to contacts',
    CERTIFICATES: 'Document upload',
    CERTIFICATES_EDIT_INFO: 'You can upload up to 3 files',
    CERTIFICATES_EDIT_INFO_2:
      '(A maximum of 2 MB each in PDF, JPG or PNG format).',
    CERTIFICATES_EMPTY:
      'More information about yourself (such as work samples and diplomas)',
    CERTIFICATES_NEW: 'NEW',
    CHANGE_PRIMARY_JOB_EXPL:
      'You can change the display of your main job by checking the box on another of your occupations.',
    CHANGE_PRIMARY_SCHOOL_EXPL:
      'Check the box next to another university to change which university you have displayed in your profile.',
    CHANGE_UN_PW:
      'Change the login details of your {-SITENAME} member account.',
    CHOOSE_LANG: 'Select the language in which you wish to use {-SITENAME}.',
    CHOOSE_NOTIFICATION_TYPES:
      'Select which notifications you wish to receive from {-SITENAME}.',
    CHOOSE_PRIVACY:
      'Select the level of privacy you wish to have on {-SITENAME}.',
    CITY: 'City',
    COMMBOX_ACTOR_SWITCH_CANCEL: 'Keep role',
    COMMBOX_ACTOR_SWITCH_CONFIRM: 'Change role',
    COMMBOX_ACTOR_SWITCH_HEADLINE: 'In what role do you want to post this?',
    COMMBOX_ACTOR_SWITCH_HINT:
      'To change the author of this post, just tap on the name above.',
    COMMBOX_ACTOR_SWITCH_PROMPT_IMAGE:
      "Do you really want to change the name of the author? You'd then have to re-upload the image.",
    COMMBOX_ACTOR_SWITCH_PROMPT_VIDEO:
      "Do you really want to change the name of the author? You'd then have to re-upload the video.",
    COMMBOX_ACTOR_SWITCH_TYPE_PAGE: 'Page',
    COMMBOX_ACTOR_SWITCH_TYPE_USER: 'Member',
    COMMBOX_ALMOST_FULL_FILLED_DROPZONE_HEADER:
      'You can add 1 more image to your post.',
    COMMBOX_AUDIENCE_CITY_CONTACTS: 'Only contacts in my city ({totalCount})',
    COMMBOX_AUDIENCE_FOLLOWERS: 'Followers only',
    COMMBOX_AUDIENCE_FOLLOWERS_AND_CONTACTS: 'Followers & contacts',
    COMMBOX_AUDIENCE_FOLLOWERS_AND_CONTACTS_DESC: 'Your post can be shared',
    COMMBOX_AUDIENCE_FOLLOWERS_DESC: 'Your post can be shared.',
    COMMBOX_AUDIENCE_HEADLINE: 'Who can see this?',
    COMMBOX_AUDIENCE_PRIVATE: 'Private (XING contacts only)',
    COMMBOX_AUDIENCE_PRIVATE_DESC: "Your post can't be shared.",
    COMMBOX_AUDIENCE_PRIVATE_SELECTED: 'Private',
    COMMBOX_AUDIENCE_PUBLIC: 'Public',
    COMMBOX_AUDIENCE_PUBLIC_DESC: 'Anyone can see and share this.',
    COMMBOX_BUTTON_ADD_VIDEO_LABEL: 'Select video',
    COMMBOX_BUTTON_OPEN_FILES_LABEL: 'Select document',
    COMMBOX_BUTTON_OPEN_POLL_LABEL: 'Create a poll',
    COMMBOX_CANCEL_POSTING_BUTTON_BACK: 'Continue editing',
    COMMBOX_CANCEL_POSTING_BUTTON_CANCEL: 'Discard post',
    COMMBOX_CANCEL_POSTING_HEADLINE: 'Discard post?',
    COMMBOX_CANCEL_POSTING_TEXT_MESSAGE:
      "You'll lose what you wrote if you discard it.",
    COMMBOX_CHARACTERS_LEFT: 'left',
    COMMBOX_EMPTY_DROPZONE_BUTTON_TEXT: 'Select images',
    COMMBOX_EMPTY_DROPZONE_TEXT: 'JPG, BMG or PNG (max. {max_size} MB each)',
    COMMBOX_EMPTY_DROPZONE_TITLE:
      'Drag & drop or select up to {max_images} images',
    COMMBOX_EMPTY_TEXT_FIELD: 'Share your thoughts, a photo or a link...',
    COMMBOX_EMPTY_TEXT_FIELD_MENTIONS:
      "Create a new post (and use '@' to mention others)…",
    COMMBOX_ENTRY_POINT_LABEL: 'Text box for your post',
    COMMBOX_ENTRY_POINT_TEXT: "What's new with you?",
    COMMBOX_FILLED_DROPZONE_HEADER:
      'You can add {pictures} more images to your post.',
    COMMBOX_FULL_FILLED_DROPZONE_HEADER:
      "You've reached the maximum number of photos you can post.",
    COMMBOX_IMAGE_BUTTON_LABEL: 'Add image',
    COMMBOX_IMAGE_SIZE_UPLOAD_ERROR:
      'Please select an image less than 10 MB in size.',
    COMMBOX_IMAGE_TYPE_UPLOAD_ERROR:
      'Please select an image in JPG or PNG format.',
    COMMBOX_POST: 'Post',
    COMMBOX_SCHEDULE: 'Done',
    COMMBOX_SCHEDULING_DATE: 'Date',
    COMMBOX_SCHEDULING_DATE_FUTURE_ERROR: 'Please select a date in the future.',
    COMMBOX_SCHEDULING_DATE_VALIDITY_ERROR:
      'Please enter a valid date format, e.g. 20 Jan 2021.',
    COMMBOX_SCHEDULING_FENCING_CTA_BUTTON: 'Discover all benefits',
    COMMBOX_SCHEDULING_FENCING_HEADLINE: 'Schedule posts',
    COMMBOX_SCHEDULING_FENCING_SUBLINE:
      'Schedule the date and time to share your post with followers.',
    COMMBOX_SCHEDULING_HOUR_RECOS: 'Recommended times',
    COMMBOX_SCHEDULING_IMMEDIATELY: 'Now',
    COMMBOX_SCHEDULING_NOW: 'Cancel',
    COMMBOX_SCHEDULING_SAVE: 'Save',
    COMMBOX_SCHEDULING_SCHEDULE: 'Done',
    COMMBOX_SCHEDULING_TIME: 'Time',
    COMMBOX_SCHEDULING_TIME_FUTURE_ERROR: 'Please select a time in the future.',
    COMMBOX_SCHEDULING_TITLE: 'Schedule',
    COMMBOX_SUCCESS_ACTION: 'View post',
    COMMBOX_SUCCESS_DESC: 'Your post is now live on XING.\t',
    COMMBOX_SUCCESS_HEADLINE: 'Great!',
    COMMBOX_SUCCESS_SCHEDULED_DESC:
      'Your message was scheduled for {time} on {date}.',
    COMMBOX_UPDATE_BTN: 'Update',
    COMMBOX_UPLOADED_IMAGES_LIMIT_REACHED:
      "You've reached the limit of 10 images.",
    COMMBOX_VIDEO_UPLOAD_ENCODING_TITLE:
      'Your video is being prepared. This will take a moment.',
    COMMBOX_VIDEO_UPLOAD_GENERIC_ERROR:
      "Sorry, that didn't work. Please try again.",
    COMMBOX_VIEW_POST_BTN: 'View post',
    COMMENT_BUTTON_LABEL: 'Comment',
    COMPANY: 'Company',
    COMPANY_BENEFIT_ACCESSIBILITY: 'Accessibility',
    COMPANY_BENEFIT_CANTEEN: 'Canteen',
    COMPANY_BENEFIT_CHILDCARE: 'Childcare',
    COMPANY_BENEFIT_COMPANY_CAR: 'Company car',
    COMPANY_BENEFIT_COMPANY_DOCTOR: 'Company doctor',
    COMPANY_BENEFIT_COMPANY_PENSION: 'Company pension',
    COMPANY_BENEFIT_CONVENIENT_TRANSPORT_LINKS: 'Convenient transport links',
    COMPANY_BENEFIT_DISCOUNTS_SPECIAL_OFFERS: 'Employee benefits',
    COMPANY_BENEFIT_DOGS_WELCOME: 'Dogs welcome',
    COMPANY_BENEFIT_EVENTS_FOR_EMPLOYEES: 'Events for employees',
    COMPANY_BENEFIT_FLEXITIME: 'Flexitime',
    COMPANY_BENEFIT_HEALTH_IN_THE_WORKPLACE: 'Health in the workplace',
    COMPANY_BENEFIT_HOME_OFFICE: 'Work from home',
    COMPANY_BENEFIT_MOBILE_DEVICE: 'Mobile device',
    COMPANY_BENEFIT_PRIVATE_INTERNET_USE: 'Private internet use',
    COMPANY_BENEFIT_PROFIT_SHARING: 'Profit-sharing',
    COMPANY_BENEFIT_RESTAURANT_TICKETS: 'Restaurant tickets',
    COMPANY_BENEFIT_TRAINING: 'Training',
    COMPANY_HP: 'Company Homepage',
    COMPANY_SIZE: 'Employees',
    COMPANY_SIZE_1: 'Just me',
    COMPANY_SIZE_2: '1-10 employees',
    COMPANY_SIZE_3: '11-50 employees',
    COMPANY_SIZE_4: '51-200 employees',
    COMPANY_SIZE_5: '201-500 employees',
    COMPANY_SIZE_6: '501-1,000 employees',
    COMPANY_SIZE_7: '1,001-5,000 employees',
    COMPANY_SIZE_8: '5,001-10,000 employees',
    COMPANY_SIZE_9: '10,001 or more employees',
    COMPLETE_BUSINESS_ADDRESS: 'Please complete your business address.',
    COMPLETENESS_HINT:
      'Networking is all about “finding” and “being found” – such as for jobs and by coworkers, friends and business partners. Make sure that you have completed your profile page and included a photo - it will make you much easier to find.',
    COMPLETION_LEVEL_WHATS_THIS: "What's this?",
    CONTACT_DETAILS: 'Contact details',
    CONTACTSHTML_LIST_DISABLED:
      'This person has chosen not to display their contacts.',
    CONTENT_ACTION_BLOCK_USER: 'Block this user',
    CONTENT_ERROR_404_BUTTON: 'Go to XING homepage',
    CONTENT_ERROR_404_HEADLINE: 'This page appears to have gone with the wind.',
    CONTENT_ERROR_404_SUBLINE:
      'But go ahead and check out what else is on offer:',
    CONTENT_ERROR_503_HEADLINE:
      'Looks like the server is playing cat and mouse with us.',
    CONTENT_ERROR_503_SUBLINE:
      'Please bear with us while we get some cheese for them.',
    CONTENT_ERROR_OFFLINE:
      'Please check your Internet connection and try again.',
    CONTENT_FOLLOW: 'Follow',
    CONTENT_FOLLOW_LIGHTBOX_HEADLINE: 'Follow {title}',
    CONTENT_FOLLOW_LIGHTBOX_TEXT:
      'Interested in reading {title} articles on XING on a regular basis?',
    CONTENT_FOLLOWED: 'Following',
    CONTENT_SWITCHER_MORE: 'More',
    CONTENT_TIMESTAMP_AN_HOUR_AGO_LONG:
      '{minutesCount, plural,\n   =1 {Just now}\n   other {# minutes ago}\n}',
    CONTENT_TIMESTAMP_AN_HOUR_AGO_SHORT:
      '{minutesCount, plural,\n   =1 {Now}\n   other {# mins}\n}',
    CONTENT_TIMESTAMP_DAYS: 'days',
    CONTENT_TIMESTAMP_DAYS_AGO_LONG:
      '{daysCount, plural,\n    =0 {Today}\n    one {Yesterday}\n    other {# days ago}\n}',
    CONTENT_TIMESTAMP_DAYS_AGO_SHORT:
      '{daysCount, plural,\n    =0 {Today}\n    one {Yesterday}\n    other {# days}\n}',
    CONTENT_TIMESTAMP_LESS_THAN_A_MINUTE_AGO: 'Just now',
    CONTENT_TIMESTAMP_SOME_HOUR_AGO_SHORT:
      '{hoursCount, plural,\n   =1 {1 hr}\n   other {# hrs}\n}',
    CONTENT_TIMESTAMP_SOME_HOURS_AGO_LONG:
      '{hoursCount, plural,\n   =1 {1 hour ago}\n   other {# hours ago}\n}',
    CONTENT_TIMESTAMP_TODAY: 'Today',
    CONTENT_TIMESTAMP_YESTERDAY: 'Yesterday',
    CONTENT_UNFOLLOW: 'Do not follow',
    CONTENT_VIDEO_ARTICLE_HEADLINE_PLACEHOLDER:
      'Add a title for your video here.',
    CONTENT_VIDEO_ARTICLE_VIDEO_ADDED: 'Video added',
    CONTENT_VIDEO_RECOS_REASON: 'Popular videos on XING',
    CONTROL_DISPLAY_NAME:
      'You can change your personal info here and enter your academic titles.',
    CONTROL_PROFILE_VISIBILITY:
      'Decide for yourself which parts of your profile are visible to others.',
    CORRECT_END_DATE: 'Please enter a correct end date.',
    CORRECT_START_DATE: 'Please enter a correct start date.',
    COUNTRY: 'Country',
    COUNTRY_AD: 'Andorra',
    COUNTRY_AE: 'United Arab Emirates',
    COUNTRY_AF: 'Afghanistan',
    COUNTRY_AG: 'Antigua and Barbuda',
    COUNTRY_AI: 'Anguilla',
    COUNTRY_AL: 'Albania',
    COUNTRY_AM: 'Armenia',
    COUNTRY_AN: 'Netherlands Antilles',
    COUNTRY_AO: 'Angola',
    COUNTRY_AQ: 'Antarctica',
    COUNTRY_AR: 'Argentina',
    COUNTRY_AS: 'American Samoa',
    COUNTRY_AT: 'Austria',
    COUNTRY_AU: 'Australia',
    COUNTRY_AW: 'Aruba',
    COUNTRY_AX: 'Åland Islands',
    COUNTRY_AZ: 'Azerbaijan',
    COUNTRY_BA: 'Bosnia and Herzegovina',
    COUNTRY_BB: 'Barbados',
    COUNTRY_BD: 'Bangladesh',
    COUNTRY_BE: 'Belgium',
    COUNTRY_BF: 'Burkina Faso',
    COUNTRY_BG: 'Bulgaria',
    COUNTRY_BH: 'Bahrain',
    COUNTRY_BI: 'Burundi',
    COUNTRY_BJ: 'Benin',
    COUNTRY_BL: 'Saint-Barthélemy',
    COUNTRY_BM: 'Bermuda',
    COUNTRY_BN: 'Brunei Darussalam',
    COUNTRY_BO: 'Bolivia',
    COUNTRY_BQ: 'Bonaire, Sint Eustatius and Saba',
    COUNTRY_BR: 'Brazil',
    COUNTRY_BS: 'Bahamas',
    COUNTRY_BT: 'Bhutan',
    COUNTRY_BV: 'Bouvet Island',
    COUNTRY_BW: 'Botswana',
    COUNTRY_BY: 'Belarus',
    COUNTRY_BZ: 'Belize',
    COUNTRY_CA: 'Canada',
    COUNTRY_CC: 'Coconut Islands',
    COUNTRY_CD: 'Congo, The Democratic Republic of the',
    COUNTRY_CF: 'Central African Republic',
    COUNTRY_CG: 'Congo',
    COUNTRY_CH: 'Switzerland',
    COUNTRY_CI: 'Ivory Coast',
    COUNTRY_CK: 'Cook Islands',
    COUNTRY_CL: 'Chile',
    COUNTRY_CM: 'Cameroon',
    COUNTRY_CN: 'China',
    COUNTRY_CO: 'Colombia',
    COUNTRY_CR: 'Costa Rica',
    COUNTRY_CU: 'Cuba',
    COUNTRY_CV: 'Cape Verde',
    COUNTRY_CW: 'Curaçao',
    COUNTRY_CX: 'Christmas Island',
    COUNTRY_CY: 'Cyprus',
    COUNTRY_CZ: 'Czech Republic',
    COUNTRY_DE: 'Germany',
    COUNTRY_DJ: 'Djibouti',
    COUNTRY_DK: 'Denmark',
    COUNTRY_DM: 'Dominica',
    COUNTRY_DO: 'Dominican Republic',
    COUNTRY_DZ: 'Algeria',
    COUNTRY_EC: 'Ecuador',
    COUNTRY_EE: 'Estonia',
    COUNTRY_EG: 'Egypt',
    COUNTRY_EH: 'Western Sahara',
    COUNTRY_ER: 'Eritrea',
    COUNTRY_ES: 'Spain',
    COUNTRY_ET: 'Ethiopia',
    COUNTRY_FI: 'Finland',
    COUNTRY_FJ: 'Fiji',
    COUNTRY_FK: 'Falkland Islands',
    COUNTRY_FM: 'Micronesia, Federated States of',
    COUNTRY_FO: 'Faroe Islands',
    COUNTRY_FR: 'France',
    COUNTRY_GA: 'Gabon',
    COUNTRY_GB: 'United Kingdom',
    COUNTRY_GD: 'Grenada',
    COUNTRY_GE: 'Georgia',
    COUNTRY_GF: 'French Guiana',
    COUNTRY_GG: 'Guernsey',
    COUNTRY_GH: 'Ghana',
    COUNTRY_GI: 'Gibraltar',
    COUNTRY_GL: 'Greenland',
    COUNTRY_GM: 'Gambia',
    COUNTRY_GN: 'Guinea',
    COUNTRY_GP: 'Guadeloupe',
    COUNTRY_GQ: 'Equatorial Guinea',
    COUNTRY_GR: 'Greece',
    COUNTRY_GS: 'South Georgia and the South Sandwich Islands',
    COUNTRY_GT: 'Guatemala',
    COUNTRY_GU: 'Guam',
    COUNTRY_GW: 'Guinea-Bissau',
    COUNTRY_GY: 'Guyana',
    COUNTRY_HK: 'Hong Kong',
    COUNTRY_HM: 'Heard Island and McDonald Islands',
    COUNTRY_HN: 'Honduras',
    COUNTRY_HR: 'Croatia',
    COUNTRY_HT: 'Haiti',
    COUNTRY_HU: 'Hungary',
    COUNTRY_ID: 'Indonesia',
    COUNTRY_IE: 'Ireland',
    COUNTRY_IL: 'Israel',
    COUNTRY_IM: 'Isle of Man',
    COUNTRY_IN: 'India',
    COUNTRY_IO: 'British Indian Ocean Territory',
    COUNTRY_IQ: 'Iraq',
    COUNTRY_IR: 'Iran, Islamic Republic of',
    COUNTRY_IS: 'Iceland',
    COUNTRY_IT: 'Italy',
    COUNTRY_JE: 'Jersey',
    COUNTRY_JM: 'Jamaica',
    COUNTRY_JO: 'Jordan',
    COUNTRY_JP: 'Japan',
    COUNTRY_KE: 'Kenya',
    COUNTRY_KG: 'Kyrgyzstan',
    COUNTRY_KH: 'Cambodia',
    COUNTRY_KI: 'Kiribati',
    COUNTRY_KM: 'Comoros',
    COUNTRY_KN: 'Saint Kitts and Nevis',
    COUNTRY_KP: 'North Korea',
    COUNTRY_KR: 'South Korea',
    COUNTRY_KW: 'Kuwait',
    COUNTRY_KY: 'Cayman Islands',
    COUNTRY_KZ: 'Kazakhstan',
    COUNTRY_LA: "Lao People's Democratic Republic",
    COUNTRY_LB: 'Lebanon',
    COUNTRY_LC: 'Saint Lucia',
    COUNTRY_LI: 'Liechtenstein',
    COUNTRY_LK: 'Sri Lanka',
    COUNTRY_LR: 'Liberia',
    COUNTRY_LS: 'Lesotho',
    COUNTRY_LT: 'Lithuania',
    COUNTRY_LU: 'Luxembourg',
    COUNTRY_LV: 'Latvia',
    COUNTRY_LY: 'Libya',
    COUNTRY_MA: 'Morocco',
    COUNTRY_MC: 'Monaco',
    COUNTRY_MD: 'Moldova',
    COUNTRY_ME: 'Montenegro',
    COUNTRY_MF: 'Saint-Martin (French part)',
    COUNTRY_MG: 'Madagascar',
    COUNTRY_MH: 'Marshall Islands',
    COUNTRY_MK: 'Macedonia',
    COUNTRY_ML: 'Mali',
    COUNTRY_MM: 'Myanmar',
    COUNTRY_MN: 'Mongolia',
    COUNTRY_MO: 'Macau',
    COUNTRY_MP: 'Northern Mariana Islands',
    COUNTRY_MQ: 'Martinique',
    COUNTRY_MR: 'Mauritania',
    COUNTRY_MS: 'Montserrat',
    COUNTRY_MT: 'Malta',
    COUNTRY_MU: 'Mauritius',
    COUNTRY_MV: 'Maldives',
    COUNTRY_MW: 'Malawi',
    COUNTRY_MX: 'Mexico',
    COUNTRY_MY: 'Malaysia',
    COUNTRY_MZ: 'Mozambique',
    COUNTRY_NA: 'Namibia',
    COUNTRY_NC: 'New Caledonia',
    COUNTRY_NE: 'Niger',
    COUNTRY_NF: 'Norfolk Island',
    COUNTRY_NG: 'Nigeria',
    COUNTRY_NI: 'Nicaragua',
    COUNTRY_NL: 'The Netherlands',
    COUNTRY_NO: 'Norway',
    COUNTRY_NP: 'Nepal',
    COUNTRY_NR: 'Nauru',
    COUNTRY_NU: 'Niue',
    COUNTRY_NZ: 'New Zealand',
    COUNTRY_OM: 'Oman',
    COUNTRY_PA: 'Panama',
    COUNTRY_PE: 'Peru',
    COUNTRY_PF: 'French Polynesia',
    COUNTRY_PG: 'Papua New Guinea',
    COUNTRY_PH: 'Philippines',
    COUNTRY_PK: 'Pakistan',
    COUNTRY_PL: 'Poland',
    COUNTRY_PM: 'Saint Pierre et Miquelon',
    COUNTRY_PN: 'Pitcairn Islands',
    COUNTRY_PR: 'Puerto Rico',
    COUNTRY_PS: 'Occupied Palestine',
    COUNTRY_PT: 'Portugal',
    COUNTRY_PW: 'Palau',
    COUNTRY_PY: 'Paraguay',
    COUNTRY_QA: 'Qatar',
    COUNTRY_RE: 'Réunion',
    COUNTRY_RO: 'Romania',
    COUNTRY_RS: 'Serbia',
    COUNTRY_RU: 'Russian Federation',
    COUNTRY_RW: 'Rwanda',
    COUNTRY_SA: 'Saudi Arabia',
    COUNTRY_SB: 'Solomon Islands',
    COUNTRY_SC: 'Seychelles',
    COUNTRY_SD: 'Sudan',
    COUNTRY_SE: 'Sweden',
    COUNTRY_SG: 'Singapore',
    COUNTRY_SH: 'Saint Helena',
    COUNTRY_SI: 'Slovenia',
    COUNTRY_SJ: 'Svalbard and Jan Mayen Island',
    COUNTRY_SK: 'Slovakia',
    COUNTRY_SL: 'Sierra Leone',
    COUNTRY_SM: 'San Marino',
    COUNTRY_SN: 'Senegal',
    COUNTRY_SO: 'Somalia',
    COUNTRY_SR: 'Suriname',
    COUNTRY_SS: 'South Sudan',
    COUNTRY_ST: 'Sao Tome and Principe',
    COUNTRY_SV: 'El Salvador',
    COUNTRY_SX: 'Sint Maarten (Dutch part)',
    COUNTRY_SY: 'Syria',
    COUNTRY_SZ: 'Swaziland',
    COUNTRY_TC: 'Turks and Caicos Islands',
    COUNTRY_TD: 'Chad',
    COUNTRY_TF: 'Territory of the French Southern and Antarctic Lands',
    COUNTRY_TG: 'Togo',
    COUNTRY_TH: 'Thailand',
    COUNTRY_TJ: 'Tajikistan',
    COUNTRY_TK: 'Tokelau',
    COUNTRY_TL: 'Timor-Leste',
    COUNTRY_TM: 'Turkmenistan',
    COUNTRY_TN: 'Tunisia',
    COUNTRY_TO: 'Tonga',
    COUNTRY_TR: 'Turkey',
    COUNTRY_TT: 'Trinidad and Tobago',
    COUNTRY_TV: 'Tuvalu',
    COUNTRY_TW: 'Taiwan',
    COUNTRY_TZ: 'Tanzania',
    COUNTRY_UA: 'Ukraine',
    COUNTRY_UG: 'Uganda',
    COUNTRY_UM: 'United States Minor Outlying Islands',
    COUNTRY_US: 'United States',
    COUNTRY_UY: 'Uruguay',
    COUNTRY_UZ: 'Uzbekistan',
    COUNTRY_VA: 'Holy See',
    COUNTRY_VC: 'Saint Vincent and the Grenadines',
    COUNTRY_VE: 'Venezuela',
    COUNTRY_VG: 'Virgin Islands, British',
    COUNTRY_VI: 'Virgin Islands, U.S.',
    COUNTRY_VN: 'Vietnam',
    COUNTRY_VU: 'Vanuatu',
    COUNTRY_WF: 'Wallis and Futuna',
    COUNTRY_WS: 'Samoa',
    COUNTRY_XK: 'Kosovo',
    COUNTRY_YE: 'Yemen',
    COUNTRY_YT: 'Mayotte',
    COUNTRY_ZA: 'South Africa',
    COUNTRY_ZM: 'Zambia',
    COUNTRY_ZW: 'Zimbabwe',
    CP_KUNUNU_RATED_BY: '{kununu_ratings_num} employer ratings on',
    CP_RECOMMENDS_ADVERTISEMENT_ABOUT_US:
      'XING recommends the following companies:',
    CP_REVIEWS_BENEFITS_1: 'Flexitime',
    CP_REVIEWS_BENEFITS_10: 'Training',
    CP_REVIEWS_BENEFITS_11: 'Car park',
    CP_REVIEWS_BENEFITS_12: 'Convenient transport links',
    CP_REVIEWS_BENEFITS_13: 'Staff discounts',
    CP_REVIEWS_BENEFITS_14: 'Company car',
    CP_REVIEWS_BENEFITS_15: 'Mobile device',
    CP_REVIEWS_BENEFITS_16: 'Profit-sharing',
    CP_REVIEWS_BENEFITS_17: 'Staff events',
    CP_REVIEWS_BENEFITS_18: 'Private Internet use',
    CP_REVIEWS_BENEFITS_19: 'Dogs welcome',
    CP_REVIEWS_BENEFITS_2: 'Remote work',
    CP_REVIEWS_BENEFITS_3: 'Canteen',
    CP_REVIEWS_BENEFITS_4: 'Restaurant tickets',
    CP_REVIEWS_BENEFITS_5: 'Childcare',
    CP_REVIEWS_BENEFITS_6: 'Company pension',
    CP_REVIEWS_BENEFITS_7: 'Accessibility',
    CP_REVIEWS_BENEFITS_8: 'Health in the workplace',
    CP_REVIEWS_BENEFITS_9: 'Company doctor',
    CREATE_ACCOUNT_HINT: 'Here you can manage your XING account.',
    CROSSPOST_SHARE_ERROR:
      'Sorry, but you already shared this post in 3 other groups.',
    DATA_PROTECTION: 'Edit shared personal info',
    DAY_NAMES: 'Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday',
    DAY_NAMES_SHORT: 'Mo,Tu,We,Th,Fr,Sa,Su',
    DEGREE: 'Degree',
    DESC_POS: 'Describe your position',
    DISCIPLINE_1001: 'Analysis and statistics',
    DISCIPLINE_1002: 'Administration',
    DISCIPLINE_1003: 'Consulting',
    DISCIPLINE_1004: 'Customer service',
    DISCIPLINE_1005: 'Purchasing, materials management & logistics',
    DISCIPLINE_1006: 'Finance, accounting & controlling',
    DISCIPLINE_1007: 'Teaching, R&D',
    DISCIPLINE_1008: 'Health, medical and social',
    DISCIPLINE_1009: 'Graphic design and architecture',
    DISCIPLINE_1010: 'Engineering and technical',
    DISCIPLINE_1011: 'IT and software development',
    DISCIPLINE_1012: 'Management and corporate development',
    DISCIPLINE_1013: 'Marketing and advertising',
    DISCIPLINE_1014: 'HR',
    DISCIPLINE_1015: 'PR and journalism',
    DISCIPLINE_1016: 'Production and manufacturing',
    DISCIPLINE_1017: 'Product management',
    DISCIPLINE_1018: 'Project management',
    DISCIPLINE_1019: 'Process planning & QA',
    DISCIPLINE_1020: 'Legal',
    DISCIPLINE_1021: 'Sales and commerce',
    DISCIPLINE_1022: 'Other',
    DISCO_DOT_MENU_BLOCK_MENTIONS_DIALOG_CONFIRM: 'Turn off',
    DISCO_DOT_MENU_BLOCK_MENTIONS_DIALOG_HEADLINE: 'Turn off mentions?',
    DISCO_DOT_MENU_BLOCK_MENTIONS_DIALOG_TEXT:
      "Your name won't link to your XING profile.",
    DONT_REMIND: "Please don't remind me again",
    DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_COMPANIES: 'A company',
    DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_EVENTS: 'An event',
    DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_GROUPS: 'A group',
    DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_JOBS: 'A job',
    DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_MEMBERS: 'A person',
    DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_NEWS: 'An article',
    DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_TOPICS: 'A topic',
    DS_PREDICTIVE_SEARCH_QUERY_SUGGESTIONS_JOBS:
      'Discover the many {keyword} jobs on offer',
    DS_PREDICTIVE_SEARCH_QUERY_SUGGESTIONS_JOBS_SHORT:
      'Discover {keyword} jobs',
    DS_PREDICTIVE_SEARCH_SECTION_TITLE_INCREMENTAL_SEARCH: 'Search suggestions',
    DS_PREDICTIVE_SEARCH_SECTION_TITLE_NAVIGATION_SUGGESTIONS:
      'What are you looking for?',
    DS_PREDICTIVE_SEARCH_SECTION_TITLE_QUERY_SUGGESTION: 'Search suggestions',
    DS_PREDICTIVE_SEARCH_SECTION_TITLE_RECENT_INTERACTIONS:
      'Profiles you recently visited',
    DS_PREDICTIVE_SEARCH_SECTION_TITLE_RECENT_SEARCHES:
      'Your recent job searches',
    DS_PREDICTIVE_SEARCH_SECTION_TITLE_RECOMMENDATION: 'Recommendations',
    DUPLICATE_SHARE_ERROR:
      'You already shared this so your contacts are in the loop.',
    EDIT_JOB_COMPANY: 'Edit position at &quot;{-company}&quot;',
    EDIT_JOB_HEADER: 'Edit position at &quot;{-company}&quot;',
    EDIT_MOTIVATIONS: 'Edit motivations',
    EDIT_SCHOOL: 'Edit studies at &quot;{-name}&quot;',
    EDIT_SCHOOL_HEADER: 'Edit the university/college "{-name}"',
    EDIT_WIDGET_EMAILS_LABEL_ADDITIONAL: 'Other',
    EDIT_WIDGET_EMAILS_LABEL_BUSINESS: 'Business',
    EDIT_WIDGET_EMAILS_LABEL_PRIVATE: 'Private',
    EDIT_WIDGET_UNSAVED_CHANGES: 'unsaved change(s)',
    EDUCATION: 'Educational background',
    EMAIL_PREFS: 'E-mail settings',
    EMAILS_ACTIVATE: 'Activate for notifications',
    EMAILS_ACTIVATED_BODY:
      'Your e-mail address <strong>&quot;{-email_for_flash}&quot;</strong> has been successfully activated.',
    EMAILS_ACTIVATED_HEADER: 'Activation successful',
    EMAILS_ACTIVATION_EMAIL_BODY:
      'Your notification e-mail address has not yet been changed to <strong>&quot;{-email_for_flash}&quot;</strong> as it hasn’t yet been confirmed. We have already sent a confirmation e-mail to this address. Please open the e-mail and click on the confirmation link. As soon as the new address has been successfully confirmed it will be activated as your notification address.<br/>\nThis procedure is for your own security and prevents spam.',
    EMAILS_ACTIVATION_EMAIL_HEADER: "You've got mail",
    EMAILS_ACTIVE: 'active notification address',
    EMAILS_ADDITONAL_INFO:
      '<strong>Please note:</strong> Your additional e-mail addresses are not visible to other XING members. You can nevertheless use any of them as your notification address. Your additional e-mail addresses are also screened when other members carry out an address book comparison, meaning that you can be found more easily on XING.',
    EMAILS_CHANGE_ACTIVE_BODY:
      'We have sent you an e-mail with a confirmation link for you to change you active notification address to <strong>&quot;{-email_for_flash}&quot;</strong>. The change will be implemented as soon as you click on this link!',
    EMAILS_CONFIRM: 'Confirm',
    EMAILS_CONFIRMATION_EMAIL_BODY:
      'We sent an e-mail to <strong>&quot;{-email_for_flash}&quot;</strong>. Please click on the link in this e-mail to confirm your new e-mail address.',
    EMAILS_CONFIRMED: 'confirmed',
    EMAILS_CONFIRMED_BODY:
      'Your e-mail address <strong>&quot;{-email_for_flash}&quot;</strong> has been confirmed.',
    EMAILS_DELIVERY_EMAIL_INFO:
      '<strong>Please note</strong>: According to your e-mail provider, there could also be a noticeable delay (several minutes) between sending and delivery of our confirmation e-mail. Unfortunately, XING has no influence on this practice.',
    EMAILS_NOT_VISIBLE: 'not visible to others',
    EMAILS_UNCONFIRMED: 'unconfirmed',
    EMPLOYMENT_TYPE_CONTRACTOR: 'Self-employed',
    EMPLOYMENT_TYPE_CONTRACTOR_LOCAL_SERPS: 'Freelancer',
    EMPLOYMENT_TYPE_FULL_TIME: 'Full-time',
    EMPLOYMENT_TYPE_INTERN: 'Student',
    EMPLOYMENT_TYPE_PART_TIME: 'Part-time',
    EMPLOYMENT_TYPE_SEASONAL: 'Seasonal',
    EMPLOYMENT_TYPE_TEMPORARY: 'Temp',
    EMPLOYMENT_TYPE_TEMPORARY_LOCAL_SERPS: 'Mini',
    EMPLOYMENT_TYPE_VOLUNTARY: 'Volunteer',
    ENTER_BIRTHDAY_HINT:
      'Enter the date of your birthday - and let your contacts congratulate you.',
    ENTER_COMPANY: 'Please enter the name of the company.',
    ENTER_PREV_COMPANIES_HINT:
      'Enter information on your former companies and be found by past coworkers and business partners.',
    ENTER_SCHOOL_NAME: 'Please enter the name of the university or college.',
    ENTER_TITLE: 'Please enter a job title.',
    ENTER_UNIVERSITY_HINT:
      'Enter information on your university or college and get back in touch with old friends on XING.',
    ENTER_URL: 'Please enter a valid URL.',
    EP_404_ERROR: 'This page appears to have gone with the wind.',
    EP_404_ERROR_SUBLINE: 'But go ahead and check out what else is on offer:',
    EP_500_ERROR: 'Looks like the server got tired of the hamster wheel.',
    EP_500_ERROR_SUBLINE: 'We’ll make sure it starts spinning again.',
    EP_503_ERROR: 'Looks like the server is playing cat and mouse with us.',
    EP_503_ERROR_SUBLINE:
      'Please bear with us while we get some cheese for them.',
    EP_ABOUT_EDIT_VIEW_COPY:
      'Add a brief description about yourself to let your readers know a little more about you.',
    EP_ABOUT_EDIT_VIEW_LABEL_INPUT_FIELD: 'Description',
    EP_ABOUT_EDIT_VIEW_TITLE: 'About',
    EP_ABOUT_US_DESCRIPTION: 'About us',
    EP_ABOUT_US_DISCOVER_CTA: 'Show more',
    EP_ABOUT_US_HEADLINE: 'About us',
    EP_ABOUT_US_NAVIGATION_TITLE: 'About',
    EP_ABOUT_US_SUBPAGE_EDIT_DESCRIPTION: 'Edit title and description',
    EP_ABOUT_US_SUBPAGE_LINK: 'Discover more about us',
    EP_ABOUT_VISITOR_VIEW_TITLE: 'About',
    EP_ADD_CONTACTS_SEARCH_LABEL: 'Add contact person',
    EP_ADD_CTA: 'Add',
    EP_ADD_IMAGE: 'Add image',
    EP_ADD_MORE_COMPANIES: 'Add company',
    EP_ADD_VIDEO: 'Add video',
    EP_ADMINISTRATE_PAGE: 'Manage page',
    EP_AFFILIATES: 'Affiliates',
    EP_AFFILIATES_CATEGORY_ASSOCIATE: 'Associate',
    EP_AFFILIATES_CATEGORY_CLIENT: 'Client',
    EP_AFFILIATES_CATEGORY_FRANCHISE: 'Franchise company',
    EP_AFFILIATES_CATEGORY_JOINT_VENTURE: 'Joint venture',
    EP_AFFILIATES_CATEGORY_PARENT: 'Parent company',
    EP_AFFILIATES_CATEGORY_PARTNER: 'Business partner',
    EP_AFFILIATES_CATEGORY_REGIONAL_BRANCH: 'Branch company',
    EP_AFFILIATES_CATEGORY_SERVICE_PROVIDER: 'Service provider',
    EP_AFFILIATES_CATEGORY_SUBSIDIARY: 'Subsidiary',
    EP_AFFILIATES_FEEDBACK_ERROR: 'Sorry, something went wrong.',
    EP_AFFILIATES_NO_DATA_DESCRIPTION:
      'Here you can add other companies in your group, along with service providers, customers, etc.',
    EP_AFFILIATES_NO_DATA_HEADER: 'Your affiliates',
    EP_AFFILIATES_UPSELL_BODY:
      'Add links to the employer profiles of your affiliates for a full brand overview.',
    EP_AFFILIATES_UPSELL_HEADLINE: 'Link partner profiles',
    EP_AFFILIATES_VALIDATION_NO_NAME_ERROR: 'Please enter a company name',
    EP_AFFILIATION_TYPE: 'Affiliation type',
    EP_ALERT_DIALOG_FOLLOW_BTN_CANCEL: 'Cancel',
    EP_ALERT_DIALOG_FOLLOW_BTN_CONFIRM: 'Follow',
    EP_ALERT_DIALOG_FOLLOW_BTN_OK: 'Okay',
    EP_ALERT_DIALOG_FOLLOW_INFORMATION_WEB:
      "Good news - you're already following {page}.",
    EP_ALERT_DIALOG_FOLLOW_QUESTION_WEB:
      'Read articles from {page} in Insights by following them.',
    EP_ALERT_DIALOG_FOLLOW_TITLE_WEB: 'Follow {page}',
    EP_ALL_CONTACTS: 'All contact persons',
    EP_AMOUNT_EMPLOYEES: 'Employees',
    EP_AND: 'and',
    EP_ARMSTRONG_CREATE: 'Create',
    EP_ARMSTRONG_CREATE_POST: 'Post',
    EP_ARTICLES_MODULE_DELETE_CONFIRMATION_DIALOG_CONFIRM_BUTTON: 'Delete',
    EP_ARTICLES_MODULE_DELETE_CONFIRMATION_DIALOG_DISMISS_BUTTON: 'Cancel',
    EP_ARTICLES_MODULE_DELETE_CONFIRMATION_DIALOG_MESSAGE:
      'All your work will be lost.',
    EP_ARTICLES_MODULE_DELETE_CONFIRMATION_DIALOG_TITLE: 'Delete article?',
    EP_ARTICLES_MODULE_HEADLINE: 'News',
    EP_ARTICLES_MODULE_PINNED_LABEL: 'Top article',
    EP_ARTICLES_NAVIGATION_TITLE: 'News',
    EP_ARTICLES_SOCIAL_COMMENTS_COUNTER_LABEL:
      '{counter, plural,\n    =1 {1 comment} \n    other {# comments}\n}',
    EP_ARTICLES_SOCIAL_SHARES_COUNTER_LABEL: '{counter} shares',
    EP_AWARDS_EDIT_HINT: 'Here are the awards listed in your kununu profile.',
    EP_AWARDS_HEADLINE: 'Awards',
    EP_BACK_TO_OVERVIEW: 'Back to overview',
    EP_BANNER_ROLLOUT_AUTOMATIC_ROLLOUT:
      "On 30 Nov 2020 we'll switch existing employer profiles over to a new design and migrate all of your data for you. If you prefer, you can switch now and arrange your data in the new design. Please note that you won't be able to switch back to the old design.",
    EP_BENEFIT_ACCESSIBILITY: 'Accessibility',
    EP_BENEFIT_CANTEEN: 'Canteen',
    EP_BENEFIT_CAR_PARK: 'Car park',
    EP_BENEFIT_CHILDCARE: 'Childcare',
    EP_BENEFIT_COMPANY_CAR: 'Company car',
    EP_BENEFIT_COMPANY_DOCTOR: 'Company doctor',
    EP_BENEFIT_COMPANY_PENSION: 'Company pension',
    EP_BENEFIT_CONVENIENT_TRANSPORT_LINKS: 'Convenient transport links',
    EP_BENEFIT_DISCOUNTS_SPECIAL_OFFERS: 'Discounts',
    EP_BENEFIT_DOGS_WELCOME: 'Dogs welcome',
    EP_BENEFIT_EVENTS_FOR_EMPLOYEES: 'Events for employees',
    EP_BENEFIT_FLEXITIME: 'Flexitime',
    EP_BENEFIT_HEALTH_IN_THE_WORKPLACE: 'Health in the workplace',
    EP_BENEFIT_HOME_OFFICE: 'Remote work',
    EP_BENEFIT_MOBILE_DEVICE: 'Mobile device',
    EP_BENEFIT_PRIVATE_INTERNET_USE: 'Private internet use',
    EP_BENEFIT_PROFIT_SHARING: 'Profit-sharing',
    EP_BENEFIT_RESTAURANT_TICKETS: 'Restaurant tickets',
    EP_BENEFIT_TRAINING: 'Training',
    EP_CANCEL: 'Cancel',
    EP_CHANGE_IMAGE_CTA: 'Change image',
    EP_CHANGE_TITLE_INFO:
      'Want to change your page title? Please contact <a href="mailto:customer.support@xing.com">customer.support@xing.com</a> for assistance. ',
    EP_CHANGE_TITLE_INFO_FREE:
      'Want to change your page title? Please e-mail <a href="mailto:employerprofile@xing.com">employerprofile@xing.com</a> for assistance.',
    EP_CHANGE_TITLE_INFO_FREE_V2:
      'Want to change your page title? Please e-mail <a>employerprofile@xing.com</a> for assistance.',
    EP_CHANGE_TITLE_INFO_V2:
      'Want to change your page title? Please contact <a>customer.support@xing.com</a> for assistance. ',
    EP_CHOOSE_ACTION: 'What would you like to do?',
    EP_CHOOSE_COVER: 'Select a background image',
    EP_CHOOSE_COVER_INFO_BANNER: 'How to choose the perfect cover image',
    EP_CHOOSE_COVER_INFO_BANNER_CTA: 'Learn more',
    EP_CHOOSE_LOGO:
      'Upload your company logo here so people recognise your company more easily.',
    EP_CHOOSE_SECTION: 'Crop your image:',
    EP_CLEAR_ALL: 'Reset filters',
    EP_COMMBOX_ERROR: "Sorry, that didn't work. Please try again.",
    EP_COMMBOX_PLACEHOLDER:
      'Share your thoughts, a photo or a link(…, &#8230;)',
    EP_COMMBOX_POST: 'Post',
    EP_COMMBOX_POSTING_SUCCESSFUL_MESSAGE: 'Post shared',
    EP_COMPANIES_AUTOMIGRATION_BANNER_CONTACT_SUPPORT:
      'Please contact us if you need help or if you have any questions:\n<br/>\nE-mail: <a href="mailto:customer.support@xing.com">customer.support@xing.com</a>\n<br/>\nPhone: +49 40 419 131 778',
    EP_COMPANIES_AUTOMIGRATION_BANNER_REDIRECT_ADVISE:
      'Visits to your old employer profile will be redirected to your new XING Page.',
    EP_COMPANIES_AUTOMIGRATION_BANNER_TITLE:
      "We've revamped the employer profiles!",
    EP_COMPANIES_AUTOMIGRATION_BANNER_UPDATED_URL:
      'Your employer profile link has changed to <strong>www.xing.com/pages/{slug}</strong>.',
    EP_COMPANIES_FEEDBACK_BANNER:
      'Your feedback helps us optimise the XING Employer Branding Profile for you!',
    EP_COMPANIES_FEEDBACK_BANNER_BUTTON: 'Start survey',
    EP_COMPANY_ABOUT_US_FACTS: 'Company summary',
    EP_COMPANY_ABOUT_US_INDUSTRY: 'Industry',
    EP_COMPANY_INDUSTRY: 'Industry',
    EP_COMPANY_NAME: 'Company name',
    EP_COMPANY_NAME_VALIDATION_ERROR:
      'Please enter between 2 and 64 characters.',
    EP_COMPANY_NOT_FOUND: 'Company not found',
    EP_COMPANY_PAGE_CREATION_ERROR:
      "Sorry, that didn't work. Please try again.",
    EP_COMPANY_PAGE_CREATION_INVALID_CHARACTERS_ERROR:
      'Please use letters and numbers here, without any special characters.',
    EP_COMPANY_PAGE_CREATION_NAME_ALREADY_USED_ERROR:
      "There's already a XING Page with that name. Maybe a colleague already created a XING Page for your company? Feel free to contact us if you have any questions.",
    EP_COMPANY_PAGE_CREATION_NAME_HAS_ONLY_NUMBERS_ERROR:
      'This name only seems to contains numbers. Please use letters as well.',
    EP_COMPANY_PAGE_CREATION_NAME_TOO_LONG_ERROR:
      'Sorry, this name is too long. You can use up to 80 characters.',
    EP_COMPANY_PAGE_CREATION_NAME_TOO_SHORT_ERROR:
      'Sorry, this name is too short. Please use more than two characters.',
    EP_COMPANY_PAGE_CREATION_STEP_1: 'Basic information',
    EP_COMPANY_PAGE_CREATION_STEP_2: 'Contact details',
    EP_COMPANY_PAGE_CREATION_STEP_3: 'Company logo',
    EP_COMPANY_PAGE_CREATION_STEP_4: 'Brief description',
    EP_COMPANY_RECOMMENDATIONS_HEADLINE: 'Recommended companies',
    EP_COMPANY_RECOMMENDATIONS_NAVIGATION_TITLE: 'Similar companies',
    'EP_COMPANY_SOCIAL_PROOF.one': '{username} works here.',
    'EP_COMPANY_SOCIAL_PROOF.other':
      '{numberOfContacts} of your contacts work here.',
    EP_COMPANY_SUMMARY: 'Company summary',
    EP_CONFIRMATION_LAST_STEP_CREATE_PAGE:
      "- We'll e-mail you a confirmation within 72 hours to let you know your profile has been approved.",
    EP_CONTACT_EDIT_LABEL: 'Contact label',
    EP_CONTACT_FOLLOWING: 'is following this page.',
    EP_CONTACT_WORK: 'works here.',
    EP_CONTACTS_CHANGE_OWNER_INFO:
      'To change the page owner, please e-mail <a href="mailto:{cs_email}">{cs_email}</a>',
    EP_CONTACTS_CHANGE_OWNER_INFO_V2:
      'To change the page owner, please e-mail <a>{cs_email}</a>',
    EP_CONTACTS_DESCRIPTION: 'Contact persons at',
    EP_CONTACTS_EDIT_CTA: 'Edit contact persons',
    EP_CONTACTS_EDIT_TITLE: 'Manage contact persons',
    EP_CONTACTS_EMPTY_LIST:
      "Enter the names of the people you'd like to answer queries from visitors to your XING page.",
    EP_CONTACTS_FOLLOWING: 'are following this page.',
    EP_CONTACTS_HEADLINE: 'Contact',
    EP_CONTACTS_HIGHLIGHT_INFO:
      "The first two contact persons will be displayed on your main page. All of your contact persons will be listed in the 'contact' section.",
    EP_CONTACTS_MESSENGER_INFO:
      "Please make sure the users you select are willing to act as contacts for your page. We'll notify them when your page is published.",
    EP_CONTACTS_MODULE_TITLE: 'Contact persons',
    EP_CONTACTS_NAVIGATION_TITLE: 'Contact',
    EP_CONTACTS_SEARCH_INPUT_ERROR:
      "Sorry, that didn't work. Please try again.",
    EP_CONTACTS_SEARCH_NO_RESULTS: "We didn't find anyone with that name.",
    EP_CONTACTS_SEARCH_PLACEHOLDER: 'Enter name',
    EP_CONTACTS_SUBPAGE_DESCRIPTION: 'All {pageTitle} contacts at a glance',
    EP_CONTACTS_WORK: 'work here.',
    EP_COVER_CROP_DESCRIPTION:
      '<b>Note:</b> Only the centre of the background image is visible on smaller screens (e.g. smartphones). Please use an image where your main content is within the dotted lines as this will always be visible.',
    EP_CREATE_COMPANY_PAGE_MOBILE_INFO:
      'Please use a <span>large screen</span> to create your XING Page.',
    EP_DELETE_COVER: 'Delete background image',
    EP_DELETE_COVER_DIALOG:
      'Do you really want to delete your background image? ',
    EP_DELETE_COVER_DIALOG_CONFIRM: 'Delete',
    EP_DELETE_COVER_DIALOG_HEADLINE: 'Delete background image?',
    EP_DELETE_COVER_DIALOG_KEEP: 'Cancel',
    EP_DELETE_DOCUMENT_DIALOG: 'Do you really want to delete this document?',
    EP_DELETE_DOCUMENT_DIALOG_CANCEL: 'Cancel',
    EP_DELETE_DOCUMENT_DIALOG_CONFIRM: 'Delete',
    EP_DELETE_DOCUMENT_DIALOG_HEADLINE: 'Delete document?',
    EP_DELETE_IMAGE_GM_DIALOG: 'Your image and copy will be deleted.',
    EP_DELETE_IMAGE_GM_DIALOG_CANCEL: 'Cancel',
    EP_DELETE_IMAGE_GM_DIALOG_CONFIRM: 'Yes, delete',
    EP_DELETE_IMAGE_GM_DIALOG_HEADLINE: 'Delete everything?',
    EP_DELETE_VIDEO_DIALOG_CANCEL: 'Cancel',
    EP_DELETE_VIDEO_DIALOG_CONFIRM: 'Delete',
    EP_DELETE_VIDEO_DIALOG_HEADLINE: 'Delete this video?',
    'EP_DELETE_VIDEO_DIALOG_HEADLINE (Copy)': 'Delete this video?',
    EP_DELETE_VIDEO_DIALOG_TEXT: "You won't be able to undo this.",
    EP_DIALOG_PAGE_CREATION_CLOSE: "Don't cancel",
    EP_DIALOG_PAGE_CREATION_CONFIRM: 'Close',
    EP_DISCARD_CTA: 'Discard',
    EP_DISCARD_DIALOG_CANCEL: 'Cancel',
    EP_DISCARD_DIALOG_CONFIRM: 'Discard',
    EP_DISCARD_DIALOG_HEADLINE: 'Discard changes?',
    EP_DISCARD_DIALOG_TEXT:
      "Your changes will be discarded if you don't save them.",
    EP_DISCOVER_ABOUT_US: 'Learn more',
    EP_DISCUSSION_COMMBOX_BLOCKED_BANNER:
      "You've been blocked and can’t participate in the discussion anymore. Please contact the moderation team.",
    EP_DISCUSSION_EMPTY_STATE_BODY:
      "Once someone writes a group post, you'll see it here.",
    EP_DISCUSSION_EMPTY_STATE_BODY_LO:
      "Once someone writes a group post, you'll see it here.",
    EP_DISCUSSION_EMPTY_STATE_HEADLINE: 'There are no posts yet',
    EP_DISCUSSION_EMPTY_STATE_HEADLINE_LO: 'No posts yet',
    EP_DISCUSSION_EMPTY_STATE_MODERATOR_BODY:
      'Once you publish your first post, group members will see that this group is active.',
    EP_DISCUSSION_EMPTY_STATE_MODERATOR_HEADLINE: 'Write your first group post',
    EP_DISCUSSION_HIDDEN_EMPTY_STATE_MODERATOR_BODY:
      "Hidden group posts are only visible to moderators and don't show up in the group feed. Click on the eye symbol to hide a post.",
    EP_DISCUSSION_HIDDEN_EMPTY_STATE_MODERATOR_HEADLINE:
      'No hidden group posts',
    EP_DISCUSSION_PUBLISHED_EMPTY_STATE_MODERATOR_BODY:
      'Once you publish your first post, group members will see that this group is active.',
    EP_DISCUSSION_PUBLISHED_EMPTY_STATE_MODERATOR_HEADLINE:
      'Write your first group post',
    EP_DISCUSSIONS_DELETE_POST: 'Delete this post?',
    EP_DISCUSSIONS_DELETE_POST_POPUP_BODY: "You won't be able to undo this.",
    EP_DISCUSSIONS_DELETE_POST_POPUP_CTA_CANCEL: 'Back',
    EP_DISCUSSIONS_DELETE_POST_POPUP_CTA_DELETE: 'Delete',
    EP_DISCUSSIONS_DESCRIPTION: 'Discussions',
    EP_DISCUSSIONS_EDIT_ALL: 'All posts',
    'EP_DISCUSSIONS_EDIT_ALL (Copy)': 'All posts',
    EP_DISCUSSIONS_HEADLINE: 'Discussions',
    EP_DISCUSSIONS_HIDE_POST: 'Hide post',
    EP_DISCUSSIONS_HIDE_POST_ERROR: 'Something went wrong. Please try again.',
    EP_DISCUSSIONS_HIDE_POST_POPUP_BODY:
      "It won't be visible in the group anymore.",
    EP_DISCUSSIONS_HIDE_POST_POPUP_CTA_CANCEL: 'Cancel',
    EP_DISCUSSIONS_HIDE_POST_POPUP_CTA_HIDE: 'Hide',
    EP_DISCUSSIONS_HIDE_POST_POPUP_CTA_UNHIDE: 'Unhide',
    EP_DISCUSSIONS_HIDE_POST_POPUP_HEADLINE: 'Hide this post?',
    EP_DISCUSSIONS_MARK_POST: 'Pin this post',
    EP_DISCUSSIONS_NAVIGATION_TITLE: 'Discussions',
    EP_DISCUSSIONS_REPORT_POST: 'Report this post',
    EP_DISCUSSIONS_UNHIDE_POST: 'Unhide',
    EP_DISCUSSIONS_UNHIDE_POST_POPUP_BODY:
      'Doing so will make it visible in group discussions again.',
    EP_DISCUSSIONS_UNHIDE_POST_POPUP_CTA_CANCEL: 'Cancel',
    EP_DISCUSSIONS_UNHIDE_POST_POPUP_CTA_HIDE: 'Unhide',
    EP_DISCUSSIONS_UNHIDE_POST_POPUP_HEADLINE: 'Unhide this post?',
    EP_DOCUMENT_TITLE_EDIT_ERROR: 'Please give your document a title.',
    EP_DOCUMENT_TITLE_EDIT_HINT: "Add your document's title here.",
    EP_DOCUMENT_TITLE_EDIT_LABEL: 'Document title',
    EP_DOCUMENTS_HEADLINE: 'Documents',
    EP_DONE_CTA: 'Done',
    EP_DRAFT_LAST_STEP_CREATE_PAGE:
      "– Please note that your employer profile is still in draft mode, meaning that it won't be published until we approve it.",
    EP_DRAFT_REPUBLISH_CTA: 'Post',
    EP_DRAFT_REPUBLISH_DESCRIPTION:
      'The complaint about this page has been rejected so it can be published again.',
    EP_DUMMY_COMMBOX_PLACEHOLDER: 'Create a new post…',
    EP_EDIT_ABOUT_DATA_TITLE: 'Edit details',
    EP_EDIT_ABOUTMETA_CTA: 'Edit details',
    EP_EDIT_ABOUTUS_COPY_TO_INTRO:
      'Use the first few lines from the detailed description as your brief description',
    EP_EDIT_ABOUTUS_DESCRIPTION: 'About us description',
    EP_EDIT_ABOUTUS_DESCRIPTION_DISCARD: 'Cancel',
    EP_EDIT_ABOUTUS_DESCRIPTION_PUBLISH: 'Save',
    EP_EDIT_ABOUTUS_DETAILED_REQUIRED:
      "Please add a description to the 'about us' section.",
    EP_EDIT_ABOUTUS_EXTERNALVIDEO_INFO:
      'Video links will be discontinued soon. Instead, you can upload your videos to XING directly, including subtitles and previews.',
    EP_EDIT_ABOUTUS_HINT:
      "<b>Tip:</b> Present your most important products and / or services here. Also, to improve the discoverability of your page (SEO), use keywords that your target audience might be looking for in your brief description.\nYou can enter both a short and a detailed description – or just the latter, in which case we'll use the first sentences as your short description.",
    EP_EDIT_ABOUTUS_INDUSTRIES_INTRO:
      "What's your company's industry? Add up to 5 industries here so people can find your page more easily and so we can suggest it to the right members.",
    EP_EDIT_ABOUTUS_INTRO_DESCRIPTION: 'Brief description',
    EP_EDIT_ABOUTUS_INTRO_DESCRIPTION_LABEL: 'Brief description',
    EP_EDIT_ABOUTUS_INTRO_INFO_TEXT:
      "This brief description appears in the 'About us' section of the overview page, and is also used by search engines.",
    EP_EDIT_ABOUTUS_INTRO_TITLE: 'Title',
    EP_EDIT_ABOUTUS_INTRO_TITLE_LABEL: 'Title',
    EP_EDIT_ABOUTUS_NAV_DESCRIPTION: 'Detailed description',
    EP_EDIT_ABOUTUS_NAV_FACTS: 'Company summary',
    EP_EDIT_ABOUTUS_NAV_INDUSTRIES: 'Industries',
    EP_EDIT_ABOUTUS_NAV_INTRO: 'Brief description',
    EP_EDIT_ABOUTUS_SUMMARY: 'About us summary',
    EP_EDIT_ACTION_DISCARD: 'Cancel',
    EP_EDIT_ACTION_POST: 'Post',
    EP_EDIT_ACTION_PROMOTE: 'Promote page',
    EP_EDIT_ACTION_PUBLISH: 'Done',
    EP_EDIT_ACTION_SET_EDIT_MODE_OFF: 'See visitor view',
    EP_EDIT_ACTION_SET_EDIT_MODE_ON: 'Edit page',
    EP_EDIT_ACTION_WRITE_ARTICLE: 'Write article',
    EP_EDIT_AFFILIATES: 'Edit affiliates',
    EP_EDIT_AFFILIATES_CONFIRM_DIALOG_BODY:
      'Are you sure you want to delete the affiliate {companyName}?',
    EP_EDIT_AFFILIATES_CONFIRM_DIALOG_BODY_MARKUP:
      'Are you sure you want to delete the affiliate <strong>{companyName}</strong>?',
    EP_EDIT_AFFILIATES_CONFIRM_DIALOG_CANCEL: 'Cancel',
    EP_EDIT_AFFILIATES_CONFIRM_DIALOG_CONFIRM: 'Yes, delete',
    EP_EDIT_AFFILIATES_DESCRIPTION:
      "Here you can add subsidiaries, customers, service providers, etc. They'll need a XING employer profile of their own, and you should get their permission before adding them.",
    EP_EDIT_AFFILIATES_INFO:
      'You can offer even more information by adding subsidiaries, customers, service providers, etc. They need to have their own XING Page, and you should obtain their consent before adding them.',
    EP_EDIT_BACK_GALLERY: 'Back to gallery',
    EP_EDIT_COMPANY_FACTS_TITLE: 'Company summary',
    EP_EDIT_COMPANYSIZEID: 'Employees',
    EP_EDIT_COMPANYSIZEID_FIELD_LABEL: 'Employees',
    EP_EDIT_CONTACTS: 'Edit contact',
    EP_EDIT_CONTACTS_DELETE: 'Really remove this contact from your page?',
    EP_EDIT_CONTACTS_DELETE_CANCEL_CONFIRM: 'Remove',
    EP_EDIT_CONTACTS_DELETE_CANCEL_CTA: 'Cancel',
    EP_EDIT_CONTACTS_DELETED: 'Contact person removed',
    EP_EDIT_CONTACTS_DESCRIPTION_INPUT_FIELD:
      'Tip: Use this space to specify the topics this person can be contacted about.',
    EP_EDIT_CONTACTS_FREE_INFO:
      'Here you can add a contact person for your page who can respond to queries. To change the contact person listed here, simply add someone else and the previous contact person will be removed as you can only have one contact person.',
    EP_EDIT_CONTACTS_NAV_CONTACTS: 'Contact person',
    EP_EDIT_CONTACTS_NAV_LOCATION: 'Location',
    EP_EDIT_CONTACTS_PAID_INFO:
      "Here you can add a contact person for your page who can respond to queries. The first two contact persons will be displayed in the overview as well as under 'contact persons'.",
    EP_EDIT_DOCUMENTS: 'Edit documents',
    EP_EDIT_EVENT_ALL_LINK: 'All events',
    EP_EDIT_EVENT_COPY_EVENT: 'Copy event',
    EP_EDIT_EVENT_DRAFT_MESSAGE_BODY:
      "Once it's ready and live, you can invite everyone and promote your group.",
    EP_EDIT_EVENT_DRAFT_MESSAGE_CTA: 'Continue editing',
    EP_EDIT_EVENT_DRAFT_MESSAGE_HEADLINE:
      'Time to tell your group about your next event!',
    EP_EDIT_EVENT_EDIT_EVENTS: 'Manage events',
    EP_EDIT_EVENT_EMPTY_STATE_BODY:
      'With events focussed around a specific topic, lively discussions, shared experiences or a new way to connect with people.',
    EP_EDIT_EVENT_EMPTY_STATE_CTA: 'Organise a group event',
    EP_EDIT_EVENT_EMPTY_STATE_FAQ_LINK:
      'Need help? Here are some tips for your group event.',
    EP_EDIT_EVENT_EMPTY_STATE_HEADLINE:
      'Foster your group and attract new members - online or offline',
    EP_EDIT_EVENT_ORGANISE_CTA: 'Create new event',
    EP_EDIT_EVENT_PAST_EMPTY_STATE_BODY:
      'Regular meetings help you engage your group members, keep them in the loop and attract new ones.',
    EP_EDIT_EVENT_PAST_EMPTY_STATE_CTA: 'Copy event',
    EP_EDIT_EVENT_PAST_EMPTY_STATE_HEADLINE:
      'Time to organise your next group event!',
    EP_EDIT_EVENT_PAST_EMPTY_STATE_TIP:
      'Tip: Save time by copying your last event.',
    'EP_EDIT_EVENT_PAST_TITLE.one': 'Past event',
    'EP_EDIT_EVENT_PAST_TITLE.other': 'Past events',
    'EP_EDIT_EVENT_PAST_TITLE.zero': 'Past events',
    EP_EDIT_EVENT_SUBPAGE_BACK_LINK: 'Back to overview',
    EP_EDIT_EVENT_SUBPAGE_COPY_EVENT_BUTTON: 'Copy event',
    EP_EDIT_EVENT_SUBPAGE_DRAFT_MESSAGE_BODY:
      'The sooner you post your event, the longer people have an opportunity to see and add it to their calendar.',
    'EP_EDIT_EVENT_SUBPAGE_DRAFT_MESSAGE_HEADLINE.one':
      "Reminder: You've got a draft event waiting to be published",
    'EP_EDIT_EVENT_SUBPAGE_DRAFT_MESSAGE_HEADLINE.other':
      "Reminder: You've got draft events waiting to be published",
    'EP_EDIT_EVENT_SUBPAGE_DRAFT_MESSAGE_HEADLINE.zero':
      'Tell your group about your next event!',
    EP_EDIT_EVENT_SUBPAGE_DRAFT_TAB: 'Drafts',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_4_HELP_BODY:
      'Here are some <a>tips for your next group event</a>.',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_4_TITLE_HELP: 'Need some help?',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_BODY:
      'Bring your group together by organising an event where members can meet up and network to foster a feeling of community.',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_BULLET_1_BODY:
      "First, pick a date for your event and set whether it'll be online or offline. If you're holding it offline, add the venue.",
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_BULLET_1_TITLE: '1. Basic information',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_BULLET_2_BODY:
      'Next, you should add more details about your event - will there be presentations and networking opportunities? How can people register online and what do they need if attending in person?',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_BULLET_2_TITLE: '2. Fine-tuning',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_BULLET_3_BODY:
      'Once your event page is ready, start inviting people from your group along with contacts from your network or industry. Thanks to viral effects, this will generate more buzz among other XING members.',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_BULLET_3_TITLE: '3. Guests',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_BULLET_HEADLINE:
      'How to create an event:',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_CTA: 'Organise a group event',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_FAQ_LINK:
      'Need help? Here are some tips for your event.',
    EP_EDIT_EVENT_SUBPAGE_EMPTY_STATE_HEADLINE:
      'Organise your next group event',
    EP_EDIT_EVENT_SUBPAGE_LIVE_TAB: 'Live',
    EP_EDIT_EVENT_SUBPAGE_MORE_BUTTON: 'More',
    EP_EDIT_EVENT_SUBPAGE_PAST_EMPTY_STATE_BODY:
      'Regular meetings help you engage your group members, keep them in the loop and attract new ones.',
    EP_EDIT_EVENT_SUBPAGE_PAST_EMPTY_STATE_HEADLINE:
      'Time to organise your next group event!',
    EP_EDIT_EVENT_SUBPAGE_PAST_EMPTY_STATE_TIP:
      'Tip: Save time by cloning your last group event.',
    EP_EDIT_EVENT_SUBPAGE_PAST_TAB: 'Archived',
    'EP_EDIT_EVENT_UPCOMING_TITLE.one': 'Upcoming event',
    'EP_EDIT_EVENT_UPCOMING_TITLE.other': 'Upcoming events',
    'EP_EDIT_EVENT_UPCOMING_TITLE.zero': 'Upcoming events',
    EP_EDIT_FOUNDINGYEAR: 'Established in',
    EP_EDIT_FOUNDINGYEAR_FIELD_LABEL: 'Year of establishment',
    EP_EDIT_GALLERY: 'Edit gallery',
    EP_EDIT_GALLERY_EMPTY_STATE_BODY:
      'Make your page interesting and unique: with pictures and videos.',
    EP_EDIT_GALLERY_EMPTY_STATE_CTA_IMAGE: 'Add image',
    EP_EDIT_GALLERY_EMPTY_STATE_CTA_VIDEO: 'Add video',
    EP_EDIT_GALLERY_EMPTY_STATE_HEADER:
      'Looking good: Your very own XING Page.',
    EP_EDIT_GROUP_RULES: 'Edit group rules',
    EP_EDIT_IMPRINT: 'Legal notice',
    EP_EDIT_IMPRINT_FIELD_LABEL: 'Legal notice',
    EP_EDIT_INDUSTRY: 'Industry',
    EP_EDIT_INDUSTRY_FIELD_LABEL: 'Industry',
    EP_EDIT_INDUSTRYSEGMENT: 'Segment',
    EP_EDIT_INDUSTRYSEGMENT_FIELD_LABEL: 'Segment',
    EP_EDIT_INFO_EDIT_VIEW: 'Edit mode active',
    EP_EDIT_INFO_MOBILE:
      'Please use a <span>large screen</span> to edit this page.\t',
    EP_EDIT_INFO_MOBILE_EDITABLE:
      "Unfortunately not all elements can be edited on small-screen. But we're on it.",
    EP_EDIT_INFO_VISITOR_VIEW: 'Visitor view active',
    EP_EDIT_LOCATION_ADDRESS: 'Street and number',
    EP_EDIT_LOCATION_ADDRESSSUFFIX: 'Address suffix',
    EP_EDIT_LOCATION_CITY: 'City',
    EP_EDIT_LOCATION_COUNTRY: 'Country',
    EP_EDIT_LOCATION_EMAIL: 'E-mail',
    EP_EDIT_LOCATION_FAXNUMBER: 'Fax',
    EP_EDIT_LOCATION_FAXNUMBERCOUNTRYCODE: 'Country code',
    EP_EDIT_LOCATION_LABEL: 'Location',
    EP_EDIT_LOCATION_PHONENUMBER: 'Phone',
    EP_EDIT_LOCATION_PHONENUMBERCOUNTRYCODE: 'Country code',
    EP_EDIT_LOCATION_POSTCODE: 'Postcode',
    EP_EDIT_LOCATION_WEBSITEURL: 'Website',
    EP_EDIT_PAGEINFO_COMPANY_BANNER:
      'Want to change the page title? Please get in touch with us directly.',
    EP_EDIT_SECTION: 'Edit crop',
    EP_EDIT_SELECTION: 'Edit entries',
    EP_EDIT_SUBPAGE_LINK: 'Edit subpage',
    EP_EDIT_WEBSITEURL: 'Website',
    EP_EDIT_WEBSITEURL_FIELD_LABEL: 'Website',
    EP_EDITBAR_MANAGE_UPDATES: 'Manage updates',
    EP_EDITOR_BODY_PLACEHOLDER: 'Add a description of your company.',
    EP_EDITOR_TITLE_PLACEHOLDER: "Enter a title, e.g. 'Who we are'.",
    EP_EMPLOYEE: 'employee',
    EP_EMPLOYEE_CARD_SHARED_CONTACTS: '{sharedContactsNumber} shared contacts',
    'EP_EMPLOYEE_CARD_SHARED_CONTACTS_WEB.one': '{value} shared contact',
    'EP_EMPLOYEE_CARD_SHARED_CONTACTS_WEB.other': '{value} shared contacts',
    'EP_EMPLOYEE_CARD_SHARED_CONTACTS_WEB.zero': 'No shared contacts',
    EP_EMPLOYEE_FENCED:
      'Log in to see all {total} employees on XING and your connections to the company.',
    EP_EMPLOYEES: 'employees',
    EP_EMPLOYEES_BUTTON_ADD_CONTACT: 'Add',
    EP_EMPLOYEES_BUTTON_REGISTER_NOW: 'Register now',
    EP_EMPLOYEES_BUTTON_SEND_MESSAGE: 'Message',
    EP_EMPLOYEES_BUTTON_SIGN_IN: 'Log in',
    EP_EMPLOYEES_COUNT: '{employeesNumber} employees',
    EP_EMPLOYEES_DESCRIPTION: 'Employees',
    EP_EMPLOYEES_EDIT_HINT:
      'Here are all the XING members who set your company as their employer.',
    EP_EMPLOYEES_EMPTY_STATE:
      'No member has yet indicated this company as employer.',
    EP_EMPLOYEES_FEEDBACK_ERROR: 'Sorry, something went wrong.',
    EP_EMPLOYEES_HEADLINE: 'Employees',
    EP_EMPLOYEES_LINK: 'See all {number} employees on XING',
    EP_EMPLOYEES_LOAD_MORE_CTA: 'More employees',
    EP_EMPLOYEES_NAVIGATION_TITLE: 'Employees',
    EP_EMPLOYEES_NUMBER_EMPTY_STATE: 'Employees',
    EP_EMPLOYEES_OR: 'or',
    EP_EMPLOYEES_SIGNIN: 'Log in',
    EP_EMPLOYEES_SUBPAGE_DESCRIPTION:
      'All XING members working at {pageTitle} at a glance',
    EP_EMPLOYEES_TEXT_CTA_REGISTER_TO_SEE:
      'Discover all {employeesNumber} employees on XING.',
    EP_EMPLOYEES_TEXT_PROFILE_IMAGE_ALT: "{profileName}'s profile photo",
    EP_EMPLOYEES_VIEW_PROFILE: 'View profile',
    EP_ERROR: 'It looks like we’re having a few problems here.',
    EP_ERROR_BODY: 'Please reload to try again.',
    EP_ERROR_HEADER: 'It looks like we’re having a few problems here.',
    EP_ERROR_RELOAD_CTA: 'Reload',
    EP_EVENT_ALL_CTA: 'All events',
    EP_EVENT_ALL_LINK: 'All events',
    EP_EVENT_DELETE_ERROR: 'Sorry, something went wrong. Please try again.',
    EP_EVENT_EMPTY_STATE_BODY:
      'Members will be notified about upcoming group events.',
    EP_EVENT_EMPTY_STATE_HEADLINE:
      "This group hasn't organised any events yet.",
    EP_EVENT_PAST_TITLE: 'Past event',
    EP_EVENT_SUBPAGE_BACK_LINK: 'Back to overview',
    EP_EVENT_SUBPAGE_EMPTY_STATE_PAST:
      "This group hasn't organised any events yet.",
    EP_EVENT_SUBPAGE_EMPTY_STATE_PAST_BODY:
      "Once the next group event's coming up, members will be notified about it.",
    EP_EVENT_SUBPAGE_EMPTY_STATE_UPCOMING:
      'There are no upcoming events for this group.',
    EP_EVENT_SUBPAGE_EMPTY_STATE_UPCOMING_BODY:
      "Once the next group event's coming up, members will be notified about it.",
    EP_EVENT_SUBPAGE_MORE_CTA: 'More',
    EP_EVENT_SUBPAGE_PAST_TAB: 'Past events',
    EP_EVENT_SUBPAGE_UPCOMING_TAB: 'Upcoming events',
    'EP_EVENT_UPCOMING_TITLE.one': 'upcoming event',
    'EP_EVENT_UPCOMING_TITLE.other': 'upcoming events',
    'EP_EVENT_UPCOMING_TITLE.zero': 'upcoming events',
    EP_EVENTS_DESCRIPTION: 'Events',
    EP_EVENTS_HEADLINE: 'Events',
    EP_EVENTS_NAVIGATION_TITLE: 'Events',
    EP_EXISTING_COMPANY_NAME:
      'This name is already in use on XING. Please use a different one.',
    EP_FAX: 'Fax',
    EP_FEEDBACK_BUTTON: 'Feedback?',
    EP_FIELD_EMPTY_OR_MIN_CHARACTERS_ERROR:
      'Please leave it empty or enter at least {value} characters here.',
    EP_FIELD_MAX_CHARACTERS_ERROR:
      'You can enter up to to {value} characters here.',
    EP_FIELD_REQUIRED_ERROR: 'Please check this entry again.',
    EP_FILE_SIZE_FORMAT_ERROR: 'Please select a PDF file up to 100 MB in size.',
    EP_FIND_US_HERE: 'Where to find us:',
    EP_FLAG_EMPLOYER_BRANDING_PROFILE: 'Employer Branding Profile',
    EP_FOLLOW: 'Follow',
    'EP_FOLLOWERS_COUNT_WEB.one': '{value} follower',
    'EP_FOLLOWERS_COUNT_WEB.other': '{value} followers',
    'EP_FOLLOWERS_COUNT_WEB.zero': 'No followers',
    'EP_FOLLOWERS_SOCIAL_PROOF.one': '{username} is following this page.',
    'EP_FOLLOWERS_SOCIAL_PROOF.other':
      '{numberOfContacts} of your contacts are following this page.',
    'EP_FOLLOWERS.one': 'follower',
    'EP_FOLLOWERS.other': 'followers',
    'EP_FOLLOWERS.zero': 'followers',
    EP_FOLLOWING: 'Following',
    EP_FORMATTEDTIMESTAMP_DAYSAGO: '{number} days ago',
    EP_FORMATTEDTIMESTAMP_DAYSAGO_SHORT: '{number} days',
    EP_FORMATTEDTIMESTAMP_TODAY: 'Today',
    EP_FORMATTEDTIMESTAMP_YESTERDAY: 'Yesterday',
    EP_FOUNDING_YEAR: 'Founded in {foundingYear}',
    EP_FOUNDING_YEAR_EMPTY_STATE: 'Year of establishment',
    EP_FREE_ADD_CONTACT_INFO:
      "You can only enter one contact person for your page, so we've added your previous contact person again.",
    EP_FREE_CONTACTS_PROBLEM:
      "Unfortunately, you can't change the contact person at the moment. Please bear with us - we're working on it.",
    EP_GALLERY_MANAGER_INFO:
      "Here you can move, edit and delete images and videos. The first four will be displayed in the 'media' module of your XING page.",
    EP_GENERAL_ERROR_BUTTON: 'Try again',
    EP_GENERAL_ERROR_TEXT: 'Please try again.',
    EP_GENERAL_ERROR_TITLE: 'It looks like we’re having a few problems here.',
    EP_GENERAL_FEEDBACK_ERROR: "Sorry, that didn't work. Please try again.",
    EP_GENERIC_FEEDBACK_ERROR:
      'Looks like the server got tired of the hamster wheel. Please try again.',
    EP_GM_IMAGE_CAPTION_HINT: 'Add a caption here',
    EP_GM_IMAGE_CAPTION_LABEL: 'Caption',
    EP_GM_IMAGE_INFO_BANNER: 'How to choose the right images',
    EP_GM_IMAGE_INFO_BANNER_CTA: 'Learn more',
    EP_GM_IMAGE_WILL_BE_DELETED: 'Image deleted',
    EP_GM_NO_MEDIA:
      'Start creating your gallery by uploading images and videos about your company.',
    EP_GM_NO_MEDIA_HEADLINE: 'Showcase your company here.',
    EP_GM_VIDEO_ENCODING: 'Encoding your <span>{video_name}</span> video...',
    EP_GM_VIDEO_ENCODING_FAILED_INFO:
      "Your video couldn't be played and will be removed. Please use a video in .avi .mov or .mp4 format with a ratio of 16:9 format and a maximum size of 5 GB. ",
    EP_GM_VIDEO_ENCODING_FAILED_TITLE:
      "Your  <span>{video_name}</span> video couldn't be encoded.",
    EP_GM_VIDEO_ENCODING_MESSAGE:
      "We'll e-mail you once it's ready and public.",
    EP_GM_VIDEO_WILL_BE_DELETED: 'Video deleted',
    EP_GO_HOME: 'Go to homepage',
    EP_GROUP_ABOUT_LEGAL_NOTICE: 'Legal notice',
    EP_GROUP_ABOUT_US_BOX_CATEGORIES_TITLE: 'Topic',
    EP_GROUP_ABOUT_US_BOX_HEADLINE: 'Info',
    EP_GROUP_ABOUT_US_CATEGORY: 'Topic',
    'EP_GROUP_ABOUT_US_EVENTS.one': '{amount} event',
    'EP_GROUP_ABOUT_US_EVENTS.other': '{amount} events',
    'EP_GROUP_ABOUT_US_EVENTS.zero': 'No events',
    EP_GROUP_ABOUT_US_FACTS: 'Info',
    'EP_GROUP_ABOUT_US_MEMBERS.one': '{amount} member',
    'EP_GROUP_ABOUT_US_MEMBERS.other': '{amount} members',
    'EP_GROUP_ABOUT_US_MEMBERS.zero': 'No members',
    EP_GROUP_ABOUT_US_RULES_EMPTY_STATE_BODY:
      "Let group members know what's okay and what's not.",
    EP_GROUP_ABOUT_US_RULES_EMPTY_STATE_CTA: 'Add group rules',
    EP_GROUP_ABOUT_US_RULES_EMPTY_STATE_EDIT: 'Edit group rules',
    EP_GROUP_ABOUT_US_RULES_EMPTY_STATE_HEADER: 'Group rules',
    EP_GROUP_ABOUT_US_RULES_EMPTY_STATE_HELP_TEXT:
      'Share your group rules, such as:\n<ul>\n<li>What kind of posts are fine and what will be considered spam</li>\n<li>General etiquette and respectful interaction</li>\n</ul>\nMake sure your rules are easy to read, e.g. by using simple language and applying formatting such as bullet points.',
    EP_GROUP_ABOUT_US_RULES_PLACEHOLDER:
      "Here you can let group members know what's okay and what's not.",
    EP_GROUP_ABOUT_US_TITLE: 'About us',
    EP_GROUP_ABOUT_US_UPCOMING_EVENTS_NONE: 'No upcoming events',
    'EP_GROUP_ABOUT_US_UPCOMING_EVENTS_WEB.one': '{amount} upcoming event',
    'EP_GROUP_ABOUT_US_UPCOMING_EVENTS_WEB.other': '{amount} upcoming events',
    'EP_GROUP_ABOUT_US_UPCOMING_EVENTS_WEB.zero': 'No upcoming events',
    'EP_GROUP_ABOUT_US_UPCOMING_EVENTS.one': '%,d upcoming event',
    'EP_GROUP_ABOUT_US_UPCOMING_EVENTS.other': '%,d upcoming events',
    'EP_GROUP_ABOUT_US_UPCOMING_EVENTS.zero': 'No upcoming events',
    EP_GROUP_ABOUT_US_VISIBILITY_PRIVATE: 'Private',
    EP_GROUP_ABOUT_US_VISIBILITY_PUBLIC: 'Public',
    EP_GROUP_CREATION_CANCEL_CTA: 'Discard group',
    EP_GROUP_CREATION_CANCEL_KEEP_CTA: 'No, keep it',
    EP_GROUP_CREATION_CANCEL_TEXT: "You'll lose all your changes on this page.",
    EP_GROUP_CREATION_CANCEL_TITLE:
      'Are you sure you want to discard your group?',
    EP_GROUP_CREATION_PROGRESS_BAR_TITLE_1: 'Name & settings',
    EP_GROUP_CREATION_PROGRESS_BAR_TITLE_2: 'Topic',
    EP_GROUP_CREATION_PROGRESS_BAR_TITLE_3: 'Description',
    EP_GROUP_CREATION_PROGRESS_BAR_TITLE_4: 'Logo',
    EP_GROUP_CREATION_STEP_1_CONDUCT_BULLET_1:
      'encourage people to share information and network together',
    EP_GROUP_CREATION_STEP_1_CONDUCT_BULLET_2:
      'help your group members to make new connections',
    'EP_GROUP_CREATION_STEP_1_CONDUCT_BULLET_2\n':
      'help your group members to make new connections',
    EP_GROUP_CREATION_STEP_1_CONDUCT_BULLET_3:
      'organise regular events (e.g. meet-ups, workshops or lightning talks)',
    EP_GROUP_CREATION_STEP_1_CONDUCT_LEGAL: 'Yes, I accept the {link}.',
    EP_GROUP_CREATION_STEP_1_CONDUCT_LEGAL_LINK:
      'code of conduct for moderators',
    EP_GROUP_CREATION_STEP_1_CONDUCT_TITLE_FEMALE:
      'As a group moderator you...',
    EP_GROUP_CREATION_STEP_1_CONDUCT_TITLE_MALE: 'As a group moderator you...',
    EP_GROUP_CREATION_STEP_1_CONDUCT_TITLE_OTHER: 'As a group moderator you...',
    EP_GROUP_CREATION_STEP_1_LOCATION_HELP_TEXT:
      'Enter a city if you want us to suggest your group to members who live or work there. Of course, everyone else can still join your group, too.',
    EP_GROUP_CREATION_STEP_1_LOCATION_OPTIONAL: '(optional)',
    EP_GROUP_CREATION_STEP_1_LOCATION_TITLE:
      'Choose a city for your group so people in the area will see it.',
    EP_GROUP_CREATION_STEP_1_NAME_HEADER: "What’s your group's name?",
    EP_GROUP_CREATION_STEP_1_NAME_HELP_TEXT:
      'The group name is the first thing people will see, so choose one that clearly conveys what your group is about.',
    EP_GROUP_CREATION_STEP_1_NAME_PLACEHOLDER: 'e.g. {city} Marketing',
    EP_GROUP_CREATION_STEP_1_TITLE: 'Name & settings',
    EP_GROUP_CREATION_STEP_1_VISIBILITY_HELP_TEXT:
      "If a group is public, all XING members can read posts there and see who's a group member.<br> \nIf a group is private, posts there are only available to group members.<br> \nPrivacy settings for events are separate from privacy settings for groups. This means that public groups can organise private events, and vice versa.",
    EP_GROUP_CREATION_STEP_1_VISIBILITY_PRIVATE:
      "Private (you can't change this later)",
    EP_GROUP_CREATION_STEP_1_VISIBILITY_PUBLIC: 'Public',
    EP_GROUP_CREATION_STEP_1_VISIBILITY_TITLE:
      'Do you want your group to be public or private?',
    EP_GROUP_CREATION_STEP_2_CATEGORY_HEADER:
      'Select topics that match your group.',
    EP_GROUP_CREATION_STEP_2_CATEGORY_HELPTEXT:
      'You can add up to 20 topics to help prospective members find your group and attend your events.',
    EP_GROUP_CREATION_STEP_2_CATEGORY_PLACEHODER:
      'e.g. marketing or project management',
    EP_GROUP_CREATION_STEP_3_DESCRIPTION_HEADER: "What's your group about?",
    EP_GROUP_CREATION_STEP_3_DESCRIPTION_HELP:
      "You can base your description on the answers to these questions:\n<ul>\n<li>Who might be interested in your group?</li>\n<li>What's it about?</li>\n<li>How often do you host events?</li>\n\nYou can use ({min}-{max} characters and format the text.",
    EP_GROUP_CREATION_STEP_3_DESCRIPTION_PLACEHOLDER:
      'e.g. Inspire and be inspired by meeting others in the industry and learning what works for them and where they maybe went wrong. ',
    EP_GROUP_CREATION_STEP_4_CTA_CREATE_GROUP: 'Request group approval',
    EP_GROUP_CREATION_STEP_4_UPLOAD_LOGO_CTA: 'Select file',
    EP_GROUP_CREATION_STEP_4_UPLOAD_LOGO_HINT:
      'JPG, BMP or PNG (max. 20 MB), ratio of 16:9',
    EP_GROUP_CREATION_STEP_4_UPLOAD_LOGO_TITLE:
      'Drag & drop or select a logo file.',
    EP_GROUP_CREATION_SUCCESS_BULLET_1:
      "We'll run a quick check to make sure it meets our requirements. This can take up to 48 hours. We'll e-mail you when your group is live.",
    EP_GROUP_CREATION_SUCCESS_BULLET_2:
      'In the meantime, you can go ahead and add a banner, images or a video. (Please use a desktop computer.)',
    EP_GROUP_CREATION_SUCCESS_BULLET_3:
      "Prepare a welcome post for new group members so you're ready when your group goes live.",
    EP_GROUP_CREATION_SUCCESS_CTA_BACK: 'Back to groups',
    EP_GROUP_CREATION_SUCCESS_HEADLINE:
      'You just created your new <name> group.',
    EP_GROUP_CREATION_SUCCESS_LARGE_SCREEN_CTA: 'Go to group',
    EP_GROUP_CREATION_SUCCESS_SMALL_SCREEN_CTA: 'Go to group',
    EP_GROUP_DELETION_CTA: 'Delete now',
    EP_GROUP_DELETION_ERROR:
      "Sorry, your group couldn't be deleted. Please try again.",
    EP_GROUP_DELETION_KEEP_CTA: 'Keep group',
    EP_GROUP_DELETION_SUCCESS: 'Your group has been deleted.',
    EP_GROUP_DELETION_TITLE: 'Are you sure you want to delete this group?',
    EP_GROUP_DISCUSSIONS_LOAD_COMMBOX_ERROR:
      "We're having trouble creating posts right now. Please try reloading.",
    EP_GROUP_DISCUSSIONS_LOAD_COMMBOX_ERROR_CTA: 'Reload',
    EP_GROUP_DISCUSSIONS_LOAD_MORE_ERROR:
      'We had a little trouble loading more posts. Please try again.',
    'EP_GROUP_HEADER_MEMBERS.one': 'member',
    'EP_GROUP_HEADER_MEMBERS.other': 'members',
    'EP_GROUP_HEADER_MEMBERS.zero': 'members',
    EP_GROUP_HEADER_VISIBILITY_PRIVATE: 'Private',
    EP_GROUP_HEADER_VISIBILITY_PUBLIC: 'Public',
    EP_GROUP_INFO_BLOCKED: 'Blocked',
    EP_GROUP_LEAVE_OWNER_ERROR_POP_UP:
      'To leave this group, please transfer ownership first by contacting our support team.',
    EP_GROUP_LEAVE_OWNER_ERROR_POP_UP_CLOSE_CTA: 'Not now',
    EP_GROUP_LEAVE_OWNER_ERROR_POP_UP_CONTACT_CTA: 'Contact',
    EP_GROUP_LEAVE_OWNER_ERROR_POP_UP_TITLE: "You're the owner of this group.",
    EP_GROUP_LEAVE_TRANSFER_OWNERSHIP_EMAIL_ADDRESS: 'en-support@xing.com',
    EP_GROUP_LEAVE_TRANSFER_OWNERSHIP_SUBJECT:
      "I'd like to transfer ownership of my group.",
    EP_GROUP_LOCATION_TITLE: 'Location',
    EP_GROUP_MEMBER_ADD_CONTACT: 'Add',
    EP_GROUP_MEMBER_ADD_CONTACT_ERROR:
      'Sorry, something went wrong. Please try again.',
    EP_GROUP_MEMBER_ALL_LINK: 'All group members',
    'EP_GROUP_MEMBER_CONTACT_SHARED_CONTACTS.one': '%d shared contact',
    'EP_GROUP_MEMBER_CONTACT_SHARED_CONTACTS.other': '%d shared contacts',
    'EP_GROUP_MEMBER_CONTACT_SHARED_CONTACTS.zero': '%d shared contacts',
    EP_GROUP_MEMBER_EDIT: 'Manage members',
    EP_GROUP_MEMBER_INVITE_CTA: 'Invite members',
    EP_GROUP_MEMBER_INVITE_PLACEHOLDER:
      'Hi, \n\nI\'d like to invite you to join the "{group_name}" group. Looking forward to seeing you there! \n\nBest, \n{invitor_name}',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_BLOCKED_EMPTY_BODY:
      'All members can access the group and read, write and comment on posts.',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_BLOCKED_EMPTY_TITLE: 'No one is blocked',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_BLOCKED_SINCE: 'Blocked since {date}',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_BLOCKED_SWITCHER: 'Blocked',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_EXCLUDED_EMPTY_BODY:
      'People you exclude lose their membership rights. If you remove the exclusion, they can join the group again.',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_EXCLUDED_EMPTY_TITLE:
      'No one is excluded right now',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_EXCLUDED_SINCE: 'Excluded since {date}',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_EXCLUDED_SWITCHER: 'Excluded',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_MEMBERS_SWITCHER: 'Members',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_REQUESTED_EMPTY_BODY:
      "Here you'll see who wants to join this group.",
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_REQUESTED_EMPTY_TITLE:
      'No new membership requests',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_REQUESTED_SINCE: 'Requested on {date}',
    EP_GROUP_MEMBER_MODERATOR_SUBPAGE_REQUESTED_SWITCHER: 'Pending',
    EP_GROUP_MEMBER_POP_UP_BODY_LO:
      'Register for free or log in to view all {count} members.',
    EP_GROUP_MEMBER_REGISTER_LO: 'Register for free',
    EP_GROUP_MEMBER_SEARCH_EMPTY_BODY:
      'Perhaps your entry contains a typo. Give it another go or try a different term.',
    EP_GROUP_MEMBER_SEARCH_EMPTY_HEADLINE:
      "Sorry, we couldn't find anyone with that name.",
    EP_GROUP_MEMBER_SIGN_IN_LO: 'Log in',
    EP_GROUP_MEMBER_SUBPAGE_MORE_CTA: 'Show more',
    EP_GROUP_MEMBER_TITLE: 'Members',
    EP_GROUP_MEMBER_VIEW_PROFILE_LO: 'View profile',
    EP_GROUP_MEMBER_WRITE_MESSAGE: 'Send message',
    EP_GROUP_MEMBERS_ADD_AS_MODERATOR: 'Appoint as moderator',
    EP_GROUP_MEMBERS_ADD_AS_MODERATOR_SUCCESS:
      'You added {name} as a moderator.',
    EP_GROUP_MEMBERS_BLOCK_MEMBER: 'Block this member',
    EP_GROUP_MEMBERS_BLOCK_MEMBER_POP_UP:
      "{first_name} won't be able to post or comment until you unblock them.",
    EP_GROUP_MEMBERS_BLOCK_MEMBER_POP_UP_HEADLINE: 'Block {name}?',
    EP_GROUP_MEMBERS_BLOCK_MEMBER_POP_UP_YES: 'Block now',
    EP_GROUP_MEMBERS_BLOCK_MEMBER_SUCCESS: '{name} has now been blocked.',
    EP_GROUP_MEMBERS_BLOCK_MEMBERSHIP_POP_UP_CTA_CANCEL: 'Cancel',
    EP_GROUP_MEMBERS_CHANGE_ROLE_ERROR:
      'Sorry, something went wrong. Please try again.',
    EP_GROUP_MEMBERS_CHANGE_STATUS_POP_UP_CANCEL: 'Cancel',
    EP_GROUP_MEMBERS_CONFIRM_MEMBER_SUCCESS:
      '{first_name} is now a member, too.',
    EP_GROUP_MEMBERS_DECLINE_MEMBER_SUCCESS:
      "You've declined {name}'s membership request.",
    EP_GROUP_MEMBERS_GROUP_OWNER_INFO: 'Group owner since: {date}',
    EP_GROUP_MEMBERS_GROUP_PENDING_OWNER_INFO:
      'You can start inviting members once your group is activated. ',
    EP_GROUP_MEMBERS_LOAD_MORE_ERROR:
      'We had a little trouble loading more members. Please try again.',
    EP_GROUP_MEMBERS_MEMBERSHIP_ACTION_ERROR:
      'Sorry, something went wrong. Please try again.',
    EP_GROUP_MEMBERS_MEMBERSHIP_INFO: 'Member since: {date}',
    'EP_GROUP_MEMBERS_OPEN_REQUESTS_BODY.one':
      'Do you want your group to grow? Accept the membership request and welcome your new member or decline it.',
    'EP_GROUP_MEMBERS_OPEN_REQUESTS_BODY.other':
      'Do you want your group to grow? Accept the requests to welcome your new members or decline them.',
    'EP_GROUP_MEMBERS_OPEN_REQUESTS_BODY.zero':
      'Do you want your group to grow? Accept the request to welcome your new member or decline it.',
    'EP_GROUP_MEMBERS_OPEN_REQUESTS_CTA.one': 'View pending request',
    'EP_GROUP_MEMBERS_OPEN_REQUESTS_CTA.other': 'View pending requests',
    'EP_GROUP_MEMBERS_OPEN_REQUESTS_CTA.zero': 'View pending requests',
    'EP_GROUP_MEMBERS_OPEN_REQUESTS.one': '{number} pending membership request',
    'EP_GROUP_MEMBERS_OPEN_REQUESTS.other':
      '{number} pending membership requests',
    'EP_GROUP_MEMBERS_OPEN_REQUESTS.zero':
      '{number} pending membership requests',
    EP_GROUP_MEMBERS_REINSTATE_MEMBERSHIP: 'Remove exclusion',
    EP_GROUP_MEMBERS_REINSTATE_MEMBERSHIP_BODY: 'Remove exclusion?',
    EP_GROUP_MEMBERS_REINSTATE_MEMBERSHIP_CTA_CANCEL: 'Cancel',
    EP_GROUP_MEMBERS_REINSTATE_MEMBERSHIP_CTA_YES: 'Remove exclusion',
    EP_GROUP_MEMBERS_REINSTATE_MEMBERSHIP_SUCCESS:
      '{name} can request to join the group again.',
    EP_GROUP_MEMBERS_REINSTATE_MEMBERSHIP_TEXT:
      "{name} will be able to request to join the group again as they won't get their membership back automatically.",
    EP_GROUP_MEMBERS_REMOVE_AS_MODERATOR: 'Remove moderator status',
    EP_GROUP_MEMBERS_REMOVE_AS_MODERATOR_POP_UP:
      "Do you want to remove {name}'s moderator status?",
    EP_GROUP_MEMBERS_REMOVE_AS_MODERATOR_POP_UP_CONTENT:
      '{first_name} will lose their moderator status and be a regular member again.',
    EP_GROUP_MEMBERS_REMOVE_AS_MODERATOR_POP_UP_YES: 'Yes, remove',
    EP_GROUP_MEMBERS_REMOVE_AS_MODERATOR_SUCCESS:
      '{name} is no longer a moderator.',
    EP_GROUP_MEMBERS_REMOVE_MEMBER_FROM_GROUP: 'Exclude from group',
    EP_GROUP_MEMBERS_REMOVE_MEMBER_FROM_GROUP_HEADLINE:
      'Exclude {name} from this group?',
    EP_GROUP_MEMBERS_REMOVE_MEMBER_FROM_GROUP_POP_UP:
      "{first_name} will be removed as a member and can't join again until you cancel their exclusion.",
    EP_GROUP_MEMBERS_REMOVE_MEMBER_FROM_GROUP_POP_UP_CANCEL: 'Cancel',
    EP_GROUP_MEMBERS_REMOVE_MEMBER_FROM_GROUP_POP_UP_YES: 'Exclude',
    EP_GROUP_MEMBERS_REMOVE_MEMBER_FROM_GROUP_SUCCESS:
      '{name} is now excluded from this group.',
    EP_GROUP_MEMBERS_SEARCH_BAR: 'e.g. Jane Doe',
    EP_GROUP_MEMBERS_SEARCH_BAR_MOBILE: 'e.g. Jane Smith',
    EP_GROUP_MEMBERS_SEARCH_BUTTON: 'Search members',
    'EP_GROUP_MEMBERS_TOTAL.one': 'One person',
    'EP_GROUP_MEMBERS_TOTAL.other': '{value} people',
    'EP_GROUP_MEMBERS_TOTAL.zero': 'Nobody',
    EP_GROUP_MEMBERS_UNBLOCK_MEMBER: 'Unblock',
    EP_GROUP_MEMBERS_UNBLOCK_MEMBER_POP_UP:
      '{first_name} will be able to post and comment in this group again.',
    EP_GROUP_MEMBERS_UNBLOCK_MEMBER_SUCCESS:
      '{name} is now unblocked and can post and comment again.',
    EP_GROUP_MEMBERS_UNBLOCK_MEMBERSHIP_POP_UP_CTA_CANCEL: 'Cancel',
    EP_GROUP_MEMBERS_UNBLOCK_MEMBERSHIP_POP_UP_CTA_YES: 'Unblock',
    EP_GROUP_MEMBERS_UNBLOCK_MEMBERSHIP_POP_UP_HEADLINE: 'Unblock {name}?',
    EP_GROUP_MEMBERS_UNDER_10_OWNER_BANNER_BODY:
      "There's bound to be some exciting people in your personal network who want to join your group and events.",
    EP_GROUP_MEMBERS_UNDER_10_OWNER_BANNER_CTA: 'Invite members',
    EP_GROUP_MEMBERS_UNDER_10_OWNER_BANNER_HEADLINE:
      'Who should join your group?',
    EP_GROUP_MEMBERS_UNDER_10_VISITOR_BANNER_BODY:
      'Do you know someone who might be interested or would like to meet other people to discuss this topic? Invite them to join this group.',
    EP_GROUP_MEMBERS_UNDER_10_VISITOR_BANNER_CTA: 'Invite contacts',
    EP_GROUP_MEMBERS_UNDER_10_VISITOR_BANNER_HEADLINE:
      'Who else should join this group?',
    EP_GROUP_OWNER_DRAFT_BANNER_BODY:
      "You're just a few details away from completing your group. Once it's published, you can start inviting people to join.",
    EP_GROUP_OWNER_DRAFT_BANNER_DELETE_CTA: 'Discard group',
    EP_GROUP_OWNER_DRAFT_BANNER_FINISH_CTA: 'Continue editing',
    EP_GROUP_OWNER_DRAFT_BANNER_TITLE:
      'Your group <b>"%group_name</b> is almost ready to go...',
    EP_GROUP_OWNER_YOUR_GROUPS_PENDING_INFO: 'Approval pending (since {date})',
    EP_GROUP_ROLE_INFO_MEMBER: 'Member',
    EP_GROUP_ROLE_INFO_MODERATOR: 'Moderator',
    EP_GROUP_RULES_TITLE: 'Group rules',
    'EP_GROUP_SOCIAL_PROOF.one': '{username} is already a group member.',
    'EP_GROUP_SOCIAL_PROOF.other':
      '{numberOfContacts} of your contacts are already group members.',
    EP_GROUP_START_PAGE_BODY:
      'XING groups are all about regular meet-ups and real connections.\nPeople can get together to talk about specific topics, to give presentations, or to network in a relaxed atmosphere.',
    EP_GROUP_START_PAGE_CREATE_GROUP_CTA: 'Start a group',
    EP_GROUP_START_PAGE_HEADER: 'Find your community!',
    EP_GROUP_START_PAGE_NEW_GROUPS_HEADER: 'Your groups with the new design',
    EP_GROUP_START_PAGE_NEW_GROUPS_INFO_BODY:
      'Migrating all of the groups from the old to the new design is likely to take around 4 weeks. But we wanted to give you access to the new design as each group is switched over.',
    EP_GROUP_START_PAGE_NEW_GROUPS_INFO_TITLE:
      'Why do some of my groups have the new design, while others still have the old one?',
    EP_GROUP_START_PAGE_NEW_GROUPS_MEMBER_BODY:
      "It's fresh and clear with a whole host of new event features for you. Every group will be switched over to the new design in the coming weeks.",
    EP_GROUP_START_PAGE_NEW_GROUPS_MODERATOR_BODY:
      "The new design offers two different views – one for regular members and one for moderators. This makes it easier for you to manage your groups. The new design is also smartphone and tablet friendly. <a href=“https://faq.xing.com/node/68236”>What's changed?</a>",
    EP_GROUP_START_PAGE_NEW_GROUPS_MODERATOR_BODY_ANDROID:
      'The new design offers two different views – one for moderators and one for members. <a href=“https://faq.xing.com/node/68236”>What else has changed?</a>',
    EP_GROUP_START_PAGE_NEW_GROUPS_MODERATOR_BODY_IOS:
      'The new design offers two different views – one for moderators and one for members. <a href=“https://faq.xing.com/node/68236”>What else has changed?</a>',
    EP_GROUP_START_PAGE_OLD_GROUPS_HEADER:
      'Your groups with the current design',
    EP_GROUP_START_PAGE_OLD_GROUPS_MEMBER_BODY:
      "These groups still use the existing design, but they'll switch over to the new one soon.",
    EP_GROUP_START_PAGE_SEARCH_GROUP_CTA: 'Search groups',
    EP_GROUP_START_PAGE_TAB_EMPTY_STATE_BODY:
      'Join your first group now and meet new people.',
    EP_GROUP_START_PAGE_TAB_EMPTY_STATE_CTA: 'Find a group',
    EP_GROUP_START_PAGE_TAB_EMPTY_STATE_TITLE: 'Here are your groups',
    EP_GROUP_XBM_ENTRY_BODY:
      'Do you want to see how your group has grown over time or how often it was found?',
    EP_GROUP_XBM_ENTRY_CTA: 'Go to group analysis',
    EP_HEADER_IMAGE_BANNER_BODY:
      'You can save your cover image <strong>as a draft</strong> right now and it will only be visible to you. <br />\nTo post your cover image, please <strong>upgrade</strong> to an Employer Branding Profile first.',
    EP_HEADER_IMAGE_BANNER_DRAFT_BUTTON: 'Save cover image as draft',
    EP_HEADER_IMAGE_BANNER_HEADER: 'Upgrade and post your cover image',
    EP_HEADER_IMAGE_BANNER_PUBLISH_BUTTON: 'Upgrade now',
    EP_HEADER_LAST_STEP_CREATE_PAGE:
      'Excellent! Your {companyName} profile is almost ready to go.',
    EP_HELP_CENTER: 'Help',
    EP_IDEAL_EMPLOYER_ADD: 'Add as ideal employer',
    EP_IDEAL_EMPLOYER_ADD_CONFIDENTIALLY:
      'Add this company as an ideal employer to indicate your interest confidentially.',
    EP_IDEAL_EMPLOYER_ADDED: 'Added as ideal employer',
    EP_IDEAL_EMPLOYER_ADMIN_ADAPT: 'To do that, head over to the BrandManager.',
    EP_IDEAL_EMPLOYER_ADMIN_GO_BM: 'Go to BrandManager',
    EP_IDEAL_EMPLOYER_ADMIN_LAPTOP:
      'To do that, head over to the BrandManager (ideally using a large screen). ',
    EP_IDEAL_EMPLOYER_ADMIN_POSITION: 'Edit ideal employer module',
    EP_IDEAL_EMPLOYER_CONFIDENTIAL_INFO:
      'Only the employer will know about this unless you choose otherwise in your job preferences.',
    EP_IDEAL_EMPLOYER_EDIT_HINT:
      'The BrandManager shows you which XING members set your company as an ideal employer.',
    EP_IDEAL_EMPLOYER_EDIT_HINT_CTA: 'Visit BrandManager',
    EP_IDEAL_EMPLOYER_EI_CONFIDENTIAL:
      'Only the employer will know about this unless you choose otherwise in your job preferences.',
    EP_IDEAL_EMPLOYER_EI_HOW: 'How does it work?',
    EP_IDEAL_EMPLOYER_EI_NON_COMMITTAL:
      "We'll let the company know you'd like to work there. ",
    EP_IDEAL_EMPLOYER_EI_POTENTIAL:
      'The company is then more likely to get in touch with you.',
    EP_IDEAL_EMPLOYER_EI_UPTO_30: 'You can add up to 30 ideal employers.',
    EP_IDEAL_EMPLOYER_HEADLINE: 'Ideal employer',
    EP_IDEAL_EMPLOYER_MAX: "You've already added 30 ideal employers.",
    EP_IDEAL_EMPLOYER_NAVIGATION_TITLE: 'Ideal employer',
    EP_IDEAL_EMPLOYER_REMOVE: 'Remove as ideal employer',
    EP_IDEAL_EMPLOYER_WORK: 'Would you like to work here?',
    EP_IMAGE_UPLOAD_SIZE_FORMAT_ERROR:
      'Please select an image in JPG, JPEG or PNG format and up to 5 MB in size.',
    EP_INDUSTRY_SUBCATEGORY: 'Segment',
    EP_INFO_LAST_STEP_CREATE_PAGE:
      '– You can continue adding content to your employer profile in the meantime. ',
    EP_INPUT_CHARS_LEFT: '{charactersNumber} characters left',
    EP_INSIDER_CHANGE_PROFILE_INFO_BUTTON: 'Visit my profile',
    EP_INSIDER_CHANGE_PROFILE_INFO_TEXT:
      'You may edit your profile or background image on your profile.',
    EP_INVALID_COMPANY_NAME:
      'There already is a XING Page with that name. Maybe a colleague of yours created it?',
    EP_JOB_ADS_FOUND: '{total} job ads found',
    EP_JOB_JOBBOX: 'View saved jobs',
    EP_JOB_LOOKING: 'Looking for a job?',
    EP_JOB_NO_VACANCIES: 'No vacancies currently available.',
    EP_JOBS_CAREER_LEVEL: 'Career level',
    EP_JOBS_DAYSAGO: '{number} days ago',
    EP_JOBS_DESCRIPTION: 'Jobs',
    EP_JOBS_DISCIPLINE: 'Discipline',
    EP_JOBS_EDIT_HINT: "Here are all the job ads you've posted on XING.",
    EP_JOBS_EDITSWITCH_CTA: 'Go to XING JobManager',
    EP_JOBS_EDITSWITCH_DISCRIPTION:
      'You can browse and manage your vacancies in the {jobManager}.\nWe recommend using a large screen to optimise your experience.',
    EP_JOBS_EDITSWITCH_DISCRIPTION_INNER: 'XING JobManager',
    EP_JOBS_EDITSWITCH_HEADLINE: 'Post and manage job ads',
    EP_JOBS_EMPTYSTATE_BODY:
      'Hire the right people by adding a new job opening.',
    EP_JOBS_EMPTYSTATE_CTA: 'Add new vacancy',
    EP_JOBS_EMPTYSTATE_HEADER: "You don't have any vacancies right now.",
    EP_JOBS_FEEDBACK_ERROR: 'Sorry, something went wrong.',
    EP_JOBS_FILTER_ALL: 'All',
    EP_JOBS_HEADLINE: 'Jobs',
    EP_JOBS_LINK: 'All {number} jobs',
    EP_JOBS_LOCATIONS: 'Locations',
    EP_JOBS_MATCH: '{number}% match',
    EP_JOBS_NAVIGATION_TITLE: 'Jobs',
    EP_JOBS_PUBLIC_EMPTYSTATE_BODY:
      'Looking for a job? Check out our recommendations.',
    EP_JOBS_PUBLIC_EMPTYSTATE_CTA: 'View recommendations',
    EP_JOBS_PUBLIC_EMPTYSTATE_HEADER:
      "There aren't any vacancies available right now.",
    EP_JOBS_RECOMMENDATION: 'View job recommendations',
    EP_JOBS_SEARCH_PLACEHOLDER: 'Enter a job title or keyword',
    EP_JOBS_SUBPAGE_DESCRIPTION: 'All job offers from {pageTitle} at a glance',
    EP_JOBS_TODAY: 'Today',
    EP_JOBS_YESTERDAY: 'Yesterday',
    EP_JOIN_GROUP_CTA: 'Join',
    EP_KUNUNU_ALL_BENEFITS: 'View all benefits on kununu',
    EP_KUNUNU_ALL_REVIEWS: 'All {number} reviews on kununu',
    EP_KUNUNU_BENEFITS_CONFIRMATION:
      'Confirmed by {total} out of {totalReviews} reviews',
    EP_KUNUNU_BENEFITS_TITLE: 'Employee benefits',
    EP_KUNUNU_CULTURE_BUTTON: 'View the corporate culture',
    EP_KUNUNU_DISCOVER_REVIEWS:
      'Get to know the company and see how employees rate the culture there',
    EP_KUNUNU_DISCOVER_REVIEWS_CULTURE:
      'Get to know the company and see how employees rate the culture.',
    EP_KUNUNU_EDIT_HINT: 'These ratings are from your kununu profile.',
    EP_KUNUNU_EDIT_HINT_CTA: 'View kununu profile',
    EP_KUNUNU_HEADLINE: 'Employee reviews',
    EP_KUNUNU_NAVIGATION_TITLE: 'Employer reviews & culture',
    EP_KUNUNU_RATE_COMPANY: 'Is this your current or previous employer?',
    EP_KUNUNU_RATE_COMPANY_BUTTON: 'Rate this company',
    EP_KUNUNU_RATING: 'kununu rating',
    EP_KUNUNU_RATING_VALUE: '{value} / {fullValue}',
    EP_KUNUNU_READ_REVIEW: 'Read review',
    EP_KUNUNU_RECOMMENDATION: 'recommend this employer',
    EP_KUNUNU_REVIEW_TITLE: 'What employees say',
    EP_KUNUNU_REVIEWER_CURRENT_JOB: 'Current job',
    EP_KUNUNU_REVIEWER_FORMER_JOB: 'Past job',
    EP_KUNUNU_REVIEWER_INTERNSHIP_JOB: 'Internship\t',
    EP_KUNUNU_REVIEWER_NS_JOB: 'Not specified',
    EP_KUNUNU_SHARE_BUTTON: 'Rate this employer',
    EP_KUNUNU_SHARE_DETAIL:
      'Share your experience anonymously and help us all make better work-life decisions.',
    EP_KUNUNU_SHARE_TITLE: 'Have you worked at {company}?',
    EP_KUNUNU_WHAT_SAY_BUTTON: 'View kununu profile',
    EP_LEARN_MORE_ABOUT_US: 'Learn more',
    EP_LEGAL_NOTICE: 'Legal notice',
    EP_LEGAL_NOTICE_EMPTY_STATE: 'Legal notice',
    EP_LOAD_MORE_CTA: 'Show more ',
    EP_LOCATION_ADDRESS_ERROR: 'Please check your address again.',
    EP_LOCATION_CITY_ERROR: 'Please check your city again.',
    EP_LOCATION_EMAIL_ERROR: 'Please check your e-mail address again.',
    EP_LOCATION_EMPTY_STATE_BUTTON: 'Add contact details',
    EP_LOCATION_EMPTY_STATE_TEXT:
      'Please add your address and contact details before publishing.',
    EP_LOCATION_EMPTY_STATE_TITLE: 'Address and contact details',
    EP_LOCATION_FAXNUMBER_ERROR: 'Please only enter numbers (min. 5).',
    EP_LOCATION_LABEL_ERROR: 'Please check this entry again.',
    EP_LOCATION_PHONENUMBER_ERROR: 'Please only enter numbers (min. 5).',
    EP_LOCATION_POSTCODE_ERROR: "Please don't enter any special characters.",
    EP_LOCATION_WEBSITE_ERROR: 'Please check your web address again.',
    EP_LOCATIONS_EDIT_TITLE: 'Edit location',
    EP_LOCATIONS_HEADLINE: 'Location',
    EP_LOCATIONS_NAVIGATION_TITLE: 'Location',
    EP_LOCATIONS_OPEN_MAP: 'Open map',
    EP_MAIL_LAST_STEP_CREATE_PAGE: "– We'll send your confirmation to {mail}",
    EP_MANAGE_CONTENT: 'Manage media',
    EP_MAX: 'Max.',
    EP_MEDIA_HEADLINE: 'Media',
    EP_MEMBERS_BLOCKED_MEMBER_LEAVE_BODY:
      "You'll no longer be a member and can't join again.",
    EP_MEMBERS_BLOCKED_MEMBER_LEAVE_HEADLINE: 'Leave this group?',
    'EP_MEMBERS_CARD_SHARED_CONTACTS_WEB.one': '{value} shared contact',
    'EP_MEMBERS_CARD_SHARED_CONTACTS_WEB.other': '{value} shared contacts',
    'EP_MEMBERS_CARD_SHARED_CONTACTS_WEB.zero': 'No shared contacts',
    EP_MEMBERS_DESCRIPTION: 'Members',
    EP_MEMBERS_FEEDBACK_ERROR: 'Sorry, something went wrong. Please try again.',
    EP_MEMBERS_HEADLINE: 'Members',
    EP_MEMBERS_LEAVE_GROUP_NO: 'Cancel',
    EP_MEMBERS_LEAVE_GROUP_YES: 'Leave group',
    EP_MEMBERS_MEMBER_LEAVE_BODY:
      "You'll no longer be a member but can join again.",
    EP_MEMBERS_MEMBER_LEAVE_HEADLINE: 'Leave this group?',
    EP_MEMBERS_MODERATOR_LEAVE_BODY:
      "You'll no longer be a member. If you join again, you won't be a moderator.",
    EP_MEMBERS_MODERATOR_LEAVE_HEADLINE: 'Leave this group?',
    EP_MEMBERS_NAVIGATION_TITLE: 'Members',
    EP_MEMBERS_OR: 'or',
    EP_META_ABOUT_US_DESCRIPTION:
      "► Find out what it's like to work at {pageTitle} and what makes the company stand out as an employer.",
    EP_META_ABOUT_US_TITLE: 'About {pageTitle} | XING',
    EP_META_COMPANY_DESCRIPTION:
      '► Alles über {pageTitle} als Arbeitgeber: Informationen, Neuigkeiten, offene Stellen, Ansprechpersonen, Standorte und mehr',
    EP_META_CONTACTS_DESCRIPTION:
      "Who to contact if you'd like to know more about {pageTitle} as an employer",
    EP_META_CONTACTS_TITLE: 'HR contacts for {pageTitle} | XING',
    EP_META_DESCRIPTION_COMPANY_PAGES:
      'Discover what {pageTitle} has to offer as an employer.',
    EP_META_DESCRIPTION_DEFAULT:
      'Discover general information, job vacancies and updates for {pageTitle} on XING.',
    EP_META_DESCRIPTION_PUBLISHER_PAGES:
      "Here you'll find every article and post by {pageTitle} on XING.",
    EP_META_DESCRIPTION_TOPIC_PAGES:
      "Here you'll find every news feed about {pageTitle} on XING.",
    EP_META_DISCUSSIONS_DESCRIPTION:
      '✅ Latest topics and discussions in the {pageTitle} group.',
    EP_META_DISCUSSIONS_TITLE: '{pageTitle} discussions | XING',
    EP_META_EMPLOYEES_DESCRIPTION:
      '► Mitarbeiter·innen von {pageTitle} bei XING. Wenn kennst Du schon – und seid Ihr schon vernetzt? Wenn nicht, lernt Euch kennen und tauscht Euch zu gemeinsamen Themen aus.',
    EP_META_EMPLOYEES_TITLE: 'Employees of {pageTitle} | XING',
    EP_META_EVENTS_DESCRIPTION:
      "✅ Here you'll find {pageTitle}'s events. Network and discuss what matters to you with like-minded people or learn something new.",
    EP_META_EVENTS_TITLE: 'All events by {pageTitle} | XING',
    EP_META_JOBS_DESCRIPTION:
      "✅ Looking for a job? Here you'll find {pageTitle}'s current vacancies. See what the company has to offer and apply for a vacancy.",
    EP_META_JOBS_TITLE: 'Available jobs at {pageTitle} | XING',
    EP_META_MEMBERS_DESCRIPTION:
      "✅ See who's already a member and connect with them.",
    EP_META_MEMBERS_TITLE: '{pageTitle} members | XING',
    EP_META_NEWS_DESCRIPTION:
      "► Visit {pageTitle}'s page on XING for news and information.",
    EP_META_NEWS_TITLE: 'News from {pageTitle} | XING',
    EP_META_PAGE_TITLE: '{pageTitle}: Information and Updates | XING',
    EP_META_WEBSITE_ERROR:
      "Sorry, but that doesn't appear to be a website. Maybe there's a typo?",
    EP_META_YEAR_LABEL_ERROR:
      "Sorry, but that doesn't appear to be a year. Maybe there's a typo?",
    EP_MIN: 'Min.',
    EP_MODULE_DISCUSSION_EDITOR_TAB_HIDDEN: 'Hidden',
    EP_MODULE_DISCUSSION_EDITOR_TAB_PUBLISHED: 'Posted',
    EP_MODULE_DISCUSSION_SHOW_MORE: 'Show more',
    EP_MODULE_DISCUSSION_TITLE: 'Discussions',
    EP_MODULE_DISCUSSIONS_ALL_LINK: 'Show all posts',
    EP_MODULE_ERROR_SUBLINE:
      'Please try reloading or returning to the {overview}.',
    EP_MODULE_ERROR_SUBLINE_INNER: '{entityPage} overview',
    EP_NA: 'N/A',
    EP_NAVIGATION_EDIT_HINT:
      'Add and remove tabs and change their order in the BrandManager.',
    EP_NAVIGATION_EDIT_HINT_CTA: 'Visit BrandManager',
    EP_NEW_PAGE_CREATED_DRAFT:
      'This employer profile is in draft mode, i.e. unpublished.',
    EP_NEWS_ACTION_TYPE_ARTICLE: 'shared this article:',
    EP_NEWS_ACTION_TYPE_IMAGE: 'shared this image.',
    EP_NEWS_ACTION_TYPE_LINK: 'shared this link.',
    EP_NEWS_ACTION_TYPE_TEXT: 'posted the following',
    EP_NEWS_ACTION_TYPE_VIDEO: 'shared this video.',
    EP_NEWS_DESCRIPTION: 'News',
    EP_NEWS_HEADLINE: 'News',
    EP_NEWS_NAVIGATION_TITLE: 'News',
    EP_NEWS_SHOW_ALL: 'Show all news',
    EP_NEWS_SUBPAGE_DESCRIPTION: 'All news from {pageTitle} at a glance',
    EP_NEWS_UPSELL_POPOVER_BODY:
      'Pinned articles always appear at the top of your feed, making it easy for you to find them again.',
    EP_NEWS_UPSELL_POPOVER_BUTTON: 'Discover all benefits',
    EP_NEWS_UPSELL_POPOVER_HEADER: 'Pin top articles',
    EP_NEXT_BUTTON_LAST_STEP_CREATE_PAGE: 'Continue editing',
    EP_NO_DOCUMENTS:
      'Share your latest presentations, product details and annual reports with your page visitors.',
    EP_NO_DOCUMENTS_HEADLINE: 'Upload your PDF files here.',
    EP_OF_CONTACTS: 'of your contacts',
    EP_ON_XING: 'on XING',
    EP_OPTIONAL_FIELD_HINT: 'Optional',
    EP_OPTIONS: 'What would you like to do?',
    EP_PAGE_CREATION_ABOUT_US_SUBHEADER:
      "The title and description will appear in 'About Us' in your employer profile. Both are needed to request approval, but can still be changed later in your profile.",
    EP_PAGE_CREATION_BACK_TO_COMPANIES: 'Back to overview',
    EP_PAGE_CREATION_CLOSE: 'Yes, delete',
    EP_PAGE_CREATION_CLOSE_PROCESS: 'Really delete the {name} profile?',
    EP_PAGE_CREATION_COMPANY_NAME_HELPER:
      "Please make sure you enter your company name correctly as you can't change it after saving.",
    EP_PAGE_CREATION_CONDITIONS:
      'I\'m authorised to claim this XING Page and I accept the <a href="https://www.xing.com/terms/employer-branding-profil" target="_blank">Terms and Conditions for XING Company Profiles.</a>',
    EP_PAGE_CREATION_CONTACTS_INFO:
      "Your company's contact details will be listed in your employer profile.",
    EP_PAGE_CREATION_DELETE_PROFILE: 'Delete draft profile',
    EP_PAGE_CREATION_DIALOG_CLOSE: 'Cancel',
    EP_PAGE_CREATION_DIALOG_TEXT:
      "Are you sure you want to cancel this process? You'll lose all your changes on this page.",
    EP_PAGE_CREATION_DO_NOT_CLOSE: 'Continue editing',
    EP_PAGE_CREATION_FORM_1_TITLE: 'Company details',
    EP_PAGE_CREATION_RESUME_OR_DELETE:
      "Please finish creating your employer profile and send it to us for approval. Simply delete this profile if you'd like to start over again.",
    EP_PAGE_CREATION_SAVE_NEXT: 'Save and continue',
    EP_PAGE_CREATION_SEND_FOR_APPROVAL: 'Request approval',
    EP_PAGE_INFO_HELP_BODY:
      'Check out our tips to help you put together an appealing page.',
    EP_PAGE_INFO_HELP_CTA: 'Visit help section',
    EP_PAGE_INFO_HELP_TITLE: 'Questions?',
    EP_PAGE_INFO_MANAGE_BODY:
      'You can add, hide or sort modules in the BrandManager.',
    EP_PAGE_INFO_MANAGE_CTA: 'Visit BrandManager',
    EP_PAGE_INFO_MANAGE_TITLE: 'Manage modules',
    EP_PAGE_INFO_PAGE_TYPE_FREE: 'Free profile',
    EP_PAGE_INFO_PAGE_TYPE_LABEL: 'Page type',
    EP_PAGE_INFO_PAGE_TYPE_PAID: 'Employer Branding Page',
    EP_PAGE_INFO_PAGE_TYPE_PAID_PLUS: 'Employer Branding Page Professional',
    EP_PAGE_INFO_ROLE_ADMINISTRATOR: 'Admin',
    EP_PAGE_INFO_ROLE_EDITOR: 'Editor',
    EP_PAGE_INFO_ROLE_LABEL: 'Your role:',
    EP_PAGE_INFO_ROLE_OWNER: 'Page owner',
    EP_PAGE_INFO_TITLE: 'About this page',
    EP_PAGE_OWNER: 'Page owner',
    EP_PARAMS_INCORRECT_ERROR:
      'Please add a title and a description (min. 4 characters each).',
    EP_PENDING_GROUP_JOIN_INFO: 'Pending',
    EP_PROMOTE_PAGE: 'Promote page',
    EP_PUBLISH_CTA: 'Save',
    EP_READ_LESS: 'Less',
    EP_READ_MORE: 'Read more',
    EP_READ_MORE_CTA: 'Read more',
    EP_REPORT_NEWS_ITEM: 'Report',
    EP_REPORT_PAGE: 'Report this page',
    EP_REQUIRED_FIELD: 'Required field',
    EP_RESTORE_CTA: 'Restore',
    EP_RESULT_LIST_CONTACT:
      'Not found who you want to add as a contact person for your page? Please contact  <a href="mailto:customer.support@xing.com">customer.support@xing.com</a> for assistance.',
    EP_RESULT_LIST_CONTACT_V2:
      'Not found who you want to add as a contact person for your page? Please contact  <a>customer.support@xing.com</a> for assistance.',
    EP_RESULT_LIST_HEADLINE: 'Choose contact person',
    EP_ROLLOUT_BANNER_ADD_LEGAL_NOTICE: 'Your legal notice',
    EP_ROLLOUT_BANNER_CONFIRM_BUTTON: 'Switch to the new design',
    EP_ROLLOUT_BANNER_CONTACT_DETAILS: "Your company's contact details",
    EP_ROLLOUT_BANNER_CONTACT_DETAILS_DESCRIPTION:
      "Please enter your company's address and phone number here.",
    EP_ROLLOUT_BANNER_DESCRIPTION:
      'Your new employer profile is almost ready to go.\nAll you need to do is to check the following information and update or complete it:',
    EP_ROLLOUT_BANNER_LEGAL_ADVICE_DESCRIPTION:
      "Please provide a link to the legal notice on your company's website or add this information here.",
    EP_ROLLOUT_BANNER_MOBILE_INFO:
      'Please open your employer profile on the desktop before switching to the new design.',
    EP_ROLLOUT_BANNER_OLD_URL:
      'Visitors to your old employer profile will be redirected.',
    EP_ROLLOUT_BANNER_PROFILE_UPDATED: 'Welcome to your new employer profile!',
    EP_ROLLOUT_BANNER_PUBLISH_INFO:
      'All done and checked? Great, then you’re ready to switch:',
    EP_ROLLOUT_BANNER_TEXT_EMAIL:
      'Please contact us if you need help or if you have any questions:\n<br/>\nE-mail: <a href="mailto:customer.support@xing.com">customer.support@xing.com</a>\n<br/>\nPhone: +49 40 419 131 778',
    EP_ROLLOUT_BANNER_TITLE: 'Nearly done!',
    EP_ROLLOUT_BANNER_UPDATED_URL:
      'Your XING Page link has changed to <strong>www.xing.com/pages/{slug}</strong>',
    EP_ROLLOUT_BANNER_WEBINAR_LINK:
      'https://attendee.gotowebinar.com/rt/2071891534032487180',
    EP_ROLLOUT_PUBLISH_ERROR_ABOUT_US:
      "Please add a detailed description to your 'About us' section.",
    EP_ROLLOUT_PUBLISH_ERROR_LOCATION:
      "Please add your company's contact details.",
    EP_ROLLOUT_WEBINAR_TEXT_1:
      'Are you interested in a tour of your new XING Page? Or do you have any questions? No problem! Simply sign up to one of our',
    EP_ROLLOUT_WEBINAR_TEXT_2: 'Register for one of our',
    EP_ROLLOUT_WEBINAR_TEXT_LINK: 'live webinars (German only)',
    EP_SEE_ALL_AWARDS: 'Show all awards',
    EP_SELECT_NONE: 'None',
    EP_SHARE_PAGE: 'Share this page',
    EP_SHOW_ALL_RESULTS: 'Show all results',
    EP_SHOW_MORE_RESULTS: 'Show more results',
    EP_SIMILAR_JOBS_HEADLINE: 'Similar jobs',
    EP_SLOGAN_EDIT_HEADLINE: 'Edit slogan',
    EP_SLOGAN_EDIT_HEADLINE_INFO:
      'Add a slogan to let visitors know what your page is about.',
    EP_SLOGAN_EDIT_HINT: "Add your page's slogan here.",
    EP_SLOGAN_EDIT_LABEL: 'Page slogan',
    EP_SUBPAGE_BACK: 'Back to overview',
    EP_TELEPHONE: 'Phone',
    EP_TITLE_EDIT_HINT: 'You can use your company name, for example.',
    EP_TITLE_EDIT_LABEL: 'Page title',
    EP_UNFOLLOW: 'Unfollow',
    EP_UPDATES_EDIT_EMPTY_STATE_DESCRIPTION:
      "Every update you post draws other XING members' attention to your page.",
    EP_UPDATES_EDIT_EMPTY_STATE_HEADLINE: 'Write your first article',
    EP_UPDATES_PUBLIC_EMPTY_STATE_DESCRIPTION:
      'Follow this page to receive future updates in your feed.',
    EP_UPDATES_PUBLIC_EMPTY_STATE_HEADLINE: 'Updates',
    EP_UPLOAD_COVER: 'Select a background image',
    EP_UPLOAD_COVER_LABEL: 'Add a stylish background image to your page.',
    EP_UPLOAD_DOCUMENT: 'Upload PDF',
    EP_UPLOAD_IMAGE_DRAG_LABEL: 'Drop your logo here.',
    EP_UPLOAD_IMAGE_HINT: 'JPG, BMP or PNG (max. 5 MB), ratio of 16:9',
    EP_UPLOAD_IMAGE_LABEL: 'Drag & drop or select a file.',
    EP_UPLOAD_LOGO: 'Add new logo',
    EP_UPLOAD_LOGO_DRAG_LABEL: 'You can drop your logo now.',
    EP_UPLOAD_LOGO_EDIT_INFO: 'How to choose the perfect logo',
    EP_UPLOAD_LOGO_EDIT_INFO_CTA: 'Learn more',
    EP_UPLOAD_LOGO_HINT: 'JPG, BMP or PNG (max. 5 MB) in square format',
    EP_UPLOAD_LOGO_LABEL: 'Drag & drop or browse your file.',
    EP_UPSELL_BANNER_CONTAINER_DISCARD_BUTTON: 'Discard',
    EP_UPSELL_BANNER_CONTAINER_PUBLISH_BUTTON: 'Continue',
    EP_UPSELL_BODY_TOP_HEADER:
      'Add your cover image and slogan to your employer profile for greater reach and better brand recognition among your target audience.\n \nShow everyone what your company stands for!',
    EP_UPSELL_COMPANY_RECOMMENDED_BODY:
      'Upgrade your profile to stop recommendations from other companies appearing on your own employer profile. This helps to keep the focus just on you. ',
    EP_UPSELL_COMPANY_RECOMMENDED_HEADER: 'Less is more!',
    EP_UPSELL_CONTACT_BODY:
      'Add multiple contact persons to your employer profile so visitors know who to approach.',
    EP_UPSELL_CONTACT_HEADER: 'Who to contact',
    EP_UPSELL_DOCUMENTS:
      'Make your employer profile the first port of call for visitors looking for information by providing official company presentations, tips, manuals and more. ',
    EP_UPSELL_DOCUMENTS_HEADER: 'Build a single place for relevant information',
    EP_UPSELL_DOCUMENTS_HEADLINE: 'Add documents',
    EP_UPSELL_EMPLOYER_FLAG_BUTTON: 'Employer Branding Profile',
    EP_UPSELL_EMPLOYER_FLAG_CONTENT_BODY:
      'This is one of the many benefits you get with an Employer Branding Profile.<br />\nSimply click on the pen icon, <strong>select an image</strong>, and <strong>move it into the right position</strong>. You can publish it with an upgrade.',
    EP_UPSELL_EMPLOYER_FLAG_CONTENT_BODY_WITH_IMAGE:
      'Only you can see your cover image right now. <br />\nUpgrade to an Employer Branding Profile so everyone gets to see it.',
    EP_UPSELL_EMPLOYER_FLAG_CONTENT_BUTTON: 'Upgrade',
    EP_UPSELL_EMPLOYER_FLAG_CONTENT_HEADER: 'Add your cover image here!',
    EP_UPSELL_EMPLOYER_FLAG_CONTENT_HEADER_WITH_IMAGE: 'Nice cover image!',
    EP_UPSELL_GALLERY_ABOUT_US:
      'Make your employer profile interesting and unique. Use your own personal design options with pictures and videos from your company, your team or your product.',
    EP_UPSELL_GALLERY_ABOUT_US_HEADER: 'Mix things up with multimedia',
    EP_UPSELL_GALLERY_BUTTON: 'Discover benefits',
    EP_UPSELL_HEADER_TOP_HEADER:
      'Attract new talent with a cover image and slogan',
    EP_UPSELL_PROFILE_MULTIMEDIA_BODY:
      'Make your employer profile interesting and unique. Use your own personal design options with pictures and videos from your company, your team or your product.',
    EP_UPSELL_PROFILE_MULTIMEDIA_HEADER: 'Mix things up with multimedia',
    EP_VISIT_WEBSITE: 'Website',
    EP_VISIT_WEBSITE_EMPTY_STATE: 'Website',
    EP_WHO_TO_CONTACT: 'Who to contact',
    EP_YOUR_TOPICS_EDIT_VIEW_CANCEL: 'Cancel',
    EP_YOUR_TOPICS_EDIT_VIEW_COPY:
      'Select or create up to four topics you write about as an Insider to make it easier for people to find you.',
    EP_YOUR_TOPICS_EDIT_VIEW_LABEL: 'Add topic',
    EP_YOUR_TOPICS_EDIT_VIEW_SAVE: 'Save',
    EP_YOUR_TOPICS_EDIT_VIEW_TITLE: 'Your topics',
    'EP-ARTICLES-MODULE-EMPTY-STATE-EDIT-TEXT':
      'Use the XING article editor or share a link to one of your external articles.',
    'EP-ARTICLES-MODULE-EMPTY-STATE-EDIT-TITLE': 'Write your first article',
    'EP-ARTICLES-MODULE-EMPTY-STATE-PINNED-TEXT':
      'Pin one of your articles to the top of your collection by clicking the star.',
    'EP-ARTICLES-MODULE-EMPTY-STATE-PINNED-TITLE':
      'Highlight your best article',
    'EP-ARTICLES-MODULE-EMPTY-STATE-TEXT':
      'Follow this page to receive notifications about new articles.',
    'EP-ARTICLES-MODULE-EMPTY-STATE-TITLE': 'No articles published yet',
    'EP-ARTICLES-MODULE-FILTER-ALL': 'All articles',
    'EP-ARTICLES-MODULE-FILTER-DRAFTS': 'Draft',
    'EP-ARTICLES-MODULE-FILTER-HIDDEN': 'Hidden',
    'EP-ARTICLES-MODULE-FILTER-PUBLISHED': 'Published',
    'EP-ARTICLES-MODULE-FILTER-SCHEDULED': 'Scheduled',
    'EP-ARTICLES-MODULE-MOBILE-HINT-TEXT':
      "The editor isn't available on mobile devices.",
    'EP-ARTICLES-MODULE-MOBILE-HINT-TITLE':
      'Please switch to a desktop computer',
    'EP-ARTICLES-MODULE-SHARE-NEW-ARTICLE-BUTTON': 'Share external article',
    'EP-ARTICLES-MODULE-SHOW-MORE-ARTICLES-BUTTON': 'Show more articles',
    'EP-ARTICLES-MODULE-WRITE-NEW-ARTICLE-BUTTON': 'Write new article',
    ERROR_DUPLICATE_SERVICE: 'You can only enter one account for each service.',
    ERROR_ENDDATE_INVALID: 'Please enter a correct end date.',
    ERROR_INDUSTRY: 'Please select an industry.',
    ERROR_INVALID_COMPANY: 'Please select a company.',
    ERROR_INVALID_SCHOOL: 'Please select a university or college.',
    ERROR_INVALID_SERVICE: 'Please select a service.',
    ERROR_INVALID_STATUS: 'Please select your status.',
    ERROR_SOMETHING_WENT_WRONG:
      'Sorry, something went wrong. Please try again later.',
    ERROR_STATUS_CHANGE_COMPANY_MISS:
      'To change your status, please enter details of at least one job.',
    ERROR_STATUS_CHANGE_SCHOOL_MISS:
      'Please enter at least one university name and field of study to change your status.',
    ERROR_STATUS_CHANGE_SUBJECT_MISS:
      'To change your status, please enter a field of study along with your university.',
    ERROR_WIDGET_SAVE: 'Error while saving',
    ERROR_WIDGET_SAVE_MSG:
      'Your changes could not be saved, as the information has already changed. Please click on "{\'BUTTON_OK}" to confirm, and edit the data once again.',
    EXECUTIVES_CV_COMPETENCES_HEADER: 'Managerial responsibility',
    FAX: 'Fax',
    FEEDY_CARD_VERB_CONTENT_PAGE_ARTICLE_POST: 'posted this article',
    FEEDY_CARD_VERB_IMAGE_POST: 'shared this image',
    FEEDY_CARD_VERB_LINK_POST: 'shared this link',
    FEEDY_CARD_VERB_STATUS_POST: 'posted an update',
    FOOTER_LANGUAGES: 'Language',
    FRAME_HEADER_SEARCH: 'Search',
    FRAME_HEADER_UPSELL_PREMIUM: 'Go Premium',
    FRAME_HEADER_UPSELL_PROJOBS: 'Check out ProJobs',
    FRAME_NAVIGATION_DISCO: 'Discover',
    FRAME_NAVIGATION_FEEDBACK_AND_HELP_SUBTITLE:
      'What do you think about the new XING app?',
    FRAME_NAVIGATION_FEEDBACK_AND_HELP_TITLE: 'Your feedback',
    FRAME_NAVIGATION_JOBS: 'Jobs',
    FRAME_NAVIGATION_ME_HUB: 'You',
    FRAME_NAVIGATION_NETWORKING_ICON_TEXT: 'Networking',
    FRAME_NAVIGATION_PROJOBS_UPSELL: 'Go ProJobs',
    FRAME_NAVIGATION_SUPI: 'Home',
    FRAME_NAVIGATION_UPSELL_HEADLINE: 'Upgrades for you',
    FRAME_NAVIGATION_YOUR_PREMIUM: 'Your Premium',
    FRAME_NAVIGATION_YOUR_PROJOBS: 'Your ProJobs',
    FROM: 'From',
    GIVE_WORK_DETAILS_HINT:
      'Include detailed information about your current company and area of work.',
    GOOGLE_TRANSLATION_BUTTON: 'Translate',
    GOOGLE_TRANSLATION_ERROR: 'Text could not be translated.',
    GOOGLE_TRANSLATION_ERROR_NO_TEXT: 'No text to be translated',
    GOOGLE_TRANSLATION_LABEL: 'powered by Google',
    GOOGLE_TRANSLATION_SELECT_FROM: 'Select language combination:',
    GOOGLE_TRANSLATION_SELECT_TO: 'in',
    GROUP_NEWSLETTER: 'Group newsletters',
    GROUP_VISIBILITY: 'Visibility of groups',
    HEADER_SKIP_NAVIGATION: 'Skip navigation',
    IM: 'Instant messaging',
    IM_PROFILES_EMPTY:
      'Enter your instant messaging usernames (e.g. Skype or MSN messenger) here. At a later stage you can decide which contacts can see this.',
    IM_PROFILES_SERVICE: 'Web service',
    IM_PROFILES_USERNAME: 'Username',
    INDIVIDUAL_GROUP_VISIBILITY: 'Visibility of new groups',
    INDUSTRY: 'Industry',
    INDUSTRY_10000: 'Architecture and planning',
    INDUSTRY_100000: 'Telecommunications',
    INDUSTRY_10100: 'Civil engineering',
    INDUSTRY_10200: 'Construction',
    INDUSTRY_10300: 'Building materials',
    INDUSTRY_10400: 'Architecture',
    INDUSTRY_10500: 'Gardening and landscaping',
    INDUSTRY_110000: 'Media and publishing',
    INDUSTRY_110100: 'Information services',
    INDUSTRY_110200: 'Broadcast media',
    INDUSTRY_110300: 'Publishing',
    INDUSTRY_110400: 'Film and music',
    INDUSTRY_110500: 'Journalism',
    INDUSTRY_110600: 'Translation and interpreting',
    INDUSTRY_110700: 'Copywriting and proofreading',
    INDUSTRY_120000: 'Banking and financial services',
    INDUSTRY_120100: 'Financial services',
    INDUSTRY_120200: 'Banking',
    INDUSTRY_120300: 'Investment banking',
    INDUSTRY_120400: 'Venture capital and private equity',
    INDUSTRY_130000: 'Insurance',
    INDUSTRY_140000: 'Real estate',
    INDUSTRY_140100: 'Facility management',
    INDUSTRY_140200: 'Real estate management',
    INDUSTRY_140300: 'Real estate',
    INDUSTRY_150000: 'Auditing, tax and law',
    INDUSTRY_150100: 'Legal services',
    INDUSTRY_150200: 'Auditing',
    INDUSTRY_150300: 'Tax consulting',
    INDUSTRY_160000: 'Consulting',
    INDUSTRY_170000: 'Marketing, PR and design',
    INDUSTRY_170100: 'Marketing and advertising',
    INDUSTRY_170200: 'Market research and opinion polling',
    INDUSTRY_170300: 'Graphic design',
    INDUSTRY_170400: 'Trade fairs, exhibitions and conventions',
    INDUSTRY_170500: 'PR',
    INDUSTRY_180000: 'HR services',
    INDUSTRY_180100: 'HR services and consulting',
    INDUSTRY_180200: 'Outsourcing and offshoring',
    INDUSTRY_190000: 'Civil service, associations and institutions',
    INDUSTRY_190100: 'Non-profit organisations and charities',
    INDUSTRY_190200: 'Religious institutions',
    INDUSTRY_190300: 'Public administration',
    INDUSTRY_190400: 'Politics, unions and associations',
    INDUSTRY_190500: 'Defence, judiciary and police',
    INDUSTRY_190600: 'International affairs',
    INDUSTRY_20000: 'Consumer goods and trade',
    INDUSTRY_200000: 'Education and science',
    INDUSTRY_200100: 'Research',
    INDUSTRY_200200: 'Schools and childcare',
    INDUSTRY_200300: 'Academia',
    INDUSTRY_200400: 'Childcare',
    INDUSTRY_200500: 'Coaching and training',
    INDUSTRY_200600: 'E-learning',
    INDUSTRY_20100: 'Food',
    INDUSTRY_20200: 'Beverage',
    INDUSTRY_20300: 'Tobacco products',
    INDUSTRY_20400: 'Fashion and textiles',
    INDUSTRY_20500: 'Luxury goods and jewellery',
    INDUSTRY_20600: 'Furniture and wood products',
    INDUSTRY_20700: 'Stationery',
    INDUSTRY_20800: 'Cosmetics and toiletries',
    INDUSTRY_20900: 'Glass and ceramics',
    INDUSTRY_21000: 'Import and export',
    INDUSTRY_210000: 'Health and social',
    INDUSTRY_210100: 'Hospitals',
    INDUSTRY_210200: 'Veterinary',
    INDUSTRY_210300: 'Medical practices',
    INDUSTRY_210400: 'Alternative medicine',
    INDUSTRY_210500: 'Psychology and psychotherapy',
    INDUSTRY_210600: 'Medical services',
    INDUSTRY_210700: 'Nursing sector',
    INDUSTRY_210800: 'Welfare',
    INDUSTRY_21100: 'Wholesale',
    INDUSTRY_21200: 'Retail',
    INDUSTRY_220000: 'Art, culture and sport',
    INDUSTRY_220100: 'Performing arts',
    INDUSTRY_220200: 'Photography',
    INDUSTRY_220300: 'Music',
    INDUSTRY_220400: 'Arts and crafts',
    INDUSTRY_220500: 'Museums and cultural establishments',
    INDUSTRY_220600: 'Libraries',
    INDUSTRY_220700: 'Sports clubs, sports centres and gyms',
    INDUSTRY_220800: 'Athletes, organisers and associations',
    INDUSTRY_230000: 'Other industries',
    INDUSTRY_230100: 'Gambling and betting',
    INDUSTRY_230200: 'Agriculture',
    INDUSTRY_230300: 'Forestry and hunting',
    INDUSTRY_230400: 'Fishery',
    INDUSTRY_230500: 'Other services',
    INDUSTRY_230600: 'Mining and metals',
    INDUSTRY_230700: 'Geology',
    INDUSTRY_230800: 'Security and investigation',
    INDUSTRY_30000: 'Automotive and vehicle manufacturing',
    INDUSTRY_30100: 'Automotive, motorcycles and bicycles',
    INDUSTRY_30200: 'Shipbuilding',
    INDUSTRY_30300: 'Railway locomotive and rolling stock manufacturing',
    INDUSTRY_30400: 'Traffic engineering',
    INDUSTRY_30500: 'Aerospace',
    INDUSTRY_30600: 'Vehicle rental',
    INDUSTRY_40000: 'Industry and mechanical engineering',
    INDUSTRY_40100: 'Instrumentation and control engineering',
    INDUSTRY_40200: 'Semiconductors and electronic components',
    INDUSTRY_40300: 'Printing',
    INDUSTRY_40400: 'Composites',
    INDUSTRY_40500: 'Chemicals',
    INDUSTRY_40600: 'Mineral oil processing',
    INDUSTRY_40700: 'Metal and metalworking',
    INDUSTRY_40800: 'Biotechnology and nanotechnology',
    INDUSTRY_40900: 'Plastic and rubber goods',
    INDUSTRY_41000: 'Armaments',
    INDUSTRY_41100: 'Mechanical and industrial engineering',
    INDUSTRY_41200: 'Electrical engineering',
    INDUSTRY_41300: 'Optical instruments and photographic equipment',
    INDUSTRY_50000: 'Pharmaceutical and medical technology',
    INDUSTRY_50100: 'Medical technology',
    INDUSTRY_50200: 'Pharmaceutical and medicinal products',
    INDUSTRY_60000: 'Energy, water and environment',
    INDUSTRY_60100: 'Renewable energy',
    INDUSTRY_60200: 'Energy',
    INDUSTRY_60300: 'Water supply and sanitation',
    INDUSTRY_60400: 'Waste and recycling',
    INDUSTRY_60500: 'Environmental protection',
    INDUSTRY_70000: 'Transport and logistics',
    INDUSTRY_70100: 'Public transport',
    INDUSTRY_70200: 'Rail',
    INDUSTRY_70300: 'Shipping',
    INDUSTRY_70400: 'Aviation',
    INDUSTRY_70500: 'Storage',
    INDUSTRY_70600: 'Post and forwarding',
    INDUSTRY_80000: 'Tourism and food service',
    INDUSTRY_80100: 'Hotel',
    INDUSTRY_80200: 'Restaurant and food service',
    INDUSTRY_80300: 'Travel agencies',
    INDUSTRY_80400: 'Leisure facilities',
    INDUSTRY_90000: 'Internet and IT',
    INDUSTRY_90100: 'Software',
    INDUSTRY_90200: 'IT service provider',
    INDUSTRY_90300: 'IT security',
    INDUSTRY_90400: 'Computer networking',
    INDUSTRY_90500: 'Computer hardware',
    INDUSTRY_90600: 'Consumer electronics',
    INDUSTRY_90700: 'Internet and online media',
    INDUSTRY_90800: 'Computer games',
    INSIDER_DESCRIPTION_INTRO: 'Writes about',
    INSIDER_FOLLOWED_BY: 'Followers',
    INSIDER_FOLLOWED_BY_AND: 'and',
    INSIDER_FOLLOWED_BY_AND_MORE: 'other people',
    INVISIBLE_ON_PROFILE: 'visible to recruiters',
    INVISIBLE_TO_OTHERS: 'not visible to others',
    JOB_SEARCH_ALERTS_ALERT_NAME_ALL: 'All jobs',
    JOBS_JOB_TEASER_SAVE: 'Save job',
    JOBS_JOB_TEASER_SAVED: 'Saved',
    JS_REALLY: 'Do you really want to do this?',
    JS_REALLY_DELETE: 'Really delete?',
    JS_REALLY_DONT_REMIND: "Really don't remind me again?",
    LANG_NOT_AVAIL: 'I speak a language not listed here.',
    LANG_PREFS: 'My language preferences',
    LANGUAGE: 'Language',
    LANGUAGE_SKILL_LEVEL_0: '- Language skills -',
    LANGUAGE_SKILL_LEVEL_1: 'Basic knowledge',
    LANGUAGE_SKILL_LEVEL_2: 'Good knowledge',
    LANGUAGE_SKILL_LEVEL_3: 'Fluent',
    LANGUAGE_SKILL_LEVEL_4: 'First language',
    LANGUAGE_SKILLS_NOT_AVAILABLE: "The language you speak isn't listed?",
    LANGUAGE_SKILLS_SELECT_LANGUAGE: '- Language -',
    LANGUAGES: 'Languages',
    LEVEL: 'Career level',
    LIKE_BUTTON_LABEL: 'Like',
    LIKERS_LIST_HEADLINE: 'Members who like this:',
    LINKCAP_LIST: 'List',
    LOGGEDOUT_ABOUT_ME: 'About {-display_name}',
    LOGGEDOUT_BUTTON_ADDCONTACT: 'Add {-display_name} as a contact',
    LOGGEDOUT_EDUCATION: "{-display_name}'s education",
    LOGGEDOUT_PERSONAL: "{-display_name}'s personal information",
    LOGGEDOUT_WORK_EXPERIENCE: "{-display_name}'s professional experience",
    MAIL_EMAIL_LOGINABLE_SUBJECT:
      '{EMAIL_SUBJ}: Important notice about your XING login details',
    MAIL_NOT_CONFIRMED:
      'The e-mail address could not be confirmed as you have changed your e-mail details in the meantime. Please try again.',
    MAIL_WAS_CONFIRMED: 'This e-mail address has been confirmed.',
    MALT_FILE_UPLOAD_ERROR_MESSAGE:
      'Unfortunately your {filesTooBig} file exceeds the {maxSize} MB limit.',
    MALT_FILE_UPLOAD_ERROR_MESSAGE_PLURAL:
      'Unfortunately these files exceed the {maxSize} MB limit: {filesTooBig}',
    MALT_FILE_UPLOAD_NO_FILE: 'No file selected yet',
    MALT_FILE_UPLOAD_NUMBER_OF_FILES: '{numberOfFiles} Files',
    MALT_FOOTER_COPYRIGHT_API: '© {siteoperator}  | All rights reserved',
    MALT_IMAGE_UPLOAD_BROWSE_BUTTON: 'Browse files',
    MALT_TEST_RUBY_STYLE_TRANSLATION: 'My name is {name}.',
    MEMBERSHIP_MOTIVATION: 'I’m a XING member because I want to…',
    MEMENU_FOLLOWING_LABEL: 'Following',
    MEMENU_HELP_LABEL: 'Help',
    MEMENU_JOB_ADS_LABEL: 'Manage job ads',
    MEMENU_JOB_PREFERENCES_LABEL: 'Job preferences',
    MEMENU_MY_POSTS_LABEL: 'Your posts',
    MEMENU_PREMIUM_FOR_BASIC_USER_LABEL: 'Go Premium',
    MEMENU_PREMIUM_LABEL: 'Premium',
    MEMENU_PROBUSINESS_LABEL: 'ProBusiness',
    MEMENU_PROFILE_LABEL: 'Profile',
    MEMENU_PROJOBS_FOR_BASIC_USER_LABEL: 'Go ProJobs',
    MEMENU_PROJOBS_LABEL: 'ProJobs',
    MEMENU_SETTINGS_LABEL: 'Settings',
    MESSAGESHTML_SEARCH_INBOX: 'Search by name',
    MESSENGER_SHARE_COMMENT_PLACEHOLDER: 'Comment on your link here.',
    MESSENGER_SHARE_RECEIPENT_SELECTION_FORM_LABEL: 'Recipients:',
    MESSENGER_SHARE_RECEIPENT_SELECTION_PLACEHOLDER: 'Enter recipients',
    MESSENGER_SHARE_TARGET_LABEL: 'Your message:',
    MONTH_NAMES:
      'January,February,March,April,May,June,July,August,September,October,November,December',
    MONTH_NAMES_SHORT: 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec',
    MORE_PROFILES: 'My other profiles on the web',
    MOTIVATIONS_EMPTY:
      'Let others know why you are on XING, and boost your networking potential.',
    MP_000_RESOURCE_USER_ROLES_EMPLOYEE: 'Employee',
    MP_000_RESOURCE_USER_ROLES_EXECUTIVE: 'Managing Director',
    MP_000_RESOURCE_USER_ROLES_EXTERNAL_RECRUITER: 'Recruiter',
    MP_000_RESOURCE_USER_ROLES_HR_CONSULTANT: 'HR consultant',
    MP_000_RESOURCE_USER_ROLES_HR_DEPARTMENT: 'HR department',
    MP_000_RESOURCE_USER_ROLES_MANAGER: 'Management',
    MP_000_RESOURCE_USER_ROLES_RECRUITER_CONSULTANT: 'HR consultant',
    MP_700_INFOBOX_EDIT_PROFILE_LINK: 'Edit your profile',
    MY_NOTIFICATION_PREFS: 'Notification preferences',
    MY_NOTIFICATIONS: 'Notifications',
    MY_PERSONAL_INFO: 'My personal data',
    MY_PRIVACY_SETTINGS: 'Your privacy settings',
    MY_PROFILE_SETTINGS: 'My profile settings',
    MY_SETTINGS: 'Settings',
    MY_USERNAME_PASSWORD: 'My login details',
    MYXING_LOADING_ALERT_MESSAGE:
      'Sorry, something  went wrong. Please reload the page.',
    NAVIGATION_EXPAND_NETWORK_ADDITION: 'Try it now!',
    NBA_BUTTON_CANCEL: 'Cancel',
    NBA_BUTTON_CHANGE_IMAGE: 'Change photo',
    NBA_BUTTON_CONTINUE: 'Continue',
    NBA_CREATE_PHOTO_CARD_BENEFIT_TEXT:
      'A good profile photo emphasises your professional image and will lead to more profile visitors.',
    NBA_CREATE_PHOTO_CARD_GENERIC_ERROR:
      'Something went wrong. Please try again.',
    NBA_CREATE_PHOTO_CARD_IMAGE_UPLOAD_REJECTED_MIME_TYPE:
      'Please select an image in either JPG, PNG or BMP format.',
    NBA_CREATE_PHOTO_CARD_IMAGE_UPLOAD_REJECTED_SIZE:
      'Please select an image up to 20 MB in size.',
    NBA_CREATE_PHOTO_CARD_IMAGE_ZOOM_MAX: 'Max.',
    NBA_CREATE_PHOTO_CARD_IMAGE_ZOOM_MIN: 'Min.',
    NBA_CREATE_PHOTO_CARD_LIGHTBOX_HEADLINE: 'Profile photo',
    NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_HINT_FILE_SIZES:
      'Recommended resolution: 1,024 x 1,024 px',
    NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_HINT_FILE_TYPES:
      'JPG, BMP, PNG (max. 20 MB)',
    NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_TEXT_OPTION_CLICK: 'Upload photo',
    NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_TEXT_OPTION_DRAG_N_DROP:
      'Drag and drop your image here',
    NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_TEXT_OR: 'or',
    NBA_CREATE_PHOTO_CARD_SUCCESS_BENEFIT_TEXT:
      'You can change this photo in your business card whenever you like.',
    NBA_CREATE_PHOTO_CARD_SUCCESS_HEADLINE:
      "Excellent! You've changed your profile image.",
    NEW_JOB: 'Add another job',
    NEW_SCHOOL: 'add another university/college',
    NEWLETTER_AND_MAIL_TIPS: 'XING newsletter, e-mail tips and offers by post',
    NEWS_MODULE_DELETE_LABEL: 'Delete',
    NEWS_MODULE_DIALOG_DELETE_HEADLINE: 'Delete',
    NEWS_MODULE_DIALOG_DELETE_NO: 'No',
    NEWS_MODULE_DIALOG_DELETE_TEXT: 'Delete this article?',
    NEWS_MODULE_DIALOG_DELETE_YES: 'Yes',
    NEWS_MODULE_DIALOG_HIDE_HEADLINE: 'Hide',
    NEWS_MODULE_DIALOG_HIDE_NO: 'No',
    NEWS_MODULE_DIALOG_HIDE_TEXT:
      "XING members won't be able to find it any more.",
    NEWS_MODULE_DIALOG_HIDE_YES: 'Yes',
    NEWS_MODULE_DIALOG_PIN_HEADLINE: 'Pin as top article',
    NEWS_MODULE_DIALOG_PIN_NO: 'No',
    NEWS_MODULE_DIALOG_PIN_TEXT: 'Pin this article to the top?',
    NEWS_MODULE_DIALOG_PIN_YES: 'Yes',
    NEWS_MODULE_DIALOG_UNPIN_HEADLINE: 'Unpin',
    NEWS_MODULE_DIALOG_UNPIN_NO: 'No',
    NEWS_MODULE_DIALOG_UNPIN_TEXT: 'Unpin this article from the top?',
    NEWS_MODULE_DIALOG_UNPIN_YES: 'Yes',
    NEWS_MODULE_DRAFT_LABEL: 'Draft',
    NEWS_MODULE_DRAFT_TAB: 'Drafts',
    NEWS_MODULE_EDIT_ALL: 'Edit all news items',
    NEWS_MODULE_EDIT_LABEL: 'Edit',
    NEWS_MODULE_EMPTY_STATE_DESCRIPTION:
      'Follow this page to receive future updates in your feed.',
    NEWS_MODULE_EMPTY_STATE_TITLE: 'Updates',
    NEWS_MODULE_EMPTYSTATE_DESCRIPTION:
      'Every update you post attracts XING members to your page.',
    NEWS_MODULE_EMPTYSTATE_TITLE: 'Write your first article',
    NEWS_MODULE_HIDDEN_LABEL: 'Hidden',
    NEWS_MODULE_HIDDEN_TAB: 'Hidden',
    NEWS_MODULE_HIDE_BUTTON: 'Hide',
    NEWS_MODULE_PIN_BUTTON: 'Pin to top',
    NEWS_MODULE_PINNED_LABEL: 'Pinned',
    NEWS_MODULE_PROMOTE_BUTTON: 'Promote',
    NEWS_MODULE_PROMOTE_LABEL: 'Sponsored',
    NEWS_MODULE_PUBLISH_FIRST_ARTICLE_BODY:
      'Visitors of your entity page are only able to see this side section, if you have published at least one news article or post.',
    NEWS_MODULE_PUBLISH_FIRST_ARTICLE_TITLE: 'Publish your first article',
    NEWS_MODULE_PUBLISHED_STORY_LABEL: 'Published',
    NEWS_MODULE_PUBLISHED_TAB: 'Published',
    NEWS_MODULE_SCHEDULE_LABEL: 'Scheduled',
    NEWS_MODULE_SCHEDULED_TAB: 'Scheduled',
    NEWS_MODULE_SHOW_BUTTON: 'Show',
    NEWS_MODULE_SHOW_STATISTICS: 'View statistics',
    NEWS_MODULE_TOP_AREA_CTA: 'Select an article',
    NEWS_MODULE_TOP_AREA_HEADLINE: 'No top articles selected.',
    NEWS_MODULE_TOP_AREA_SUB:
      'Write and publish an article, then click on the star to feature it.',
    NEWS_MODULE_TOP_LABEL: 'Top article',
    NEWS_MODULE_UNPIN_BUTTON: 'Unpin',
    NEWS_MODULE_UNPIN_LABEL: 'Remove pin',
    NEWSFEED_PROFILE_PREVIEW_AWARDS: 'Awards',
    NEWSFEED_PROFILE_PREVIEW_AWARDS_MORE:
      '{?IF:awards_sg}\n{.awards_more_entries} more award\n{?ELSE:awards_sg}\n{.awards_more_entries} more awards\n{?END:awards_sg}',
    NEWSFEED_PROFILE_PREVIEW_BUSINESS: 'Business',
    NEWSFEED_PROFILE_PREVIEW_DOCUMENTS: 'Attachments',
    NEWSFEED_PROFILE_PREVIEW_DOCUMENTS_MORE:
      '{?IF:certificates_sg}\n{.certificates_more_entries} more attachment\n{?ELSE:certificates_sg} \n{.certificates_more_entries} more attachments\n{?END:certificates_sg}',
    NEWSFEED_PROFILE_PREVIEW_HAVES: 'Skills',
    NEWSFEED_PROFILE_PREVIEW_INTERESTS: 'Interests',
    NEWSFEED_PROFILE_PREVIEW_MOBILE: 'Mobile phone',
    NEWSFEED_PROFILE_PREVIEW_ORGANISATIONS: 'Organisations',
    NEWSFEED_PROFILE_PREVIEW_PRIVATE: 'Private',
    NEWSFEED_PROFILE_PREVIEW_QUALIFICATIONS: 'Qualifications',
    NEWSFEED_PROFILE_PREVIEW_QUALIFICATIONS_MORE:
      '{?IF:qualifications_sg}\n{qualifications_more_entries} more entry\n{?ELSE:qualifications_sg}\n{qualifications_more_entries} more entries\n{?END:qualifications_sg}',
    NEWSFEED_PROFILE_PREVIEW_SCHOOLS_MORE:
      '{?IF:schools_sg}\n{schools_more_entries} more entry\n{?ELSE:schools_sg}\n{schools_more_entries} more entries\n{?END:schools_sg}',
    NEWSFEED_PROFILE_PREVIEW_WANTS: 'Wants',
    NEWSFEED_PROFILE_PREVIEW_WORKEXP_MORE:
      '{?IF:sg}\n{more_entries} more entry\n{?ELSE:sg}\n{more_entries} more entries\n{?END:sg}',
    NEWSFEED_RECOMMEND: 'Share',
    NO_END_DATE_HINT:
      'You haven\'t yet entered an end date or selected the "Current position" option.',
    NO_PRIV_ADD_HINT: "You haven't yet entered any private address details.",
    NOTES: 'Specialised subjects',
    NOTIFICATION_CENTER_LINK: 'Notifications',
    NOTIFICATIONS: 'Notifications',
    OBCPAGEHTML_DETAILS_NOTAPPROVED: '(not visible)',
    OBCPAGEHTML_LANGUAGES: 'Languages',
    OBCPAGEHTML_PERIOD: 'Period',
    OBCPAGEHTML_SHOW_SHARED_CONTACTS: 'Show shared contacts',
    OBCPAGEHTML_TAB5: 'Applications',
    OPENSOCIAL_REFERENCES_NEW: 'NEW',
    OPENSOCIAL_REFERENCES_TEXT:
      '{-DISPLAY_NAME} is using the XING References app. <a href="{-LINK}">View references</a>',
    OPENSOCIAL_REFERENCES_TITLE: 'References',
    OPTIMIZEPROFILE_ADVANTAGES_1:
      'Get found straight away by <b>more than 75,000 recruiters</b>',
    OPTIMIZEPROFILE_ADVANTAGES_2:
      'Create the perfect profile to <b>land your new job</b>',
    OPTIMIZEPROFILE_ADVANTAGES_3:
      "<b>Make contact discretely</b>, e.g. if you're currently at another job",
    OPTIMIZEPROFILE_BTN: 'Optimize profile now',
    OPTIMIZEPROFILE_CHECK_1: 'Are you listed first in search results?',
    OPTIMIZEPROFILE_CHECK_1_MORE_1:
      "Your profile is like a calling card for you - but did you know that it also determines when you'll be found in searches, for example by recruiters. Complete profiles are shown first, so make sure yours is complete!",
    OPTIMIZEPROFILE_CHECK_1_MORE_2:
      "Don't make your professional success a matter of luck: with a completed profile, you'll appear at the top of search results.",
    OPTIMIZEPROFILE_CHECK_1_MORE_3:
      'Follow the hints on your profile page to complete your profile and draw more attention to you.',
    OPTIMIZEPROFILE_CHECK_2: 'Do you refer to your job search?',
    OPTIMIZEPROFILE_CHECK_2_MORE_1:
      'Help success find you: There are over 75,000 recruiters active on XING. Let them know you\'re looking for a new challenge, and write it under "Wants" in your profile.',
    OPTIMIZEPROFILE_CHECK_2_MORE_2:
      'Indicate that you’re looking for a job in the "I’m a XING member because I want to" box.',
    OPTIMIZEPROFILE_CHECK_2_MORE_3:
      'If you’re employed and looking for another job (discreetly), you should choose the "Make invisible to XING members" option. Only recruiters will be able to see that you’re on the job search.',
    OPTIMIZEPROFILE_CHECK_3: 'Is your work experience detailed enough?',
    OPTIMIZEPROFILE_CHECK_3_MORE_1:
      'Recruiters are especially interested in your career. You can add a variety of details in the "Professional experience" section.',
    OPTIMIZEPROFILE_CHECK_3_MORE_2:
      'You can show your network and potential employers your working experience by including details in your profile: how long you worked at a job, for example, in months and years. Use the "career level" field to document your experience.',
    OPTIMIZEPROFILE_CHECK_3_MORE_3:
      "Give them something to think about: Don't just make a list of the companies where you've worked, include specific projects, successes or skills in the description.",
    OPTIMIZEPROFILE_CHECK_4: 'Have you described your skills well enough?',
    OPTIMIZEPROFILE_CHECK_4_MORE_1:
      'Showcase your skills and knowledge in your profile.',
    OPTIMIZEPROFILE_CHECK_4_MORE_2:
      "Keep track of keywords recruiters use on their own profiles as an example of what they're searching for. If a recruiter lists “XHTML”, “HTML 4” and “CSS 3” under his “Wants”, you should be ready.",
    OPTIMIZEPROFILE_CHECK_5:
      "Have you told your network what you're looking for?",
    OPTIMIZEPROFILE_CHECK_5_MORE_1:
      "Don’t wait until a recruiter finds out about how qualified you are - tell your network what you're looking for, and make it easier for recruiters to find you.",
    OPTIMIZEPROFILE_CHECK_5_MORE_2:
      'Under "Wants", you can enter keywords such as "a challenge", “new position", or "job".',
    OPTIMIZEPROFILE_CHECK_5_MORE_3:
      'Be creative: You could enter your dream job here.',
    OPTIMIZEPROFILE_CHECK_6: 'What does your photo say about you?',
    OPTIMIZEPROFILE_CHECK_6_MORE_1:
      'For profile pictures, the same rules apply as for resume pictures: no snapshots, ideally a professional picture from a photographer.',
    OPTIMIZEPROFILE_CHECK_7: 'Do you stay up to date?',
    OPTIMIZEPROFILE_CHECK_7_MORE_1:
      'If you get in touch with a recruiter, aim for clear and rapid communication – human resources is a fast-paced business.',
    OPTIMIZEPROFILE_CHECK_7_MORE_2:
      "Stay on top of things: check your messages regularly - recruiters don't have time to wait long.",
    OPTIMIZEPROFILE_CHECK_7_MORE_3:
      "Keep your notification address on XING up to date: we'll let you know by e-mail when new XING messages arrive.",
    OPTIMIZEPROFILE_CHECK_8:
      'Do you make efficient use of the "Employer" and "Status" fields?',
    OPTIMIZEPROFILE_CHECK_8_MORE_1:
      'Surprise your future employer with a sample of how creative you can be: If you’re looking for work, use the "Employer" field as an extra descriptive line to get attention. "Searching for a challenge" might catch a recruiter\'s eye - or try something even more original. “Your company could be here”, or even "I could work for you!".',
    OPTIMIZEPROFILE_CHECK_8_MORE_2:
      'Change your status to “Seeking employment”. Recruiters often search for people with this setting.',
    OPTIMIZEPROFILE_CHECK_8_MORE_3:
      'It is okay for you to add "(former)" to companies where you no longer work.',
    OPTIMIZEPROFILE_CHECKLIST_TITLE:
      'Profile checklist: Is your profile complete?',
    OPTIMIZEPROFILE_DESCRIPTION:
      'You can make your search far more effective with a complete and polished profile. Remove any gaps in your XING profile now and boost your chances of landing your dream job.',
    OPTIMIZEPROFILE_INFO:
      'Simply click on the individual checklist items on the right to find out more about how to get the most out of your profile.',
    OPTIMIZEPROFILE_SUBTITLE_1: 'Are you looking for a new job?',
    OPTIMIZEPROFILE_TIP: 'Important tip: Your privacy settings',
    OPTIMIZEPROFILE_TIP_MORE_1:
      "If you’re employed at a company, it's probably a good idea to keep your job search to yourself. Keep in mind that all changes you make to your profile appear in your direct contacts' “What’s new in your network” feed. Here's how to avoid potential mishaps:",
    OPTIMIZEPROFILE_TIP_MORE_2:
      "Play it safe: Before you change private data in your profile, be sure to change your privacy settings. Go to \"What’s new in your network” and uncheck the “Personal information” box. That way, your network won’t see you’ve made these changes on their homepage, and the changes won't appear in your contacts' newsletters.",
    OPTIMIZEPROFILE_TIP_MORE_3:
      'Remember to recheck this box after you’ve made your changes. That way, your network will always be informed of news about you.',
    OPTIMIZEPROFILE_TITLE: 'Enhance your profile to land the perfect job!',
    OPTIONS: 'Options',
    OPTIONS_DELETE: 'Yes, do it!',
    ORG_TYPE: 'Organisation type',
    PERSONAL: 'Personal information',
    PERSONAL_INFO: 'Personal data',
    PHONE: 'Phone',
    PHOTOBOX_LOCAL_TIME: 'Local time:',
    POLLS_HEADER_LABEL: 'Poll',
    POLLS_SHOW_RESULT_TITLE: 'Show results',
    POLLS_SHOW_VOTING_OPTIONS_TITLE: 'Show voting options',
    POLLS_TIME_ENDED: 'Poll closed',
    POLLS_TIME_LAST_MINUTES: 'Last chance to vote',
    'POLLS_TIME_REMAINING_DAYS_IOS.one': 'Ends tomorrow',
    'POLLS_TIME_REMAINING_DAYS_IOS.other': 'Ends in %d days',
    'POLLS_TIME_REMAINING_DAYS_IOS.zero': 'Ends today',
    'POLLS_TIME_REMAINING_DAYS_WEB.one': 'Ends tomorrow',
    'POLLS_TIME_REMAINING_DAYS_WEB.other': 'Ends in {days} days',
    'POLLS_TIME_REMAINING_DAYS_WEB.zero': 'Ends today',
    POLLS_VOTE_ERROR: "Sorry, that didn't work. Please try again.",
    'POLLS_VOTES_NUMBER_IOS.one': '1 vote',
    'POLLS_VOTES_NUMBER_IOS.other': '%d votes',
    'POLLS_VOTES_NUMBER_IOS.zero': '0 votes',
    'POLLS_VOTES_NUMBER_WEB.one': '1 vote',
    'POLLS_VOTES_NUMBER_WEB.other': '{votes} votes',
    'POLLS_VOTES_NUMBER_WEB.zero': '0 votes',
    PORTFOLIO_CHOICE_HINT_VIDEO_UPSELL:
      'Max. 15 videos<br>\n<a {href}>(Premium only)</a>',
    PORTFOLIO_DESCRIPTION_VIDEO_UPSELL:
      'Present your work with a video.<br><br>Just go Premium to take advantage of this feature: <a {href}>Go Premium</a>',
    PORTFOLIO_UPGRADE_MESSAGE_WITH_NO_VIDEO:
      'Add up to {max_file_number} images or PDF documents<br>\nEmbed up to {max_video_number} videos<br>\nUse as many text modules as you want',
    PREFSHTML_LANG_OPT_de: 'German',
    PREFSHTML_LANG_OPT_en: 'English',
    PREFSHTML_LANG_OPT_es: 'Spanish',
    PREFSHTML_LANG_OPT_fr: 'French',
    PREFSHTML_LANG_OPT_it: 'Italian',
    PRESENT: 'present',
    PREVIEW: 'Preview',
    PRIMARY_JOB:
      'Display this job next to my name and at the top of my profile page',
    PRIMARY_SCHOOL:
      'Display this university next to my name and at the top of my profile page.',
    PRIVATE: 'Private',
    PROBUSINESS_BANNER_EVENTS_GUESTLIST_BODY:
      'View the guest list in ProBusiness, apply powerful filters to refine it, and save relevant people as leads.',
    PROBUSINESS_BANNER_EVENTS_GUESTLIST_BUTTON:
      'View guest list in ProBusiness ',
    PROBUSINESS_BANNER_EVENTS_GUESTLIST_HEADLINE: 'Looking for new leads?',
    PROBUSINESS_BANNER_SEARCH_BODY:
      'View your search results in ProBusiness where you can apply powerful filters and add contacts as leads.',
    PROBUSINESS_BANNER_SEARCH_BUTTON: 'Show results in ProBusiness',
    PROBUSINESS_BANNER_SEARCH_HEADLINE:
      'Generate better leads from your search results!',
    PROBUSINESS_DROPDOWN_SHOW_RESULT: 'Show in ProBusiness',
    PROBUSINESS_NO_STATUS_AVAILABLE: 'Lead status not available',
    PROBUSINESS_SAVE_AS_LEAD: 'Save as lead',
    PROFILE_ABOUT_PREVIEW_CREATE: 'Create an "about me" page',
    PROFILE_ABOUT_PREVIEW_EDIT: 'Edit your "about me" page now',
    PROFILE_ABOUT_PREVIEW_EMPTY_TEXT:
      '<strong>Welcome to your "about me" page!</strong><br><br>\nPeople who visit your profile are bound to want to know more about you than they can see in your profile, so you should use this page to let them know what you\'re all about.<br><br>\n\nHere you can provide a more personal description about yourself by adding detailed information about your qualifications, experience, and career goals. If you\'re a freelancer, you can use this page to present the products and services you provide.',
    PROFILE_ABOUT_PREVIEW_MORE: 'More',
    PROFILE_ACTIONS_HEADLINE: 'Options',
    PROFILE_ACTIONS_MORE_TRIGGER: 'More',
    PROFILE_BACKEND_PMBX_INQUIRY_PAYLOAD_SETTINGS_ACTIVITY_TAB_VISIBILITY:
      'Activity visible?',
    PROFILE_BACKEND_PMBX_INQUIRY_PAYLOAD_SETTINGS_PORTFOLIO_TAB_VISIBILITY:
      'Portfolio visible?',
    PROFILE_BADGES_LAYER_AMBASSADOR_COMMON_HEADLINE: 'Ambassadors',
    PROFILE_BADGES_LAYER_AMBASSADOR_COMMON_TEXT:
      'Ambassadors are moderators of Official XING Groups who also organise Official XING Events. There are three kinds of ambassador:',
    PROFILE_BADGES_LAYER_AMBASSADOR_INDUSTRY_HEADLINE: 'Industry Ambassadors',
    PROFILE_BADGES_LAYER_AMBASSADOR_INDUSTRY_LINK: 'Browse industry groups',
    PROFILE_BADGES_LAYER_AMBASSADOR_INDUSTRY_SUB:
      'Moderator and proven expert in their industry',
    PROFILE_BADGES_LAYER_AMBASSADOR_INDUSTRY_TEXT:
      'An Industry Ambassador specialises in a specific field of expertise. He hosts an industry group as a recognised expert of his special field. He supports and encourages the exchange of ideas and knowledge in his group and on official "XING Live!" events.',
    PROFILE_BADGES_LAYER_AMBASSADOR_REGIONAL_HEADLINE: 'Regional Ambassadors',
    PROFILE_BADGES_LAYER_AMBASSADOR_REGIONAL_LINK: 'Browse regional groups',
    PROFILE_BADGES_LAYER_AMBASSADOR_REGIONAL_SUB:
      'Official event organiser and local contact',
    PROFILE_BADGES_LAYER_AMBASSADOR_REGIONAL_TEXT:
      'The Regional Ambassador is the point of contact for XING related questions in your region. He is moderator of an official regional group which is open to anyone interested. Regional Amassadors regularly organise events in their region to enable their group members to get to know each other.',
    PROFILE_BADGES_LAYER_AMBASSADOR_TOPIC_HEADLINE: 'Topic Ambassadors',
    PROFILE_BADGES_LAYER_AMBASSADOR_TOPIC_LINK: 'Browse topic groups',
    PROFILE_BADGES_LAYER_AMBASSADOR_TOPIC_SUB:
      'On expert on the modern world of work',
    PROFILE_BADGES_LAYER_AMBASSADOR_TOPIC_TEXT:
      'Topic Ambassadors are experts for modern working life. They host groups where people discuss the future of work and organise events where those discussions are continued in workshops, keynote presentations, and meetings.',
    PROFILE_BADGES_LAYER_MODERATOR_SUB:
      'Contact person and mediator for the group',
    PROFILE_BADGES_LAYER_PREMIUM_LINK: 'View all Premium benefits',
    PROFILE_BADGES_LAYER_PREMIUM_SUB:
      'Handy advanced features for your business and career',
    PROFILE_BUSINESS_ANCHOR_PRIVATE:
      'Please enter your <a href="#contact_details">private contact details</a> here.',
    PROFILE_BUSINESS_ANCHOR_WEB:
      'Please add any <a href="#web-profiles">Other profiles on the web</a> such as Twitter or Facebook here.',
    PROFILE_BUSINESS_CARD_BADGE_AMBASSADOR: 'Ambassador',
    PROFILE_BUSINESS_CARD_BADGE_AMBASSADOR_TEXT:
      'How do I become an Ambassador?',
    PROFILE_BUSINESS_CARD_BADGE_BASIC: 'Basic',
    PROFILE_BUSINESS_CARD_BADGE_BASIC_TOOLTIP: 'Discover Premium',
    PROFILE_BUSINESS_CARD_BADGE_EXECUTIVE: 'Executive',
    PROFILE_BUSINESS_CARD_BADGE_INDUSTRY_AMBASSADOR: 'Industry Ambassador',
    PROFILE_BUSINESS_CARD_BADGE_MODERATOR: 'Moderator',
    PROFILE_BUSINESS_CARD_BADGE_MODERATOR_TEXT: 'How do I become a moderator?',
    PROFILE_BUSINESS_CARD_BADGE_PREMIUM: 'Premium',
    PROFILE_BUSINESS_CARD_BADGE_PREMIUM_TEXT:
      'How do I become a Premium Member?',
    PROFILE_BUSINESS_CARD_BADGE_PREMIUM_TOOLTIP: 'Premium member',
    PROFILE_BUSINESS_CARD_BADGE_REGIONAL_AMBASSADOR: 'Ambassador',
    PROFILE_BUSINESS_CARD_BADGE_TOPIC_AMBASSADOR: 'Topic Ambassador',
    PROFILE_BUSINESS_CARD_BADGE_XPERT: 'Xpert',
    PROFILE_BUSINESS_CARD_BADGE_XPERT_TEXT: 'How do I become an Xpert?',
    PROFILE_BUSINESS_CARD_BUSINESS_ADDRESS: 'Business',
    PROFILE_BUSINESS_CARD_PRIVATE_ADDRESS: 'Private',
    PROFILE_BUSINESS_CARD_ROUTE: 'Map',
    PROFILE_BUSINESS_CARD_WEB_PROFILES: 'Other profiles on the web',
    PROFILE_COMPLETE_WITH:
      'Complete your profile with the following information:',
    PROFILE_COMPLETION_TEXT: 'Profile completion level',
    PROFILE_CONTACTS_MORE: 'more contacts',
    PROFILE_GROUPS_BOX_HEADER_OTHER: "{-ONAME}'s groups",
    PROFILE_GROUPS_BOX_NO_GROUPS_VISIBLE:
      'There are no groups displayed on your profile.',
    PROFILE_GROUPS_SHOW_ALL_LINK: 'Show all',
    PROFILE_GUESTBOOK_BOX_MORE: 'Show more',
    PROFILE_HEAD_MESSAGE: 'Send message',
    PROFILE_INLINETEASER_HEADER_MULTI_ENTRIES:
      '+{num_past_workentries} other entries.',
    PROFILE_INLINETEASER_HEADER_NO_ENTRIES: 'Want to know more about {-ONAME}?',
    PROFILE_INLINETEASER_HEADER_SINGLE_ENTRY: '+1 other entry.',
    PROFILE_INLINETEASER_TEXT:
      'Just sign up now for free. As a XING member you can then get in touch with {-ONAME}.',
    PROFILE_INLINETEASER_TEXT_MULTIPLE:
      "Just sign up now for free to see all of {-ONAME}'s professional experience.",
    PROFILE_LATEST_ACTIVITIES_HEADLINE: 'Latest activity',
    PROFILE_LATEST_ACTIVITIES_MORE: 'More',
    PROFILE_LIGHTBOX_SIGNUP_HEADLINE:
      '{-ONAME} is one of over 20 million XING members.',
    PROFILE_LIGHTBOX_SIGNUP_TEXT:
      'Sign up now for free to find former contacts and meet new people.',
    PROFILE_LOGGED_OUT_JOIN_NOW: 'Sign up for free',
    PROFILE_LOGGEDOUT_RIGHTBOX_1: 'Not a member yet?',
    PROFILE_LOGGEDOUT_RIGHTBOX_2:
      'XING is a business network for professionals that successfully combines know-how with know-who.',
    PROFILE_LOGGEDOUT_RIGHTBOX_3: 'Discover the benefits of XING:',
    PROFILE_LOGGEDOUT_RIGHTBOX_4: 'Find deals, new employees or jobs',
    PROFILE_LOGGEDOUT_RIGHTBOX_5: 'Find coworkers and friends',
    PROFILE_LOGGEDOUT_RIGHTBOX_6: 'Talk to experts in all fields',
    PROFILE_LOGGEDOUT_RIGHTBOX_7: 'Sign up for free',
    PROFILE_LOGGEDOUT_SEACRHBOX_1: 'Search the XING network',
    PROFILE_LOGGEDOUT_SEACRHBOX_2:
      'Find new business contacts on XING and get back in touch with old contacts!',
    PROFILE_LOGGEDOUT_TOPBOX_BUTTON: 'Contact now',
    PROFILE_LOGGEDOUT_TOPBOX_HEADER: 'Contact {-ONAME}!',
    PROFILE_LOGGEDOUT_TOPBOX_HEADER_2: 'Want to know more about {-ONAME}?',
    PROFILE_LOGGEDOUT_TOPBOX_TEXT1:
      'All you need to do is sign up for free on XING.',
    PROFILE_LOGGEDOUT_TOPBOX_TEXT2:
      'Already a member? <a href="/profile/{+obcpage_name}">Log in</a> and add {-ONAME} as a contact.',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_ACTIVE: 'Active',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_ACTIVE_FALSE: 'No',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_ACTIVE_TRUE: 'Yes',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_CAREER_SETTINGS:
      'Job preferences',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_CONTENT_INSIDER:
      'Articles',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_MODULE_NAME: 'Name',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_PATTERNS_SHOWCASE:
      'Patterns showcase',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_PERSONAL_DETAILS:
      'Personal details',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_TIMELINE: 'Timeline',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_MODULE_STORE_ITEM_TITLE:
      'Profile sections',
    PROFILE_MODULES_PMBX_INQUIRY_PAYLOAD_TITLE: 'Profile details visibility',
    PROFILE_MORE_DETAILS_NOTES_CATS:
      'My notes and categories (tags) for {-ONAME}',
    PROFILE_MORE_DETAILS_TOGGLE_LESS: 'less',
    PROFILE_MORE_DETAILS_TOGGLE_MORE: 'More',
    PROFILE_OTHER_EXPERIENCE_HEADLINE:
      'Other visitors also viewed the following profiles:',
    PROFILE_OTHER_EXPERIENCE_LINK: 'Other profiles',
    PROFILE_PMBX_INQUIRY_PAYLOAD_CONTENT: 'Content',
    PROFILE_PMBX_INQUIRY_PAYLOAD_CREATED_AT: 'Created on',
    PROFILE_PMBX_INQUIRY_PAYLOAD_FEED_URL: 'Feed URL',
    PROFILE_PMBX_INQUIRY_PAYLOAD_LEGAL_INFORMATION_CONTENT: 'Content',
    PROFILE_PMBX_INQUIRY_PAYLOAD_LEGAL_INFORMATION_DISPLAY_CONTENT:
      'Displayed content',
    PROFILE_PMBX_INQUIRY_PAYLOAD_LEGAL_INFORMATION_TITLE: 'Legal information',
    PROFILE_PMBX_INQUIRY_PAYLOAD_NO_DATA_REASON: 'No data available',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PORTFOLIO_IMAGETILE_HEADER:
      'Portfolio: images',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PORTFOLIO_PDFTILE_HEADER:
      'Portfolio: PDF files',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PORTFOLIO_TEXTTILE_HEADER:
      'Portfolio: text modules',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PORTFOLIO_TILE_CONTENT: 'Description',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PORTFOLIO_TILE_FILE: 'PDF',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PORTFOLIO_TILE_IMAGE: 'Image',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PORTFOLIO_TILE_LINK: 'Link',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PORTFOLIO_TILE_TITLE: 'Heading',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PORTFOLIO_VIDEOTILE_HEADER:
      'Portfolio: videos',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_ACCURACY: 'Accuracy',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_ADMIN_AREA: 'Region',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_CITY: 'City',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_FORMATTED_COUNTRY: 'Country',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_JSON_ATTRIBUTES:
      'Local details',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_LATITUDE: 'Latitude',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_LOCATION_TYPE: 'Type',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_LONGITUDE: 'Longitude',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_POSTAL_CODE: 'Postcode',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_STREET: 'Street address',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_TITLE: 'Determined location',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_LOCATION_UPDATE_FAILED:
      "Unfortunately the location data couldn't be updated.",
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_PHOTO_ORIGINAL_PICTURE:
      'Profile photo',
    PROFILE_PMBX_INQUIRY_PAYLOAD_PROFILE_PHOTO_ORIGINAL_TITLE: 'Profile photo',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_GIVEN_AT: 'Given at',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_GIVEN_TO: 'For',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_POSITION: 'For position',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_RECIEVED_FROM: 'By',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_REFERENCE_GIVEN:
      'References provided',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_REFERENCE_GIVEN_TEXT:
      'To {referee_id} on {given_at} | ({referee_position}). {testimonial}',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_REFERENCE_GIVEN_TITLE:
      'Given references',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_REFERENCE_POSITON: 'Position',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_REFERENCE_RECEIVED:
      'References received',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_REFERENCE_RECEIVED_TEXT:
      'By {referrer_id} on {given_at} | ({referrer_position}). {testimonial}',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_REFERENCE_RECIEVED_TITLE:
      'Received references',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_REFERENCE_TITLE: 'References',
    PROFILE_PMBX_INQUIRY_PAYLOAD_REFERENCES_TESTIMONIAL: 'Content',
    PROFILE_PMBX_INQUIRY_PAYLOAD_RSS_PROFILE_TITLE: 'RSS feeds',
    PROFILE_PMBX_INQUIRY_PAYLOAD_TITLE: 'Location data',
    PROFILE_PMBX_INQUIRY_PAYLOAD_UPDATED_AT: 'Updated on',
    PROFILE_POWERSEARCHES_HEADER: 'Searches related to my profile',
    PROFILE_PREVIEW_HEADER:
      'This is what your profile looks like to non-contacts.',
    PROFILE_PREVIEW_INFO_BUTTON_BACK: 'Back to normal profile view',
    PROFILE_PREVIEW_INFO_HEADLINE:
      'This is what your profile looks like to visitors',
    PROFILE_PREVIEW_INFO_TEXT:
      "This is the visitors' view of your profile. Please go back to the normal view to edit your profile.",
    PROFILE_PREVIEW_OFF: 'Back to normal profile view',
    PROFILE_PREVIEW_ON: 'View as profile visitor',
    PROFILE_PROMOTE_HEADLINE: 'Your personal profile button',
    PROFILE_PROMOTE_MORE: 'Show source code',
    PROFILE_PROMOTE_TEXT:
      'Copy the HTML source code and paste it into the code of your website or blog. Clicking the button will automatically take users to your profile.',
    PROFILE_SECTIONS_GROUPS_TITLE: 'Groups',
    PROFILE_SETTINGS: 'Settings',
    PROFILE_SEVERAL_GROUPS_COUNT: '({-count} of {-total})',
    PROFILE_SEVERAL_GROUPS_TITLE: 'Several groups of {-ONAME}',
    PROFILE_SEVERAL_OF_MY_GROUPS_TITLE: 'Several of my groups',
    PROFILE_TIMELINE_MODULE_ACTION_ADD_ENTRY: 'Other',
    PROFILE_TIMELINE_MODULE_ACTION_ADD_PROMOTION: 'Promotion',
    PROFILE_TIMELINE_MODULE_ACTION_CHANGE_ORGANIZATION: 'New job',
    PROFILE_TIMELINE_MODULE_ACTION_EDIT_ENTRY: 'Internal move',
    PROFILE_TIMELINE_MODULE_BUCKET_PROFESSIONAL_AND_ENTRY_LEVEL_TITLE:
      'Professional experience',
    PROFILE_TIMELINE_MODULE_ENTRY_LOCALIZED_TIME_STRING_DURATION:
      '{years} and {months}',
    PROFILE_TIMELINE_MODULE_ENTRY_LOCALIZED_TIME_STRING_DURATION_AND_FROM:
      '{duration}, from {since}',
    PROFILE_TIMELINE_MODULE_ENTRY_LOCALIZED_TIME_STRING_DURATION_AND_SINCE:
      '{duration}, since {since}',
    PROFILE_TIMELINE_MODULE_ENTRY_LOCALIZED_TIME_STRING_DURATION_AND_SINCE_YEAR:
      '{duration}, since {since}',
    PROFILE_TIMELINE_MODULE_ENTRY_LOCALIZED_TIME_STRING_ONLY_FROM:
      'from {date}',
    PROFILE_TIMELINE_MODULE_ENTRY_LOCALIZED_TIME_STRING_ONLY_SINCE:
      'since {date}',
    PROFILE_TIMELINE_MODULE_ENTRY_LOCALIZED_TIME_STRING_PERIOD: '{from} - {to}',
    PROFILE_TIMELINE_MODULE_ENTRY_LOCALIZED_TIME_STRING_TO_PRESENT_ONLY:
      '**Current**',
    PROFILE_TIMELINE_MODULE_ENTRY_LOCALIZED_TIME_STRING_WITH_DURATION_AND_PERIOD:
      '{duration}, {period}',
    PROFILEPAGE_MSG_INV_ALREADY_MEMBER:
      '<b>Please note:</b> Your invitation was NOT sent because the recipient is already a member of the group.',
    PROFILEPAGE_MSG_INV_TOO_EARLY:
      '<b>Please note:</b> Your invitation was NOT sent because you have already invited the recipient to join the group.',
    PROFILERAILS_BLOG_PREPARE_INFO: 'Your blog posts will appear here shortly.',
    PROFILERAILS_BLOG_PREPARE_INFO_ERROR:
      "Sorry, this link doesn't seem to be responding. Maybe it contains a typo? If not, please try again later.",
    PROFILERAILS_EDIT_CONTACT_DETAILS_PATH_FENCED_HINT:
      'How are the two of you connected by mutual contacts?',
    PROFILERAILS_EDIT_CONTACT_DETAILS_PATH_SHOW_ALL_FENCED: 'Show all links',
    PROFILERAILS_PROFILE_VISITORS_HL_BASIC:
      'Premium members can view <strong>profile visitors</strong>!',
    PROFILERAILS_PROJOBS_ONBOARDING_HEADER: 'Your ProJobs profile view',
    PROFILERAILS_PROJOBS_ONBOARDING_TEXT:
      'Only people you have granted the corresponding rights in your <a href="/projobs/start/?sc_o=ojm_443_settings">ProJobs settings</a> can see this view. Find out more about how to use ProJobs in our <a href="#" data-mypj-trigger-tour>guided tour</a>.',
    PROFILERAILS_VISITORS_BUTTON_PREMIUM_UPSELL: 'Go Premium',
    PROGRESS_BAR_LOADING_PERCENTAGE: '{percentage}% finished',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_10_AM: '10:00',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_10_PM: '22:00',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_12_AM: '00:00',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_12_PM: '12:00',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_2_PM: '14:00',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_4_PM: '16:00',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_6_AM: '06:00',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_6_PM: '18:00',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_8_AM: '08:00',
    PROJOBS_CONTACT_OPTIONS_PHONE_TIME_8_PM: '20:00',
    PROVINCE_0: 'Macau',
    PROVINCE_1279685: 'Tibet Autonomous Region',
    PROVINCE_1280239: 'Qinghai',
    PROVINCE_1529047: 'Xinjiang-Uighur Autonomous Region',
    PROVINCE_1784764: 'Zhejiang',
    PROVINCE_1785694: 'Yunnan',
    PROVINCE_1792943: 'Tianjin',
    PROVINCE_1794299: 'Sichuan',
    PROVINCE_1795912: 'Shanxi',
    PROVINCE_1796231: 'Shanghai',
    PROVINCE_1796328: 'Shandong',
    PROVINCE_1796480: 'Shaanxi',
    PROVINCE_1799355: 'Ningxia Hui Autonomous Region',
    PROVINCE_1806222: 'Jiangxi',
    PROVINCE_1806260: 'Jiangsu',
    PROVINCE_1806691: 'Hunan',
    PROVINCE_1806949: 'Hubei',
    PROVINCE_1808520: 'Henan',
    PROVINCE_1808773: 'Hebei',
    PROVINCE_1809054: 'Hainan',
    PROVINCE_1809445: 'Guizhou',
    PROVINCE_1809867: 'Guangxi Zhuang Autonomous Region',
    PROVINCE_1809935: 'Guangdong',
    PROVINCE_1810676: 'Gansu',
    PROVINCE_1811017: 'Fujian',
    PROVINCE_1814905: 'Chongqing',
    PROVINCE_1818058: 'Anhui',
    PROVINCE_2035607: 'Inner Mongolia Autonomous Region',
    PROVINCE_2036115: 'Liaoning',
    PROVINCE_2036500: 'Jilin',
    PROVINCE_2036965: 'Heilongjiang',
    PROVINCE_2038349: 'Beijing',
    PROVINCE_2513413: 'Murcia',
    PROVINCE_2521383: 'Balearic Islands',
    PROVINCE_2593109: 'Andalusia',
    PROVINCE_2593110: 'Canary Islands',
    PROVINCE_2593111: 'Castille-La Mancha',
    PROVINCE_2593112: 'Extremadura',
    PROVINCE_2593113: 'Valencia',
    PROVINCE_2822542: 'Thuringia',
    PROVINCE_2838632: 'Schleswig-Holstein',
    PROVINCE_2842565: 'Saxony-Anhalt',
    PROVINCE_2842566: 'Saxony',
    PROVINCE_2842635: 'Saarland',
    PROVINCE_2847618: 'Rhineland-Palatinate',
    PROVINCE_2861876: 'North Rhine-Westphalia',
    PROVINCE_2862926: 'Lower Saxony',
    PROVINCE_2872567: 'Mecklenburg-Vorpommern',
    PROVINCE_2905330: 'Hesse',
    PROVINCE_2911297: 'Hamburg',
    PROVINCE_2944387: 'Bremen',
    PROVINCE_2945356: 'Brandenburg',
    PROVINCE_2950157: 'Berlin',
    PROVINCE_2951839: 'Bavaria',
    PROVINCE_2953481: 'Baden-Württemberg',
    PROVINCE_3042613: 'Zala',
    PROVINCE_3042925: 'Veszprém',
    PROVINCE_3043047: 'Vas',
    PROVINCE_3043845: 'Tolna',
    PROVINCE_3045226: 'Somogy',
    PROVINCE_3046431: 'Pest',
    PROVINCE_3047348: 'Nógrád',
    PROVINCE_3049518: 'Komárom-Esztergom County',
    PROVINCE_3051977: 'Győr-Moson-Sopron County',
    PROVINCE_3053028: 'Fejér County',
    PROVINCE_3054638: 'Budapest',
    PROVINCE_3055399: 'Baranya',
    PROVINCE_3055744: 'Bács-Kiskun county',
    PROVINCE_3114710: 'Principality of Asturias',
    PROVINCE_3115609: 'Navarre',
    PROVINCE_3117732: 'Madrid',
    PROVINCE_3336897: 'La Rioja',
    PROVINCE_3336898: 'Cantabria',
    PROVINCE_3336899: 'Aragon',
    PROVINCE_3336900: 'Castille and León',
    PROVINCE_3336901: 'Catalonia',
    PROVINCE_3336902: 'Galicia',
    PROVINCE_3336903: 'Basque Country',
    PROVINCE_4099753: 'Arkansas',
    PROVINCE_4138106: 'District of Columbia',
    PROVINCE_4142224: 'Delaware',
    PROVINCE_4155751: 'Florida',
    PROVINCE_4197000: 'Georgia',
    PROVINCE_4273857: 'Kansas',
    PROVINCE_4331987: 'Louisiana',
    PROVINCE_4361885: 'Maryland',
    PROVINCE_4398678: 'Missouri',
    PROVINCE_4436296: 'Mississippi',
    PROVINCE_4482348: 'North Carolina',
    PROVINCE_4544379: 'Oklahoma',
    PROVINCE_4597040: 'South Carolina',
    PROVINCE_4662168: 'Tennessee',
    PROVINCE_4736286: 'Texas',
    PROVINCE_4826850: 'West Virginia',
    PROVINCE_4829764: 'Alabama',
    PROVINCE_4831725: 'Connecticut',
    PROVINCE_4862182: 'Iowa',
    PROVINCE_4896861: 'Illinois',
    PROVINCE_4921868: 'Indiana',
    PROVINCE_4971068: 'Maine',
    PROVINCE_5001836: 'Michigan',
    PROVINCE_5037779: 'Minnesota',
    PROVINCE_5073708: 'Nebraska',
    PROVINCE_5090174: 'New Hampshire',
    PROVINCE_5101760: 'New Jersey',
    PROVINCE_5128638: 'New York',
    PROVINCE_5165418: 'Ohio',
    PROVINCE_5224323: 'Rhode Island',
    PROVINCE_5242283: 'Vermont',
    PROVINCE_5279468: 'Wisconsin',
    PROVINCE_5332921: 'California',
    PROVINCE_5417618: 'Colorado',
    PROVINCE_5481136: 'New Mexico',
    PROVINCE_5509151: 'Nevada',
    PROVINCE_5549030: 'Utah',
    PROVINCE_5551752: 'Arizona',
    PROVINCE_5596512: 'Idaho',
    PROVINCE_5667009: 'Montana',
    PROVINCE_5690763: 'North Dakota',
    PROVINCE_5744337: 'Oregon',
    PROVINCE_5769223: 'South Dakota',
    PROVINCE_5815135: 'Washington',
    PROVINCE_5843591: 'Wyoming',
    PROVINCE_5855797: 'Hawaii',
    PROVINCE_5879092: 'Alaska',
    PROVINCE_6254925: 'Kentucky',
    PROVINCE_6254926: 'Massachusetts',
    PROVINCE_6254927: 'Pennsylvania',
    PROVINCE_6254928: 'Virginia',
    PROVINCE_715593: 'Szabolcs-Szatmár-Bereg County',
    PROVINCE_719637: 'Jász-Nagykun-Szolnok County',
    PROVINCE_720002: 'Heves',
    PROVINCE_720293: 'Hajdú-Bihar County',
    PROVINCE_721589: 'Csongrád',
    PROVINCE_722064: 'Borsod-Abauj Zemplen county',
    PROVINCE_722433: 'Békés',
    PW_CHANGE_SUGGESTION:
      'We strongly recommend you change your password at least twice a year to prevent fraudulent use of your login details.',
    QUALIFICATIONS: 'Qualifications',
    QUALIFICATIONS_EMPTY:
      'Enter your additional qualifications here such as language certificates, diplomas, etc.',
    RA_1_2_years_in_pos: '1-2 years',
    RA_2_5_years_in_pos: '2-5 years',
    RA_5_10_years_in_pos: '5-10 years',
    RA_ACTIVE: 'Highly active members',
    RA_ACTIVE_INFO:
      "User activity is measured on the basis of a member's activity index. The index is only factored in, however, if the user has made it publicly visible.",
    RA_CLOSE: 'Close',
    RA_GIVE_FEEDBACK_ITEM:
      "Do you have any ideas or suggestions for XING's Recruiter Membership?",
    RA_GIVE_FEEDBACK_LINK: 'Share your feedback with us!',
    ra_hits: 'Treffer',
    RA_JOBSEARCH: 'Members who are interested in new career opportunities',
    RA_JOBSEARCH_INFO:
      'Members appearing here have indicated that they are interested in new career oppportunities in the "I’m a XING member because I want to …" box on their profile.',
    RA_SEARCH_ERROR:
      'Please limit your search with at least one search parameter.',
    RA_SEARCHHITS: 'Matches',
    RA_TEASER_GIVE_FEEDBACK_HEADLINE: 'We care about what you think.',
    RA_XING_USAGE: 'Special candidate filters',
    REFERENCES_AWARDS: 'References & awards',
    REMEMBER_PASSWORD: 'Remember me',
    REMOTE_OPTION_FULL_REMOTE: 'Remote',
    REMOTE_OPTION_NON_REMOTE: 'On-site',
    REMOTE_OPTION_PARTLY_REMOTE: 'Hybrid',
    ROFILERAILS_PORTFOLIO_ERROR_TITLE_OR_CONTENT:
      "There's no text in this box.",
    SCHOOL: 'University/College',
    SCHOOL_UNIVERSITY: 'University/College',
    SCHOOLS_EMPTY: "You haven't yet listed any universities or colleges.",
    SE_ALL_VIEW_SEARCH_RESULTS: '{max} of {total} results',
    SE_ERROR_NOTIFICATION_JOIN_GROUP: 'Sorry, something  went wrong.',
    SE_EXTENDED_SEARCH_LIST_DESCRIPTION: 'What are you looking for?',
    SE_FILTER_JOBS_PROJOB_LABEL: 'Jobs posted by top recruiters',
    SE_JOBS_SUGGESTED_NAME_LOCATION: 'Jobs in {location}',
    SE_LABEL_COMMUNITIES: 'Groups',
    SE_LABEL_COMPANIES: 'Companies',
    SE_LABEL_EVENTS: 'Events',
    SE_LABEL_JOBS: 'Jobs',
    SE_LABEL_MEMBERS: 'Members',
    SE_LABEL_NEWS: 'News',
    SE_OFFICIAL_XING_GROUPS: 'Official XING Groups',
    SE_PREMIUM_FLAG_ALTTEXT: 'Only Premium members',
    SE_SELECT_MEMBERS: 'Members',
    SE_SHOW_ALL_RESULTS: 'All results',
    SE_SUCCESS_NOTIFICATION_GROUP_JOINED: "You're now a member of this group!",
    SE_SUCCESS_NOTIFICATION_REQUESTED_TO_JOIN_GROUP:
      'Your membership request was sent to the moderator of this group.',
    SEARCH_DROPDOWN_COMPANIES: 'Companies',
    SEARCH_DROPDOWN_JOBS: 'Jobs',
    SEARCH_DROPDOWN_MEMBERS: 'Members',
    SEARCH_DROPDOWN_NEWS: 'News',
    SEARCH_OFFER_INTERESTED_HINT:
      'Draw attention to your profile with your "Wants", skills and interests.',
    SEARCH_OFFER_INTERESTED_IN: 'Wants, skills, interests',
    SEARCH_TOPICS_TAB_SEARCH_BUTTON: 'Search',
    SEARCH_TOPICS_TAB_TITLE: 'Topics',
    SELECT_INDUSTRY: 'Please select an industry.',
    SELECT_LANGUAGE: 'Please select a language.',
    SELECT_SERVICE: 'Please select a Web service.',
    SELECT_STATUS: 'Please select a status.',
    SERVICE: 'Web service',
    SETTINGS_LANGUAGES_TEXT:
      "Please select the language in which you'd like to use {-SITENAME}.",
    SETTINGS_MOTIVATIONS:
      '1. generate new business \n2. find new employees\n3. get to know interesting people\n4. manage my network\n5. find out about and attend events\n6. locate old coworkers and friends\n7. discover career opportunities',
    SETTINGS_USERNAME_PASSWORD_TEXT:
      'Change the login details of your {-SITENAME} member account.',
    SHARE_ACTIONSHEET_HEADER: 'Share this post',
    SHARE_BOX_DEFAULT_ERROR_MESSAGE:
      'Sorry, something went wrong. Please try again later.',
    SHARE_BOX_HEADER: 'Share on XING',
    SHARE_BOX_PLACEHOLDER: 'Comment on your link here.',
    SHARE_BOX_SHARE_SUCCESS: 'Thanks for sharing!',
    SHARE_BOX_SUBMIT_SHARE: 'Share',
    SHARE_BUTTON_LABEL: 'Share',
    SHARE_CANCEL_BUTTON: 'Cancel',
    SHARE_CHAT_CONTACTS: 'Select a contact',
    SHARE_CHAT_HINT: 'Your recipients will each receive a message.',
    SHARE_COUNTER_LABEL:
      '{counter, plural,\n    =1 {1 share}\n    other {# shares}\n}',
    SHARE_FEEDBACK_GROUPS_BUTTON: 'View post',
    SHARE_FEEDBACK_GROUPS_MESSAGE: '{item} shared in the {group} group',
    SHARE_FEEDBACK_INSIDERARTICLE_LABEL: 'Article',
    SHARE_FEEDBACK_JOB_LABEL: 'Job',
    SHARE_FEEDBACK_KLARTEXT_LABEL: 'Post',
    SHARE_FEEDBACK_LINK_LABEL: 'Link',
    SHARE_FEEDBACK_MESSENGER_BUTTON: 'View message',
    SHARE_FEEDBACK_MESSENGER_MESSAGE: '{item} shared via XING message',
    SHARE_FEEDBACK_NEWS_LABEL: 'Article',
    SHARE_FEEDBACK_PICTURE_LABEL: 'Image',
    SHARE_FEEDBACK_POST_LABEL: 'Post',
    SHARE_FEEDBACK_STARTPAGE_BUTTON: 'View post',
    SHARE_FEEDBACK_STARTPAGE_MESSAGE: '{item} shared on the homepage',
    SHARE_FEEDBACK_VIDEO_LABEL: 'Video',
    SHARE_FETCH_ERROR:
      "We're having problems connecting right now. Please try again later.",
    SHARE_FETCH_ERROR_COUNTDOWN: 'Trying again in {countdown} seconds.',
    SHARE_FETCH_ERROR_RETRY: 'Try again',
    SHARE_MOST_RECENT_CHATS: 'Chats',
    SHARE_PREVIEW_ERROR_HEADLINE: 'Preview currently unavailable.',
    SHARE_QUICKSHARE_TO_STARTPAGE: 'In the feed',
    SHARE_TEXT_BOX_LENGTH_ERROR:
      "{delta, plural, \none {Sorry, that's 1 character too many.} \nother {Sorry, that's # characters too many.}\n}",
    SHARE_TO_GROUPS: 'In a group',
    SHARE_TO_GROUPS_AUTOCOMPLETE_FORUMS: 'Forum',
    SHARE_TO_GROUPS_AUTOCOMPLETE_GROUPS: 'Group',
    SHARE_TO_GROUPS_SELECTION_LABEL: 'Select group:',
    SHARE_TO_GROUPS_TEXT_PLACEHOLDER: 'Comment on your link here.',
    SHARE_TO_GROUPS_THREAD_LABEL: 'Your post:',
    SHARE_TO_GROUPS_TITLE_PLACEHOLDER: 'Title',
    SHARE_TO_MESSENGER: 'Via private message',
    SHARE_TO_MESSENGER_EMPTY_STATE:
      "Unfortunately there weren't any hits for {query}.",
    SHARE_TO_STARTPAGE: 'In the feed with a comment',
    SHARE_USER_SUBTEXT_GROUPS: 'Share with a group',
    SHARE_USER_SUBTEXT_MESSAGE: 'Share in a chat message',
    SHARE_USER_SUBTEXT_STARTPAGE: 'Share on XING',
    SHARED_BUTTON_CONTINUE: 'Continue',
    SHARER_LIST_HEADLINE_MEMBERS: '{total} members shared this.',
    SHARER_LIST_HEADLINE_MULTIPLE_MEMBERS: '{total} members shared this.',
    SHARER_LIST_HEADLINE_ONE_MEMBER: 'One member shared this.',
    SHARER_LIST_ITEM_GO_TO_POST_LINK: 'View post',
    SHARER_LIST_POST_NOTIFICATION:
      "Some posts aren't visible here due to users' privacy settings.",
    SHARERS_BACK_TO_POST_LINK: 'Back',
    SHARERS_LIST_BACK_LINK: 'Back',
    SHOW_OLD_PROFILE_INDUSTRY: 'Display the industry from my old profile',
    SOCIAL_ADD_CONTACT: 'Add as contact',
    SOCIAL_BAR_VIEW_COUNTER_DESCRIPTION:
      'Click on the eye icon to find out your visibility. ',
    SOCIAL_BAR_VIEW_COUNTER_HEADING: 'Your visibility',
    SOCIAL_BAR_VIEW_COUNTER_SEEN:
      'For instance, this post was seen <strong>{views} times</strong>.',
    SOCIAL_BAR_VIEW_COUNTER_TIP:
      'Want to increase your reach? Find out how to <a>boost your visibility on XING</a>.',
    SOCIAL_COMMENT_BUTTON_LABEL: 'Comment',
    SOCIAL_COMMENT_COUNTER_LABEL: '{amount} comments',
    SOCIAL_COMMENT_CREATE_BUTTON_LABEL: 'Comment',
    SOCIAL_COMMENT_DELETE_LABEL: 'Delete comment',
    SOCIAL_COMMENT_DELETED_MESSAGE: 'Comment deleted.',
    SOCIAL_COMMENT_EDIT_LABEL: 'Edit comment',
    SOCIAL_COMMENT_ERROR_TOO_LARGE:
      'Please limit your comment to 20,000 characters.',
    SOCIAL_COMMENT_LIST_EMPTY_HEADLINE: 'Have your say',
    SOCIAL_COMMENT_LIST_EMPTY_SUBLINE: 'Add your voice to the conversation.',
    SOCIAL_COMMENT_OVERFLOW_ERROR: '{amount} characters',
    SOCIAL_COMMENT_REPORT_LABEL: 'Report comment',
    SOCIAL_COMMENT_WAS_EDITED: 'Edited',
    SOCIAL_COMMENTS_COUNTER_LABEL:
      '{counter, plural,\n    =0 {Be the first to comment:}\n    =1 {1 comment} \n    other {# comments}\n}',
    SOCIAL_COMMENTS_CREATE_MENTION_PLACEHOLDER:
      'Comment here and use @ to mention others',
    SOCIAL_COMMENTS_CREATE_PLACEHOLDER: 'Enter your comment here...',
    SOCIAL_COMMENTS_DELETE_DIALOG_CANCEL: 'Cancel',
    SOCIAL_COMMENTS_DELETE_DIALOG_CONFIRM: 'Delete',
    SOCIAL_COMMENTS_DELETE_DIALOG_CREATOR_TEXT:
      'Not entirely happy with what you wrote? Edit or retract it if you like.',
    SOCIAL_COMMENTS_DELETE_DIALOG_HEADLINE: 'Delete this comment?',
    SOCIAL_COMMENTS_DELETE_DIALOG_TEXT: "You won't be able to undo this.",
    SOCIAL_COMMENTS_DELETE_MENTION_LABEL: 'Block mentions',
    SOCIAL_COMMENTS_DELETED_TEXT: 'Comment deleted.',
    SOCIAL_COMMENTS_ERROR: "That didn't work. Please try again.",
    SOCIAL_COMMENTS_FORM_PLACEHOLDER: 'Comment and use @ to mention',
    SOCIAL_COMMENTS_LOAD_MORE: 'Show more comments',
    SOCIAL_COMMENTS_MORE_REPLIES: 'Show more replies',
    SOCIAL_COMMENTS_PREVIOUS_COMMENTS: 'Show previous comments',
    SOCIAL_COMMENTS_PREVIOUS_REPLIES: 'Show previous replies',
    SOCIAL_COMMENTS_REPLY_PLACEHOLDER: 'Reply to {authorName}',
    SOCIAL_GENERIC_REPORT: 'Report',
    SOCIAL_INTERACTION_BAR_COMMENT_INPUT_HINT: 'Comment here...',
    SOCIAL_LIKERS_LIST_HEADLINE: 'Members who like this:',
    SOCIAL_LIKERS_LIST_POST_NOTIFICATION:
      "Some likes aren't visible here due to users' privacy settings.",
    SOCIAL_LOAD_MORE_BUTTON: 'Load more',
    SOCIAL_OK: 'OK',
    SOCIAL_PMBX_COMMENT_CONTENT_URL: 'Content',
    SOCIAL_PMBX_COMMENT_DATE: 'Date',
    SOCIAL_PMBX_COMMENT_HEADING: 'Your comments and replies',
    SOCIAL_PMBX_COMMENT_MESSAGE: 'Your comment',
    SOCIAL_PMBX_COMMENT_ORIGIN: 'Where did you comment?',
    SOCIAL_PMBX_COMMENT_TITLE: 'Your comments and replies',
    SOCIAL_PMBX_COMMENT_USER_AGENT: 'User agent',
    SOCIAL_PMBX_DESCRIPTION:
      'Posts on XING you liked, shared, mentioned someone or commented on',
    SOCIAL_PMBX_MENTION_DOCUMENT_URL: 'Contents',
    SOCIAL_PMBX_MENTION_HEADING: 'Your mentions',
    SOCIAL_PMBX_MENTION_TITLE: 'Your mentions',
    SOCIAL_PMBX_REACTION_CONTENT_URL: 'Content',
    SOCIAL_PMBX_REACTION_DATE: 'Date',
    SOCIAL_PMBX_REACTION_LIKE: 'Posts you like',
    SOCIAL_PMBX_REACTION_ORIGIN: 'Where did you react to this post?',
    SOCIAL_PMBX_REACTION_TITLE: 'Posts you reacted to\t ',
    SOCIAL_PMBX_REACTION_USER_AGENT: 'User agent',
    SOCIAL_PMBX_SHARE_CONTENT_URL: 'Content',
    SOCIAL_PMBX_SHARE_DATE: 'Date',
    SOCIAL_PMBX_SHARE_GROUPS: 'Posts you shared with a group',
    SOCIAL_PMBX_SHARE_MESSAGE: 'What you wrote',
    SOCIAL_PMBX_SHARE_MESSENGER: 'Posts you shared as a private message',
    SOCIAL_PMBX_SHARE_ORIGIN: 'Where did you share this post?',
    SOCIAL_PMBX_SHARE_STARTPAGE: 'Posts you shared on XING',
    SOCIAL_PMBX_SHARE_TITLE: 'Posts you shared',
    SOCIAL_PMBX_SHARE_USER_AGENT: 'User agent',
    SOCIAL_PMBX_TITLE: 'Interactions',
    SOCIAL_REPLIES_LOAD_MORE: 'Show more replies',
    SOCIAL_REPLY_BUTTON: 'Reply',
    SOCIAL_REPLY_DISCARD_MESSAGE: 'Really discard this comment?',
    SOCIAL_REPLY_LOAD_MORE: 'Show 1 more reply',
    SOCIAL_REPLY_LOAD_MULTIPLE: 'Show more comments',
    SOCIAL_REPLY_POST_BUTTON: 'Reply',
    SOCIAL_SEND_MESSAGE: 'Write a message',
    SOCIAL_SHARE_COUNTER_LABEL: '{amount} shares',
    SOCIAL_SHARE_EXTERNAL_LINK_ERROR: "This link can't be shared.",
    SOCIAL_SHARERS_LIST_HEADLINE_MEMBERS: 'Members who shared this:',
    SOCIAL_SHARERS_LIST_ITEM_GO_TO_POST_LINK: 'View post',
    SOCIAL_SHARERS_LIST_POST_NOTIFICATION:
      "Some posts aren't visible here due to users' privacy settings.",
    SOCIAL_SHOW_MORE: 'Show more',
    SOCIAL_SOCIAL_PROOF_LABEL:
      '{counter, plural,\n    =1 {1 like:} \n    other {# likes:}\n}',
    SOCIAL_UPDATE_COMMENT_LABEL: 'Save',
    STATE: 'State',
    STATUS: 'Employment status',
    STATUS_BUTTON_CHANGE: 'Update now',
    STATUS_BUTTON_SEND: 'Save',
    STATUS_CHANGE_COMPANY_EXPL:
      'Please select a company you wish to have displayed alongside your name and at the top of your profile. If your current company isn’t listed, please add it to your profile.',
    STATUS_CHANGE_SCHOOL_EXPL:
      'Please select a university you wish to have displayed alongside your name and at the top of your profile. If your current university isn’t listed, please add it to your profile.',
    STATUS_CHANGE_TO_UNEMPLOYED_HINT:
      'Make sure you always keep your profile up to date! Filling in the "wants" and skills in your profile will considerably increase your chances of being found by a new employer.',
    STATUS_CHANGE_TO_UNEMPLOYED_HINT_TITLE: 'Find your new job on XING now!',
    STATUS_MESSAGE_DELETE: 'Delete',
    STILL_IN_POSITION: 'Current position',
    SUBJECT: 'Field of study',
    SUBJECT_HINT: '(field of study)',
    SYSTEM_LANGUAGE: 'This is your system language.',
    SYSTEM_LANGUAGE_LABEL: 'System language',
    TARGET_DEGREE: '(Future) degree',
    TEXTAREA_EMPTY: "You haven't yet entered any information in this category.",
    TITLE: 'Title',
    TO: 'Until',
    TO_LOWERCASE: 'to',
    TOP_ARTICLE_PAID_COPY:
      'Highlight articles you want to appear at the top of your feed and swap them as needed.',
    TOP_ARTICLE_PAID_CTA: 'Discover all benefits',
    TOP_ARTICLE_PAID_FLAG: 'Employer Branding Profile',
    TOP_ARTICLE_PAID_HEADLINE: 'Prioritise content for visitors',
    TOP_BAR_BACK_BUTTON_ARIA_LABEL: 'Back',
    TOP_BAR_BACK_BUTTON_ARIA_LABEL_CUSTOM: 'Back to {section}',
    TOP_BAR_ME_MENU_DIMMER_ARIA_LABEL: 'Close menu',
    TOP_BAR_PROFILE_FILLING_INDICATOR_TITLE: 'Profile',
    UNIVERSAL_CAREER_LEVEL_LIST:
      '1. Student/Intern\n2. Entry level\n3. Professional/experienced\n4. Manager/supervisor\n5. Executive (VP, SVP, etc.)\n6. Senior executive (CEO, etc.)',
    UNIVERSAL_WORKEXPERIENCE_DISCIPLINE_LIST:
      '1001. Analysis & Statistics\n1002. Administration\n1003. Consulting\n4. Controlling & Planning\n1004. Customer Service\n1005. Purchasing, Materials Management & Logistics\n1006. Finance, Accounting & Controlling\n1007. Teaching, R&D\n1008. Health, Medical & Social\n1009. Graphic Design & Architecture\n1010. Engineering & Technical\n1011. IT & Software Development\n1012. Management & Corporate Development\n1013. Marketing & Advertising\n1014. HR\n1015. PR & Journalism\n1016. Production & Manufacturing\n1017. Product Management\n1018. Project Management\n1019. Process Planning & QA\n1020. Law\n1021. Sales & Commerce\n1022. Other Disciplines',
    UNIVERSITY_HINT: '(student)',
    UPLOAD_PHOTO: 'Upload photo',
    UPLOAD_PHOTO_HINT:
      'Upload a photo and make yourself more recognizable to your business contacts and coworkers.',
    UPLOADER_ADD_IMAGE: 'Add image',
    UPLOADER_ADD_VIDEO: 'Add video',
    UPLOADER_CANCEL_UPLOAD: 'Cancel upload',
    UPLOADER_ERROR_BODY: 'Please try again.',
    UPLOADER_ERROR_HEADLINE: "Sorry, that didn't work.",
    UPLOADER_IMAGE_LOADING_HEADLINE: 'Uploading image...',
    UPLOADER_TRY_AGAIN: 'Try again',
    UPLOADER_VIDEO_LOADED_BODY:
      "We are now processing it to make sure it'll look great. This may take a few minutes – but don't let that keep you from posting or sharing it now.\n\n",
    UPLOADER_VIDEO_LOADED_HEADLINE: 'Your video has been uploaded.',
    UPLOADER_VIDEO_LOADING_HEADLINE: 'Uploading video...',
    URL: 'URL',
    USER_FLAG_BUTTON_ARIA_LABEL: '{type} membership badge',
    USER_FLAG_ERROR:
      "This information isn't available right now.<br>\nPlease try again later.",
    USER_FLAG_ERROR_PARAGRAPH_1: "This information isn't available right now.",
    USER_FLAG_ERROR_PARAGRAPH_2: 'Please try again later.',
    USER_FLAG_LOADING: 'Loading...',
    USER_SETTINGS_TAB_PROFILE_SETTINGS: 'Profile settings',
    USER_SETTINGS_TAB_USERNAME_PASSWORD: 'Login details',
    VERTICAL_NAV_BUSINESS_SOLUTIONS_LABEL: 'Business solutions',
    VERTICAL_NAV_BUSINESS_SOLUTIONS_SHORT_LABEL: 'Business',
    VERTICAL_NAV_FIND_JOBS_LABEL: 'Find jobs',
    VERTICAL_NAV_FOLLOWING_LABEL: 'Following',
    VERTICAL_NAV_INSIGHTS_LABEL: 'Insights',
    VERTICAL_NAV_JOB_PREFERENCES_LABEL: 'Job preferences',
    VERTICAL_NAV_MESSAGES_LABEL: 'Messages',
    VERTICAL_NAV_MY_POSTS_LABEL: 'Your posts',
    VERTICAL_NAV_NETWORK_LABEL: 'Network',
    VERTICAL_NAV_NETWORK_SHORT_LABEL: 'Network',
    VERTICAL_NAV_PREMIUM_FOR_BASIC_USER_LABEL: 'Go Premium',
    VERTICAL_NAV_PREMIUM_LABEL: 'Premium',
    VERTICAL_NAV_PREMIUM_SHORT_LABEL: 'Premium',
    VERTICAL_NAV_PROBUSINESS_LABEL: 'ProBusiness',
    VERTICAL_NAV_PROFILE_LABEL: 'Profile',
    VERTICAL_NAV_PROJOBS_FOR_BASIC_USER_LABEL: 'Go ProJobs',
    VERTICAL_NAV_PROJOBS_LABEL: 'ProJobs',
    VERTICAL_NAV_YOUR_JOBS_LABEL: 'Your jobs',
    VERTICAL_NAVIGATION_BUSINESS_SOLUTIONS: 'Business solutions',
    VERTICAL_NAVIGATION_BUSINESS_SOLUTIONS_SHORT: 'Business',
    VERTICAL_NAVIGATION_CAMPUS: 'Campus',
    VERTICAL_NAVIGATION_COMMUNITIES: 'Groups',
    VERTICAL_NAVIGATION_COMPANIES: 'Companies',
    VERTICAL_NAVIGATION_CONTACTS: 'Your network',
    VERTICAL_NAVIGATION_CONTACTS_SHORT: 'Network',
    VERTICAL_NAVIGATION_CONTENT_PAGES: 'News',
    VERTICAL_NAVIGATION_EVENTS: 'Events',
    VERTICAL_NAVIGATION_FEEDBACK_AND_HELP_TITLE:
      'The new XING app: Feedback & Help',
    VERTICAL_NAVIGATION_FEEDBACK_AND_HELP_TITLE_SHORT: 'New app',
    VERTICAL_NAVIGATION_FOOTER_ADVERTISE: 'Advertise on XING',
    VERTICAL_NAVIGATION_FOOTER_AMIANDO: 'Ticketing and event promotion',
    VERTICAL_NAVIGATION_FOOTER_ANDROID: 'Android',
    VERTICAL_NAVIGATION_FOOTER_ANSCHREIBEN: 'Cover letter editor',
    VERTICAL_NAVIGATION_FOOTER_APP_GALLERY: 'Mobile & desktop apps',
    VERTICAL_NAVIGATION_FOOTER_APPS: 'Apps',
    VERTICAL_NAVIGATION_FOOTER_BEWERBUNG: 'Job application tips',
    VERTICAL_NAVIGATION_FOOTER_CAMPUS: 'Campus',
    VERTICAL_NAVIGATION_FOOTER_CAREER: 'Careers',
    VERTICAL_NAVIGATION_FOOTER_CAREER_GUIDE: 'Career Guide',
    VERTICAL_NAVIGATION_FOOTER_COACHES: 'Coaches + Trainers',
    VERTICAL_NAVIGATION_FOOTER_COMMUNITY: 'Community',
    VERTICAL_NAVIGATION_FOOTER_COMPANIES: 'Companies',
    VERTICAL_NAVIGATION_FOOTER_COMPANY: 'Company',
    VERTICAL_NAVIGATION_FOOTER_DEVBLOG: 'Devblog',
    VERTICAL_NAVIGATION_FOOTER_DEVELOPER_PORTAL: 'Developers',
    VERTICAL_NAVIGATION_FOOTER_DOWNLOAD: 'Downloads',
    VERTICAL_NAVIGATION_FOOTER_EVENTS: 'Events',
    VERTICAL_NAVIGATION_FOOTER_GEHALTSVERGLEICH: 'Salary check',
    VERTICAL_NAVIGATION_FOOTER_GROUPS: 'Groups',
    VERTICAL_NAVIGATION_FOOTER_HALLO_FREELANCER: 'Freelancer verwalten',
    VERTICAL_NAVIGATION_FOOTER_HELP: 'Help & Contact',
    VERTICAL_NAVIGATION_FOOTER_IMPRINT: 'About this site',
    VERTICAL_NAVIGATION_FOOTER_INVESTOR_RELATIONS: 'Investor Relations',
    VERTICAL_NAVIGATION_FOOTER_IPHONE_IPAD: 'iPhone & iPad',
    VERTICAL_NAVIGATION_FOOTER_JOB_MARKET: 'Jobs',
    VERTICAL_NAVIGATION_FOOTER_JOBS_DIRECTORY: 'Jobs Directory',
    VERTICAL_NAVIGATION_FOOTER_KUNUNU: 'Employers',
    VERTICAL_NAVIGATION_FOOTER_LEBENSLAUF: 'CV editor',
    VERTICAL_NAVIGATION_FOOTER_LOGINWXING: 'Log in with XING',
    VERTICAL_NAVIGATION_FOOTER_MEMBERSHIP_CANCELLATION: 'Cancel membership',
    VERTICAL_NAVIGATION_FOOTER_MEMBERSHIPS: 'Memberships',
    VERTICAL_NAVIGATION_FOOTER_MOBILE: 'Mobile',
    VERTICAL_NAVIGATION_FOOTER_NEW_WORK_EXPERIENCE:
      'NWX – Neues zur Zukunft der Arbeit',
    VERTICAL_NAVIGATION_FOOTER_NEWWORKSE: 'New Work SE',
    VERTICAL_NAVIGATION_FOOTER_PEOPLE_DIRECTORY: 'Member directory',
    VERTICAL_NAVIGATION_FOOTER_PRAKTIKUM_GUIDE: 'Praktikum-Guide',
    VERTICAL_NAVIGATION_FOOTER_PREMIUM_MEMBERSHIP: 'Premium',
    VERTICAL_NAVIGATION_FOOTER_PRESS: 'Press',
    VERTICAL_NAVIGATION_FOOTER_PRIVACY: 'Privacy at XING',
    VERTICAL_NAVIGATION_FOOTER_PRIVACY_POLICY: 'Privacy Policy',
    VERTICAL_NAVIGATION_FOOTER_PRO_BUSINESS_MEMBERSHIP: 'ProBusiness',
    VERTICAL_NAVIGATION_FOOTER_PROJOBS_MEMBERSHIP: 'ProJobs',
    VERTICAL_NAVIGATION_FOOTER_RESOURCES: 'Resources',
    VERTICAL_NAVIGATION_FOOTER_SERVICES: 'Additional services',
    VERTICAL_NAVIGATION_FOOTER_TALENT_MANAGER: 'Recruiting with XING',
    VERTICAL_NAVIGATION_FOOTER_TANDC: 'Terms & Conditions',
    VERTICAL_NAVIGATION_FOOTER_TRACKING: 'Tracking',
    VERTICAL_NAVIGATION_FOOTER_WINDOWS_10: 'Windows 10',
    VERTICAL_NAVIGATION_FOOTER_XAS: 'Post an ad',
    VERTICAL_NAVIGATION_FOOTER_XING_AG: 'New Work SE',
    VERTICAL_NAVIGATION_FOOTER_XING_NEWS: 'XING News',
    VERTICAL_NAVIGATION_FOOTER_XING_SHARE: 'XING share button',
    VERTICAL_NAVIGATION_FOOTER_YOUR_XING: 'Main Sections',
    VERTICAL_NAVIGATION_HEADER_BRAND_MANAGER_EDITOR: 'BrandManager',
    VERTICAL_NAVIGATION_HEADER_HELP: 'Help',
    VERTICAL_NAVIGATION_HEADER_MANAGE_JOBS: 'Job ads',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_ADS: 'AdManager',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_COACH_PROFILE: 'Coach profile',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_INVOICES: 'Invoices & Accounts',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_LOGOUT: 'Log out',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_MEMBERSHIPS_INVOICES:
      'Memberships & invoices',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_PREMIUM_MEMBERSHIP: 'Premium',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_PROBUSINESS_MEMBERSHIP: 'ProBusiness',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_PROJOBS_MEMBERSHIP: 'ProJobs',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_PROJOBS_SETTINGS: 'ProJobs settings',
    VERTICAL_NAVIGATION_HEADER_PERSONAL_SETTINGS: 'Settings',
    VERTICAL_NAVIGATION_HEADER_SEARCH_ALL_RESULTS: 'View all',
    VERTICAL_NAVIGATION_HEADER_SEARCH_BUTTON: 'Search',
    VERTICAL_NAVIGATION_HEADER_SEARCH_COMMUNITIES: 'Groups',
    VERTICAL_NAVIGATION_HEADER_SEARCH_COMPANIES_PROFILE: 'Employer',
    VERTICAL_NAVIGATION_HEADER_SEARCH_EVENTS: 'Events',
    VERTICAL_NAVIGATION_HEADER_SEARCH_JOBS: 'Jobs',
    VERTICAL_NAVIGATION_HEADER_SEARCH_MEMBERS: 'Members',
    VERTICAL_NAVIGATION_HEADER_SEARCH_NEWS: 'Industry News',
    VERTICAL_NAVIGATION_HEADER_SEARCH_PLACEHOLDER:
      'Enter a job title, name or keyword',
    VERTICAL_NAVIGATION_HEADER_SEAT_MANAGER_ADMIN: 'LicenceManager',
    VERTICAL_NAVIGATION_HEADER_XTM: 'TalentManager',
    VERTICAL_NAVIGATION_MESSAGES: 'Messages',
    VERTICAL_NAVIGATION_MORE: 'More',
    VERTICAL_NAVIGATION_MYPROJOBS: 'ProJobs',
    VERTICAL_NAVIGATION_OTHER_SERVICES: 'Other services',
    VERTICAL_NAVIGATION_PREMIUM_BENEFITS: 'Premium',
    VERTICAL_NAVIGATION_PREMIUM_BENEFITS_FOR_BASIC_USER: 'Go Premium',
    VERTICAL_NAVIGATION_PREMIUM_BENEFITS_SHORT: 'Premium',
    VERTICAL_NAVIGATION_PROBUSINESS: 'ProBusiness',
    VERTICAL_NAVIGATION_PROJOBS_BENEFITS_FOR_BASIC_USER: 'Go ProJobs',
    VERTICAL_NAVIGATION_PROJOBS_UPSELL: 'Check out ProJobs',
    VERTICAL_NAVIGATION_PROJOBS_UPSELL_SHORT: 'ProJobs',
    VERTICAL_NAVIGATION_STARTPAGE: 'Home',
    VIDEO_DELETE_ERROR:
      "We couldn't delete your video just now. Please try again.",
    VIDEO_DIALOG_CANCEL: 'Cancel',
    VIDEO_DIALOG_CONFIRM: 'Discard',
    VIDEO_DIALOG_HEADLINE: 'Discard changes?',
    VIDEO_DIALOG_TEXT: "You won't be able to undo this.",
    VIDEO_ENCODING_HEADLINE: 'Encoding your video...',
    VIDEO_ENCODING_MESSAGE:
      'Please bear with us as this may take a few minutes.',
    VIDEO_SAVE_ERROR: "We couldn't save your video just now. Please try again.",
    VIDEO_UPLOAD_CANCEL: 'Discard',
    VIDEO_UPLOAD_CROP_CANCEL: 'Discard',
    VIDEO_UPLOAD_CROP_MAX: 'Max.',
    VIDEO_UPLOAD_CROP_MIN: 'Min.',
    VIDEO_UPLOAD_CROP_SAVE: 'Save',
    VIDEO_UPLOAD_DELETE: 'Delete video',
    VIDEO_UPLOAD_ENCODING_TITLE:
      "Your video is being prepared. We'll e-mail you to let you know when it’s ready.",
    VIDEO_UPLOAD_INPROGRESS_DESCRIPTION: '{percentage}% completed',
    VIDEO_UPLOAD_INPROGRESS_TITLE: 'Uploading your video...',
    VIDEO_UPLOAD_PREVIEW_IMAGE_CTA: 'Select image',
    VIDEO_UPLOAD_PREVIEW_IMAGE_DESCRIPTION:
      "You can add a custom preview image for your video here (JPG or PNG, max. 10MB). Otherwise we'll display a still image from the video.",
    VIDEO_UPLOAD_PREVIEW_IMAGE_TITLE: 'Preview image',
    VIDEO_UPLOAD_READY_CTA: 'Browse files',
    VIDEO_UPLOAD_READY_DESCRIPTION:
      'Format: AVI, MOV or MP4  | Ratio: 16:9 | Size: max. 5 GB',
    VIDEO_UPLOAD_READY_TITLE: 'Drag & drop or select a file.',
    VIDEO_UPLOAD_READY_TITLE_DRAGGING: 'Drop your file now',
    VIDEO_UPLOAD_SAVE: 'Save',
    VIDEO_UPLOAD_SUBTITLES_CTA: 'Choose file',
    VIDEO_UPLOAD_SUBTITLES_DESCRIPTION:
      'Here you can add subtitles to your video with a WebVTT or SRT file. ',
    VIDEO_UPLOAD_SUBTITLES_LABEL_DE: 'German',
    VIDEO_UPLOAD_SUBTITLES_LABEL_EN: 'English',
    VIDEO_UPLOAD_SUBTITLES_TITLE: 'Subtitles',
    VIDEO_UPLOAD_TITLE: 'Video title',
    VIDEO_UPLOADING_ERROR:
      "We couldn't upload your video. Please select a file in AVI, MOV or MP4 format in 16:9 ratio and up to 5 GB in size.",
    VIDEOS_AUTOPLAY: 'Autoplay',
    VIDEOS_SETTINGS: 'Autoplay videos?',
    VIDEOS_WATCH_VIDEO: 'Watch video',
    VIEW_PAGE_IN_CHINESE: 'View this page in Chinese',
    VIEW_PAGE_IN_DUTCH: 'View this page in Dutch',
    VIEW_PAGE_IN_ENGLISH: 'View this page in English',
    VIEW_PAGE_IN_FINNISH: 'View this page in Finnish',
    VIEW_PAGE_IN_FRENCH: 'View this page in French',
    VIEW_PAGE_IN_GERMAN: 'View this page in German',
    VIEW_PAGE_IN_HUNGARIAN: 'View this page in Hungarian',
    VIEW_PAGE_IN_ITALIAN: 'View this page in Italian',
    VIEW_PAGE_IN_JAPANESE: 'View this page in Japanese',
    VIEW_PAGE_IN_KOREAN: 'View this page in Korean',
    VIEW_PAGE_IN_POLISH: 'View this page in Polish',
    VIEW_PAGE_IN_PORTUGUESE: 'View this page in Portuguese',
    VIEW_PAGE_IN_RUSSIAN: 'View this page in Russian',
    VIEW_PAGE_IN_SPANISH: 'View this page in Spanish',
    VIEW_PAGE_IN_SWEDISH: 'View this page in Swedish',
    VIEW_PAGE_IN_TURKISH: 'View this page in Turkish',
    VISIBLE_ON_MY_PROFILE: 'visible to profile visitors',
    VISIBLE_TO_ALL_MEMBERS: 'visible for all XING members',
    VISIBLE_TO_RECRUITERS: 'only visible for recruiters',
    WEB: 'Web',
    WEB_PROFILES_EMPTY: "You haven't listed any other web profiles.",
    WELCOME_BOX:
      'Welcome <a href="/profile/my_profile" title="{\'NAVI_EDITCONTACTPAGE}">{-FULL_NAME}</a>{!USER_ICONS}',
    WORK: 'Jobs',
    WORK_EXPERIENCE: 'Professional experience',
    WORKEXPERIENCE_ADDITIONAL_INDUSTRIES: 'Additional industries',
    WORKEXPERIENCE_DISCIPLINE_LIST:
      '1. Administration\n2. Analysis & Statistics\n3. Consulting\n4. Controlling & Planning\n5. Customer Service\n6. Graphic Design\n7. Purchasing, Materials Management & Logistics\n8. Finance & Accounting\n9. Research & Teaching\n10. Corporate Management, M&A & Strategic Management\n11. Manual Trade\n12. Hardware & Software Development\n13. Journalism\n14. Marketing & Advertising\n15. Medicine\n16. PR\n17. Organisation & Management\n18. HR\n19. Production & Manufacturing\n20. Product Management\n21. Project Management\n22. R&D\n23. Law\n24. Sales',
    WORKEXPERIENCE_GET_OLD_INDUSTRIES:
      'Copy your industries from old XING profile',
    WORKEXPERIENCE_ORG_TYPE_LIST:
      '1. Publicly held corporation\n2. Privately held company\n3. Non-profit organisation\n4. Business partnership\n5. Individual enterprise\n6. Freelance',
    WORKEXPERIENCE_SIZE_LIST:
      '1. Just me\n2. 1-10 employees\n3. 11-50 employees\n4. 51-200 employees\n5. 201-500 employees\n6. 501-1,000 employees\n7. 1,001-5,000 employees\n8. 5,001-10,000 employees\n9. 10,001 or more employees',
    WORKEXPERIENCE_STATUS_1: 'Full-time employee',
    WORKEXPERIENCE_STATUS_10: 'Shareholder',
    WORKEXPERIENCE_STATUS_11: 'Civil servant',
    WORKEXPERIENCE_STATUS_12: 'Recruiter',
    WORKEXPERIENCE_STATUS_13: 'Freelancer',
    WORKEXPERIENCE_STATUS_14: 'Partner',
    WORKEXPERIENCE_STATUS_17: 'Apprentice',
    WORKEXPERIENCE_STATUS_2: 'Part-time employee',
    WORKEXPERIENCE_STATUS_3: 'Intern',
    WORKEXPERIENCE_STATUS_4: 'Self-employed',
    WORKEXPERIENCE_STATUS_5: 'Owner',
    WORKEXPERIENCE_STATUS_6: 'Partner/Shareholder',
    WORKEXPERIENCE_STATUS_7: 'Board member',
    WORKEXPERIENCE_STATUS_8: 'Volunteer',
    WORKEXPERIENCE_STATUS_9: 'Self-employed',
    WORKEXPERIENCE_STATUS_LIST:
      '1. Full-time employee\n2. Part-time employee\n3. Intern\n4. Freelancer/Self-employed\n5. Owner\n6. Partner/Shareholder\n7. Board member\n8. Volunteer\n9. Self-employed\n10. Shareholder\n11. Civil servant\n12. Recruiter\n13. Freelancer\n14. Partner\n17. Apprentice',
    WRONG_END_DATE_HINT:
      'You have selected "Current position", but the end date is in the past.',
    X_UI_HEADER_REGISTER: 'Sign up',
    X_UI_NAVIGATION_LOGIN_FRAME_FALLBACK: 'Log in',
    XBP_NAVIGATION_ENTRY_LINK: 'Manage Business Pages',
    XDS_A11Y_LOADING: 'Loading',
    XDS_BACK_TO_TOP_BUTTON: 'Back to top',
    XDS_BREADCRUMB_HOME: 'Home',
    XDS_CAROUSEL_NEXT_BUTTON_A11Y: 'Nächste Seite',
    XDS_CAROUSEL_PREVIOUS_BUTTON_A11Y: 'Vorherige Seite',
    XDS_CLOSE_BUTTON_A11Y: 'Close',
    XDS_DELETE_TAG_BUTTON_A11Y: 'Delete',
    XDS_DISCOVER_BADGE_A11Y: 'New feature',
    XDS_EMPTYSTATE_ERROR_BUTTON: 'Try again',
    XDS_EMPTYSTATE_ERROR_DESCRIPTION: 'Please try again.',
    XDS_EMPTYSTATE_ERROR_TITLE: "That didn't work.",
    XDS_FEEDBACK_BAR_THUMB_DOWN_A11Y: 'Thumbs down',
    XDS_FEEDBACK_BAR_THUMB_UP_A11Y: 'Thumbs up',
    XDS_FLAG_AMBASSADOR_DIVERSE: 'Ambassador',
    XDS_FLAG_AMBASSADOR_FEMALE: 'Ambassador',
    XDS_FLAG_AMBASSADOR_MALE: 'Ambassador',
    XDS_FLAG_AMBASSADOR_NEUTRAL: 'Ambassador',
    XDS_FLAG_BASIC_DIVERSE: 'Basic',
    XDS_FLAG_BASIC_FEMALE: 'Basic',
    XDS_FLAG_BASIC_MALE: 'Basic',
    XDS_FLAG_BASIC_NEUTRAL: 'Basic',
    XDS_FLAG_BETA_DIVERSE: 'Beta',
    XDS_FLAG_BETA_FEMALE: 'Beta',
    XDS_FLAG_BETA_MALE: 'Beta',
    XDS_FLAG_BETA_NEUTRAL: 'Beta',
    XDS_FLAG_INSIDER_DIVERSE: 'Insider',
    XDS_FLAG_INSIDER_FEMALE: 'Insider',
    XDS_FLAG_INSIDER_MALE: 'Insider',
    XDS_FLAG_INSIDER_NEUTRAL: 'Insider',
    XDS_FLAG_MODERATOR_DIVERSE: 'Moderator',
    XDS_FLAG_MODERATOR_FEMALE: 'Moderator',
    XDS_FLAG_MODERATOR_MALE: 'Moderator',
    XDS_FLAG_MODERATOR_NEUTRAL: 'Moderator',
    XDS_FLAG_NEW_DIVERSE: 'New',
    XDS_FLAG_NEW_FEMALE: 'New',
    XDS_FLAG_NEW_MALE: 'New',
    XDS_FLAG_NEW_NEUTRAL: 'New',
    XDS_FLAG_PREMIUM_DIVERSE: 'Premium',
    XDS_FLAG_PREMIUM_FEMALE: 'Premium',
    XDS_FLAG_PREMIUM_MALE: 'Premium',
    XDS_FLAG_PREMIUM_NEUTRAL: 'Premium',
    XDS_FLAG_PROBUSINESS_DIVERSE: 'ProBusiness',
    XDS_FLAG_PROBUSINESS_FEMALE: 'ProBusiness',
    XDS_FLAG_PROBUSINESS_MALE: 'ProBusiness',
    XDS_FLAG_PROBUSINESS_NEUTRAL: 'ProBusiness',
    XDS_FLAG_PROCOACH_DIVERSE: 'ProCoach',
    XDS_FLAG_PROCOACH_FEMALE: 'ProCoach',
    XDS_FLAG_PROCOACH_MALE: 'ProCoach',
    XDS_FLAG_PROCOACH_NEUTRAL: 'ProCoach',
    XDS_FLAG_PROJOBS_DIVERSE: 'ProJobs',
    XDS_FLAG_PROJOBS_FEMALE: 'ProJobs',
    XDS_FLAG_PROJOBS_MALE: 'ProJobs',
    XDS_FLAG_PROJOBS_NEUTRAL: 'ProJobs',
    XDS_FLAG_PROTRAINER_DIVERSE: 'ProTrainer',
    XDS_FLAG_PROTRAINER_FEMALE: 'ProTrainer',
    XDS_FLAG_PROTRAINER_MALE: 'ProTrainer',
    XDS_FLAG_PROTRAINER_NEUTRAL: 'ProTrainer',
    XDS_INPUT_BAR_CLEAR_BUTTON_A11Y: 'Eingabe löschen',
    XDS_MINI_BADGE_A11Y: 'New notification',
    'XDS_NOTIFICATION_BADGE_GENERIC_A11Y.one': '1 new notification',
    'XDS_NOTIFICATION_BADGE_GENERIC_A11Y.other': '{value} new notifications',
    XDS_SKELETON_GENERIC_A11Y: 'Loading',
    XDS_STAR_RATING_A11Y: 'Rating: {rating} out of 5 stars',
    XING_FAQ_GUIDE_URL: 'https://faq.xing.com/en/node/68237',
    XINGID_ADD_LEAD_SUCCESS: '{displayName} added to leads',
    XINGID_REMOVE_LEAD_SUCCESS: '{displayName} removed from leads',
    XWS_100_APP_PERMISSION_VENDOR_VIDEOS: 'Access XING videos vendor API',
    XWS_100_APP_PERMISSION_VENDOR_VIDEOS_HEADING: 'XING videos API',
    XWS_100_INACTIVE_MEMBER: 'Inactive member',
    YEAR: 'Year',
    YEARS: 'years',
    YOUR_BIRTHDAY: 'Your birthday',
    YOUR_CURRENT_ADDRESS: 'Your current business address',
    YOUR_CURRENT_COMPANY: 'Your current company',
    YOUR_LANGUAGE_NOT_AVAIL: "The language you speak isn't listed?",
    YOUR_PIC: 'Your photo',
    YOUR_PREVIOUS_COMPANIES: 'Your previous companies',
    YOUR_STATUS: 'Your status',
    YOUR_UNIVERSITY: 'Your university/college',
    ZIP_CODE: 'Postcode',
  },
};
